import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-allergen-popover',
  templateUrl: './allergen-popover.component.html',
  styleUrls: ['./allergen-popover.component.scss'],
})
export class AllergenPopoverComponent implements OnInit {
  @Input() allergens: string[]
  @Input() confirmed: boolean

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  /**
   * Dismisses the modal
   */
  closeModal() {
    this.modalController.dismiss()
  }
}
