<ion-row class="article-container">
  <div class="article-amount-container">
    <ion-skeleton-text
      class="skeleton-dish-counter"
      [animated]="true"
    ></ion-skeleton-text>
  </div>
  <ion-col class="text-container">
    <ion-skeleton-text
      class="skeleton-dish-name"
      [animated]="true"
    ></ion-skeleton-text>

    <ion-skeleton-text
      class="skeleton-dish-desc"
      [animated]="true"
    ></ion-skeleton-text>
  </ion-col>
  <ion-col size="2" class="skeleton-dish-price">
    <ion-skeleton-text class="price-text" [animated]="true"></ion-skeleton-text>
  </ion-col>

  <div class="item-divider"></div>
</ion-row>
