import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, RouterOutlet } from '@angular/router'
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'app-userprofilearea-modal',
  templateUrl: './userprofilearea-modal.page.html',
  styleUrls: ['./userprofilearea-modal.page.scss'],
})
export class UserprofileareaModalPage implements OnInit {
  @ViewChild(RouterOutlet) outlet: RouterOutlet
  constructor(
    public userService: UserService,
    public router: Router
  ) {}
  selectedIndex = 0

  ngOnInit(): void {}
  ionViewDidEnter() {
    this.selectedIndex = this.getActiveTabIndex()
  }

  /**
   * fetches the current tab index of the selected profile tab in order to highlight it
   * @returns
   */
  getActiveTabIndex() {
    let index
    this.userService.profileAreaTabs.forEach((element) => {
      if (element.url == this.router.url) {
        index = this.userService.profileAreaTabs.indexOf(element)
        return
      }
    })
    return index
  }

  /**
   * navigates the user to the desired Tab
   * @param tabName
   */
  loadTab(tabName) {
    this.userService.profileAreaTabs.forEach((item) => {
      if (item.name == tabName) {
        this.router.navigateByUrl(item.url)
      }
    })
  }
  /**
   * navigates the user back to the marketplace
   */
  goBack() {
    if (this.userService.fromHomepage == true) {
      this.userService.fromHomepage = false
    }
    this.router.navigateByUrl('/home')
  }
}
