import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { TableContentPage } from './table-content.page'

const routes: Routes = [
  {
    component: TableContentPage,
    path: '',
  },
]

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class TableContentPageRoutingModule {}
