<ion-header *ngIf=" this.userService.profile.isLoggedIn == false">
  <ion-row style="margin-top: 10px">
    <ion-col class="ion-align-self-center ion-text-center">
      <img
        style="height: 80px"
        [src]="'./../../assets/logos/orderbird/RGB_obLogo_blue.svg'"
      />
    </ion-col>
  </ion-row>
</ion-header>
<ion-header *ngIf="this.userService.profile.isLoggedIn == true">
  <ion-toolbar>
    <ion-title class="ion-text-center ion-align-self-center">
      Hallo, {{this.userService.profile.name}}</ion-title
    >
  </ion-toolbar>
</ion-header>

<div class="inner-content" *ngIf="this.userService.profile.isLoggedIn == true">
  <ion-button
    (click)='this.profileGoTo("main")'
    size="default"
    style="border-radius: 2rem; color: black"
    color="orderbird"
    expand="full"
  >
    <span style="text-align: left; width: 100%">
      <ion-row>
        <ion-icon
          name="person-circle-outline"
          style="font-size: x-large; margin-right: 4px"
        ></ion-icon>
        <ion-label class="ion-align-self-center">Meine Info</ion-label>
      </ion-row>
    </span>
  </ion-button>
  <ion-button
    (click)='this.profileGoTo("history")'
    size="default"
    style="border-radius: 2rem; color: black"
    color="orderbird"
    expand="full"
  >
    <span style="text-align: left; width: 100%">
      <ion-row>
        <ion-icon
          name="restaurant-outline"
          style="font-size: x-large; margin-right: 4px"
        ></ion-icon>
        <ion-label class="ion-align-self-center">Meine Bestellungen</ion-label>
      </ion-row>
    </span>
  </ion-button>
  <ion-button
    (click)='this.profileGoTo("favGastros")'
    size="default"
    style="border-radius: 2rem; color: black"
    color="orderbird"
    expand="full"
  >
    <span style="text-align: left; width: 100%">
      <ion-row>
        <ion-icon
          name="heart-outline"
          style="font-size: x-large; margin-right: 4px"
        ></ion-icon>
        <ion-label class="ion-align-self-center">Favoriten</ion-label>
      </ion-row>
    </span>
  </ion-button>
  <ion-button
    (click)="this.logOut()"
    size="default"
    style="border-radius: 2rem; color: black"
    color="split-secondary"
    expand="full"
  >
    <span style="text-align: left; width: 100%">
      <ion-row>
        <ion-icon
          name="arrow-undo-outline"
          style="font-size: x-large; margin-right: 4px"
        ></ion-icon>
        <ion-label class="ion-align-self-center">Abmelden</ion-label>
      </ion-row>
    </span>
  </ion-button>
</div>

<div
  class="inner-content"
  *ngIf='this.userService.profile.isLoggedIn == false && this.state == "pswdReset"'
>
  <ion-row *ngIf="this.errorMessage != ''">
    <ion-label color="danger">{{this.errorMessage}}</ion-label>
  </ion-row>
  <ion-row>
    <ion-input
      [autofocus]="true"
      [(ngModel)]="this.resetPswdEmail"
      placeholder="email@email.com"
      type="email"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-button
        (click)='this.changeProfileViewState("login")'
        size="default"
        style="border-radius: 2rem; color: black"
        color="split-secondary"
        expand="full"
        >Zurück!</ion-button
      >
    </ion-col>
    <ion-col>
      <ion-button
        (click)="this.resetPswd()"
        size="default"
        style="border-radius: 2rem; color: black"
        color="orderbird"
        expand="full"
        >Passwort Zurücksetzen</ion-button
      >
    </ion-col>
  </ion-row>
</div>

<div
  class="inner-content"
  *ngIf='this.userService.profile.isLoggedIn == false && this.state == "login"'
>
  <ion-row style="margin-bottom: 25px">
    <ion-col
      (click)='this.changeProfileViewState("login")'
      size="6"
      class="ion-align-self-center ion-text-center"
      style="
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-color: var(orderbird);
      "
    >
      <ion-label style="font-size: 18px; font-weight: 700" color="orderbird"
        >Login</ion-label
      >
    </ion-col>
    <ion-col
      (click)='this.changeProfileViewState("register")'
      size="6"
      class="ion-align-self-center ion-text-center"
      style="
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-color: var(--ion-color-split-secondary);
      "
    >
      <ion-label
        style="font-size: 18px; font-weight: 700"
        color="split-secondary"
        >Registrieren</ion-label
      >
    </ion-col>
  </ion-row>
  <ion-row *ngIf="this.errorMessage != ''">
    <ion-label color="danger">{{this.errorMessage}}</ion-label>
  </ion-row>
  <ion-item>
    <ion-input
      (keyup.enter)="this.logIn(this.logInEmail, this.logInPW, this.logInPersist)"
      [autofocus]="true"
      [(ngModel)]="this.logInEmail"
      placeholder="Emailadresse"
      type="email"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-item>
  <ion-item>
    <ion-input
      (keyup.enter)="this.logIn(this.logInEmail, this.logInPW, this.logInPersist)"
      [(ngModel)]="this.logInPW"
      placeholder="Passwort"
      type="password"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-item>
  <ion-row
    (keyup.enter)="this.logIn(this.logInEmail, this.logInPW, this.logInPersist)"
    style="margin-left: 5px"
  >
    <ion-col class="ion-align-self-center" size="8"
      ><ion-label>Eingeloggt bleiben?</ion-label></ion-col
    >
    <ion-col size="4"
      ><ion-toggle
        color="orderbird"
        [checked]="false"
        [(ngModel)]="this.logInPersist"
      ></ion-toggle
    ></ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-text-center ion-align-self-center">
      <ion-button
        (click)="this.logIn(this.logInEmail, this.logInPW, this.logInPersist)"
        size="default"
        style="border-radius: 5%; color: white"
        color="orderbird"
        expand="full"
        >Anmelden</ion-button
      >
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col style="text-align: center">
      <ion-text
        class="clickableText"
        (click)='this.changeProfileViewState("pswdReset")'
      >
        Passwort vergessen?
      </ion-text>
    </ion-col>
  </ion-row>
</div>

<div
  class="inner-content"
  *ngIf='this.userService.profile.isLoggedIn == false && this.state == "register"'
>
  <ion-row style="margin-bottom: 30px">
    <ion-col
      (click)='this.changeProfileViewState("login")'
      size="6"
      class="ion-align-self-center ion-text-center"
      style="
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-color: var(--ion-color-split-secondary);
      "
    >
      <ion-label
        expand="full"
        style="font-size: 18px; font-weight: 700"
        color="split-secondary"
        >Login</ion-label
      >
    </ion-col>
    <ion-col
      (click)='this.changeProfileViewState("register")'
      size="6"
      class="ion-align-self-center ion-text-center"
      style="
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-color: var(orderbird);
      "
    >
      <ion-label
        expand="full"
        style="font-size: 18px; font-weight: 700"
        color="orderbird"
        >Registrieren</ion-label
      >
    </ion-col>
  </ion-row>
  <ion-row *ngIf="this.errorMessage != ''">
    <ion-label color="danger">{{this.errorMessage}}</ion-label>
  </ion-row>

  <ion-item>
    <ion-input
      [(ngModel)]="regName"
      autocomplete="given-name"
      placeholder="Name"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-input
      [(ngModel)]="regLastname"
      autocomplete="family-name"
      placeholder="Nachname"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-input
      [(ngModel)]="regEmail"
      autocomplete="email"
      placeholder="email@email.com"
      type="email"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-input
      [(ngModel)]="regPW"
      placeholder="Passwort"
      type="password"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-input
      [(ngModel)]="regrePW"
      placeholder="Passwort wiederholen"
      type="password"
      required
      (ionChange)='this.errorMessage =""'
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-label
      >Ich aktzeptiere die
      <a
        target="_blank"
        href="https://www.orderbird.com/de/agb-bestellmanagement"
        >AGB's</a
      >
      und
      <a
        target="_blank"
        href="https://www.orderbird.com/de/datenschutzerklaerung-bestellmanagement"
        >Datenschutzbestimmungen</a
      ></ion-label
    >
    <ion-checkbox slot="start" [(ngModel)]="acceptUserTosAndDs"></ion-checkbox>
  </ion-item>

  <ion-row>
    <ion-col>
      <ion-button
        (click)='this.changeProfileViewState("login")'
        size="default"
        style="border-radius: 2rem; color: black"
        color="split-secondary"
        expand="full"
        >Zurück</ion-button
      >
    </ion-col>
    <ion-col>
      <ion-button
        size="default"
        style="border-radius: 2rem; color: black"
        color="orderbird"
        expand="full"
        (click)="this.signUp(this.regName, this.regLastname, this.regEmail, this.regPW, this.regrePW)"
        >Los Gehts!</ion-button
      >
    </ion-col>
  </ion-row>
</div>
