import { Component, Input, OnInit } from '@angular/core'
import {
  FormGroup,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms'
import { ModalController, NavController, Platform } from '@ionic/angular'
import { Storage } from '@ionic/storage'
import { CartService } from 'src/app/services/cart.service'
import { GastroService } from 'src/app/services/gastro.service'
import { TableService } from 'src/app/services/table.service'
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'app-corona-form',
  templateUrl: './corona-form.page.html',
  styleUrls: ['./corona-form.page.scss'],
})
export class CoronaFormPage implements OnInit {
  public additionalPersons: any = []
  public name = ''
  public phone = ''
  public address = ''
  public emailAddress = ''
  public saveCoronaInfosOnDevice = false
  public hasMarketing = false
  constructor(
    public viewCtrl: NavController,
    private modalCtrl: ModalController,
    public platform: Platform,
    public formBuilder: UntypedFormBuilder,
    private storage: Storage,
    public cartService: CartService,
    private tableService: TableService,
    private userService: UserService,
    private gastroService: GastroService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.storage.get('saveCoronaInfo').then((e) => {
      if (e == true) {
        this.storage.get('coronaName').then((e) => {
          this.name = e
        })
        this.storage.get('coronaPhone').then((e) => {
          this.phone = e
        })
        this.storage.get('coronaAdress').then((e) => {
          this.address = e
        })
        this.storage.get('saveCoronaInfo').then((e) => {
          this.saveCoronaInfosOnDevice = e
        })
      }
    })
  }

  dismiss() {
    this.handleCoronaData()
    this.modalCtrl.dismiss({ success: true })
  }

  submitForm() {
    this.dismiss()
  }

  addAdditionalPerson() {
    this.additionalPersons.push({ name: '', phone: '' })
  }

  removeAdditionalPerson(index) {
    this.additionalPersons.splice(index, 1)
  }

  async handleCoronaData() {
    const offset = 90
    const now = new Date()
    const end = new Date()
    let tableNr = 0
    tableNr = this.tableService.getTable().tischNR

    end.setHours(end.getHours(), end.getMinutes() + offset)
    this.userService.user.name = this.name
    const coronaInformations = {
      additionalPersons: this.additionalPersons,
      address: this.address,
      endTime: `${end.toLocaleDateString()} ${end.toLocaleTimeString()}`,
      name: this.name,
      phone: this.phone,
      table: tableNr,
      time: `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`,
    }
    if (this.saveCoronaInfosOnDevice) {
      this.storage.set('coronaName', coronaInformations.name)
      this.storage.set('coronaPhone', coronaInformations.phone)
      this.storage.set('coronaAdress', coronaInformations.address)
      this.storage.set('saveCoronaInfo', this.saveCoronaInfosOnDevice)
    }
    this.gastroService.saveCoronaInfos(coronaInformations)

    if (this.hasMarketing) {
      const marketingInformation = {
        name: this.name,
        phone: this.phone,
        email: this.emailAddress,
      }
      this.gastroService.saveMarketingInformation(marketingInformation)
    }

    this.storage.set('askedCorona', true)
  }
}
