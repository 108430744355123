<!-- <ion-header>
  <link href="https://fonts.googleapis.com/css?family=Oswald" rel="stylesheet">
</ion-header> -->
<ion-content
  *ngIf="this.sessionDataService.$comesFromPlatform == true || this.templateStyleService.$templateConfig.layout === 'Unset' || this.templateStyleService.$templateConfig.layout === ''"
  class="ion-padding"
  [scrollEvents]="true"
  #content
  (ionScroll)="scrolling($event)"
>
  <app-header (navigateBackEvent)="backToSite()"></app-header>
  <div
    id="horizontalCategorySelector"
    style="
      background-color: white;
      box-shadow: 0 2px 2px -2px rgb(0 0 0 / 30%);
      margin-left: -20px;
      margin-right: -20px;
      position: sticky;
      top: -16px;
      z-index: 20000;
    "
  >
    <ion-backdrop *ngIf="showBackdrop" [tappable]="false"></ion-backdrop>
    <ion-tab-bar
      id="tbBaseComponent"
      mode="ios"
      style="padding-bottom: 8px; padding-top: 8px; z-index: 2000000000"
    >
      <ng-container
        *ngIf="(sessionDataService.$inhouseLink && gastroService.$gastro.popDishesInhouseShown) || (!sessionDataService.$inhouseLink && gastroService.$gastro.popDishesOuterhouseShown)"
      >
        <ion-tab-button
          style="background: none; margin: 10px"
          [selected]="this.displayedCategory == 'popDishes'"
          (click)="scrollTo('popDishes')"
        >
          <ion-label
            [id]="'tbpopDishes'"
            [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font}"
            class="categories"
            >GERADE BELIEBT</ion-label
          >
        </ion-tab-button>
      </ng-container>
      <ng-container *ngFor="let category of categories; let i = index">
        <ion-tab-button
          [selected]="this.displayedCategory == category.id"
          style="margin: 10px"
          (click)="scrollTo(category.id)"
          *ngIf="(this.sessionDataService.$inhouseLink ? category.inhouseVisible : category.outerhouseVisible) && !menuService.isCategoryEmpty(category.id)"
        >
          <ion-label [id]="'tb' + category.id" class="categories"
            >{{this.getCategoryTranslation(category).name}}</ion-label
          >
        </ion-tab-button>
      </ng-container>
      <ion-tab-button style="margin: 10px" (click)="scrollTo('hinweise')">
        <ion-label [id]="'tbhinweise'" class="categories">Hinweise</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
    <app-closed-banner></app-closed-banner>
    <!--DISBALED until we validated opening hours are valid-->
    <!-- <ion-tab-button  class="info-button" (click)="openInfoPopOver()">
			<ion-label
				[ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
				class="categories"><ion-icon style="color: #012c40" size="large" name="information-circle-outline"></ion-icon></ion-label>
		</ion-tab-button> -->
  </div>

  <ion-item
    *ngIf="gastroService.isTogoOptionAvailable === false && sessionDataService.$inhouseLink === false && gastroService.gastroLoaded === true; else banner2"
    color="danger"
    style="
      margin-left: -16px;
      margin-right: -16px;
      text-align: center;
      z-index: 5;
    "
  >
    <ion-label
      class="ion-text-wrap"
      style="hyphens: auto; word-break: break-word"
    >
      Der Liefer/Pickupservice ist leider gerade nicht verfügbar. Schau doch
      später nochmal vorbei.
    </ion-label>
  </ion-item>

  <ng-template #banner2>
    <ion-item
      *ngIf="this.gastroService.$gastro.inhouseInactive && sessionDataService.$inhouseLink === true && gastroService.gastroLoaded === true; else banner3"
      color="danger"
      style="
        margin-left: -16px;
        margin-right: -16px;
        text-align: center;
        z-index: 5;
      "
    >
      <ion-label
        class="ion-text-wrap"
        style="hyphens: auto; word-break: break-word"
      >
        Bestellung per QR-Code ist leider gerade nicht verfügbar. Du kannst aber
        gerne beim Service bestellen.
      </ion-label>
    </ion-item>
  </ng-template>

  <ng-template #banner3>
    <app-banner
      *ngIf="((gastroService.hasRecentHeartbeat | async) === false || (this.gastroService.isInShift === false)) && (this.orderbirdService.active | async) === true && this.menuService.menuUnclickable === false"
    >
      Sorry, Bestellen per QR-Code geht gerade nicht. Bitte frage das Personal
      um Hilfe.
    </app-banner>
  </ng-template>

  <app-template-skeleton layout="Default"></app-template-skeleton>

  <!--Popular Dishes-->
  <ng-container
    *ngIf="(sessionDataService.$inhouseLink && gastroService.$gastro.popDishesInhouseShown) || (!sessionDataService.$inhouseLink && gastroService.$gastro.popDishesOuterhouseShown)"
  >
    <ng-container>
      <ion-row [id]="'catpopDishes'">
        <ion-item-divider mode="md" style="padding: 16px">
          <ion-col>
            <h1 style="color: black">GERADE BELIEBT</h1>
          </ion-col>
        </ion-item-divider>
      </ion-row>
      <ng-container *ngFor="let dish of this.topDishes; let in = index">
        <ion-row class="list-item" (click)="addToCart(dish, in)">
          <ion-item-divider class="list-item-div" mode="md">
            <ion-col
              sizeSm="7"
              sizeXs="7"
              sizeMd="9"
              sizeLg="10"
              style="margin-top: -8px"
            >
              <ion-row>
                <h1 class="producttitle">
                  <span
                    class="plusminus"
                    [id]="'pm'+in"
                    *ngIf="!this.menuService.isUnclickable()"
                    name="cart"
                    >+</span
                  >
                  <span
                    class="plusminus"
                    *ngIf="this.menuService.isUnclickable()"
                    >&#8203;</span
                  >
                  <div class="productName">
                    <span style="color: #3a7995" *ngIf="countDish(dish)>0"
                      >{{countDish(dish)}}x </span
                    >{{this.getProductTranslation(dish).name}}
                  </div>
                  <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                </h1>
              </ion-row>
              <ion-row style="margin-left: 37px; margin-top: -24px">
                <ng-container
                  *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                >
                  {{descriptionItem}}<br />
                </ng-container>
              </ion-row>
              <ion-row style="margin-bottom: 8px">
                <ion-col>
                  <ng-container
                    *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                  >
                    <h1 class="productprice">
                      <ng-container
                        *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                      >
                        ab {{ (dish.inhousePrice +
                        dish.extraData.minInhousePrice) |
                        price:getPriceFormat()}}
                      </ng-container>
                      <ng-container
                        *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                      >
                        {{ (dish.inhousePrice) | price:getPriceFormat()}}
                      </ng-container>
                    </h1>
                  </ng-container>
                  <ng-container
                    *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                  >
                    <h1 class="productprice">
                      <ng-container
                        *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                      >
                        ab {{ (dish.outerhousePrice +
                        dish.extraData.minOuterhousePrice) |
                        price:getPriceFormat()}}
                      </ng-container>
                      <ng-container
                        *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                      >
                        {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                      </ng-container>
                    </h1>
                  </ng-container>
                </ion-col>
              </ion-row>
              <ion-badge
                *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
							|| this.gastroService.$gastro.hasNoOfferFlag === false)"
                class="my-badge"
                color="danger"
                >Angebot
              </ion-badge>
            </ion-col>
            <ion-col
              sizeSm="5"
              sizeXs="5"
              sizeMd="3"
              sizeLg="2"
              *ngIf="dish.img"
            >
              <div
                (click)="openZoomedImage(dish.img, $event)"
                class="productImage"
                [style]="'background-image: url(' + dish.img +');background-size: contain;
			background-repeat: no-repeat;'"
              ></div>
            </ion-col>
          </ion-item-divider>
        </ion-row>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let category of this.categories">
    <ng-container
      *ngIf="(this.sessionDataService.$inhouseLink ? category.inhouseVisible : category.outerhouseVisible) && !menuService.isCategoryEmpty(category.id)"
    >
      <ion-row [id]="'cat' + category.id">
        <ion-item-divider mode="md" style="padding: 16px">
          <ion-col>
            <h1 style="color: black">
              {{ this.getCategoryTranslation(category).name }}
            </h1>
            <span
              [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesDescConfig.font, 'color':this.templateStyleService.$templateConfig.categoriesDescConfig.color}"
              >{{ this.getCategoryTranslation(category).description }}</span
            >
          </ion-col>
        </ion-item-divider>
      </ion-row>
      <ng-container *ngFor="let dish of this.dishes; let in = index">
        <ion-row
          class="list-item"
          *ngIf="dish.categoryId == category.id && offerAvailable(dish)
	&& (this.sessionDataService.$inhouseLink ? dish.inhouseVisible : dish.outerhouseVisible)"
          (click)="addToCart(dish, in)"
        >
          <ion-item-divider class="list-item-div" mode="md">
            <ion-col
              sizeSm="7"
              sizeXs="7"
              sizeMd="9"
              sizeLg="10"
              style="margin-top: -8px"
            >
              <ion-row>
                <h1 class="producttitle">
                  <span
                    class="plusminus"
                    [id]="'pm'+in"
                    *ngIf="!this.menuService.isUnclickable()"
                    name="cart"
                    >+</span
                  >
                  <span
                    class="plusminus"
                    *ngIf="this.menuService.isUnclickable()"
                    >&#8203;</span
                  >
                  <div class="productName">
                    <span style="color: #3a7995" *ngIf="countDish(dish)>0"
                      >{{countDish(dish)}}x
                    </span>
                    {{this.getProductTranslation(dish).name}}
                    <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                  </div>
                </h1>
              </ion-row>
              <ion-row style="margin-left: 37px; margin-top: -24px">
                <ng-container
                  *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                >
                  {{descriptionItem}}<br />
                </ng-container>
              </ion-row>
              <ion-row style="margin-bottom: 8px">
                <ion-col>
                  <ng-container
                    *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                  >
                    <h1 class="productprice">
                      <ng-container
                        *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                      >
                        ab {{ (dish.inhousePrice +
                        dish.extraData.minInhousePrice) |
                        price:getPriceFormat()}}
                      </ng-container>
                      <ng-container
                        *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                      >
                        {{ (dish.inhousePrice) | price:getPriceFormat()}}
                      </ng-container>
                    </h1>
                  </ng-container>
                  <ng-container
                    *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                  >
                    <h1 class="productprice">
                      <ng-container
                        *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                      >
                        ab {{ (dish.outerhousePrice +
                        dish.extraData.minOuterhousePrice) |
                        price:getPriceFormat()}}
                      </ng-container>
                      <ng-container
                        *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                      >
                        {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                      </ng-container>
                    </h1>
                  </ng-container>
                </ion-col>
              </ion-row>
              <ion-badge
                *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
							|| this.gastroService.$gastro.hasNoOfferFlag === false)"
                class="my-badge"
                color="danger"
                >Angebot
              </ion-badge>
            </ion-col>
            <ion-col
              sizeSm="5"
              sizeXs="5"
              sizeMd="3"
              sizeLg="2"
              *ngIf="dish.img"
            >
              <div
                (click)="openZoomedImage(dish.img, $event)"
                class="productImage"
                [style]="'background-image: url(' + dish.img +');background-size: contain;
			background-repeat: no-repeat;'"
              ></div>
            </ion-col>
          </ion-item-divider>
        </ion-row>
      </ng-container>
    </ng-container>
  </ng-container>
  <!--HINWEIS CATEGORY-->
  <ion-row [id]="'cathinweise'">
    <ion-col>
      <h1 style="color: black">Hinweise</h1>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-row>
        <h1 class="producttitle">Allergene & Zusatzstoffe</h1>
      </ion-row>
      <ion-row>
        <div
          *ngIf="this.gastroService.$gastro.allergicLink == undefined || this.gastroService.$gastro.allergicLink == ''"
          class="productdescription"
        >
          Für Allergene & Zusatzstoffe bitte das Personal fragen
        </div>
        <div
          (click)="alergicOpen(this.gastroService.$gastro.allergicLink)"
          *ngIf="this.gastroService.$gastro.allergicLink != undefined && this.gastroService.$gastro.allergicLink != ''"
          class="productdescription"
        >
          Für Allergene & Zusatzstoffe hier klicken
        </div>
      </ion-row>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col style="text-align: center">
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="/impressum"
        target="_blank"
        >Impressum</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/datenschutzerklaerung-bestellmanagement"
        target="_blank"
        >Datenschutz</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/agb-bestellmanagement"
        target="_blank"
        >AGB's</a
      >
    </ion-col>
  </ion-row>
</ion-content>

<!-- TEMPLATES bzw. Digitale Speisekarte default-->
<ion-content
  *ngIf="this.sessionDataService.$comesFromPlatform == false && this.templateStyleService.$templateConfig.layout == 'Default'"
  class="ion-padding"
  [scrollEvents]="true"
  #content
  style="

--background: none"
  (ionScroll)="scrolling($event)"
>
  <app-header (navigateBackEvent)="backToSite()"></app-header>

  <div
    id="horizontalCategorySelector"
    style="
      box-shadow: 0 2px 2px -2px rgb(0 0 0 / 30%);
      margin-left: -20px;
      margin-right: -20px;
      position: sticky;
      top: 48px;
      z-index: 20000;
    "
    [ngStyle]="{ 'background' : this.templateStyleService.$templateConfig.categoriesConfig.bgColor}"
  >
    <ion-backdrop *ngIf="showBackdrop" [tappable]="false"></ion-backdrop>
    <ion-tab-bar
      id="tbBaseComponent"
      mode="ios"
      style="
        background: none;
        padding-bottom: 8px;
        padding-top: 8px;
        z-index: 2000000000;
      "
    >
      <ng-container
        *ngIf="(sessionDataService.$inhouseLink && gastroService.$gastro.popDishesInhouseShown) || (!sessionDataService.$inhouseLink && gastroService.$gastro.popDishesOuterhouseShown)"
      >
        <ion-tab-button
          style="background: none; margin: 10px"
          [selected]="this.displayedCategory == 'popDishes'"
          (click)="scrollTo('popDishes')"
        >
          <ion-label
            [id]="'tbpopDishes'"
            [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font}"
            class="categories"
            >GERADE BELIEBT</ion-label
          >
        </ion-tab-button>
      </ng-container>
      <ng-container *ngFor="let category of categories; let i = index">
        <ion-tab-button
          [selected]="this.displayedCategory == category.id"
          style="background: none; margin: 10px"
          (click)="scrollTo(category.id)"
          *ngIf="(this.sessionDataService.$inhouseLink ? category.inhouseVisible : category.outerhouseVisible) && !menuService.isCategoryEmpty(category.id)"
        >
          <ion-label
            [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color':this.templateStyleService.$templateConfig.categoriesConfig.color}"
            [id]="'tb' + category.id"
            class="categories"
            >{{this.getCategoryTranslation(category).name}}</ion-label
          >
        </ion-tab-button>
      </ng-container>
      <ion-tab-button
        style="background: none; margin: 10px"
        (click)="scrollTo('hinweise')"
      >
        <ion-label
          [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color':this.templateStyleService.$templateConfig.categoriesConfig.color}"
          [id]="'tbhinweise'"
          class="categories"
          >Hinweise</ion-label
        >
      </ion-tab-button>
    </ion-tab-bar>
    <app-closed-banner></app-closed-banner>
    <!--DISBALED until we validated opening hours are valid-->
    <!-- <ion-tab-button  class="info-button" (click)="openInfoPopOver()">
				<ion-label
					[ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
					class="categories"><ion-icon style="color: #012c40" size="large" name="information-circle-outline"></ion-icon></ion-label>
			</ion-tab-button> -->
  </div>
  <ion-item
    *ngIf="gastroService.isTogoOptionAvailable === false && sessionDataService.$inhouseLink === false && gastroService.gastroLoaded === true; else banner2"
    color="danger"
    style="
      margin-left: -16px;
      margin-right: -16px;
      text-align: center;
      z-index: 5;
    "
  >
    <ion-label
      class="ion-text-wrap"
      style="hyphens: auto; word-break: break-word"
    >
      Der Liefer/Pickupservice ist leider gerade nicht verfügbar. Schau doch
      später nochmal vorbei.
    </ion-label>
  </ion-item>
  <ng-template #banner2>
    <ion-item
      *ngIf="this.gastroService.$gastro.inhouseInactive && sessionDataService.$inhouseLink === true && gastroService.gastroLoaded === true; else banner3"
      color="danger"
      style="
        margin-left: -16px;
        margin-right: -16px;
        text-align: center;
        z-index: 5;
      "
    >
      <ion-label
        class="ion-text-wrap"
        style="hyphens: auto; word-break: break-word"
      >
        Bestellung per QR-Code ist leider gerade nicht verfügbar. Du kannst aber
        gerne beim Service bestellen.
      </ion-label>
    </ion-item>
  </ng-template>
  <ng-template #banner3>
    <app-banner
      *ngIf="((gastroService.hasRecentHeartbeat | async) === false || (this.gastroService.isInShift === false)) && (this.orderbirdService.active | async) === true && this.menuService.menuUnclickable === false"
    >
      Sorry, Bestellen per QR-Code geht gerade nicht. Bitte frage das Personal
      um Hilfe.
    </app-banner>
  </ng-template>

  <app-template-skeleton layout="Default"></app-template-skeleton>

  <div style="background: none; opacity: 1; position: relative">
    <div
      [ngStyle]="{'background-image': 'url(' + this.templateStyleService.$templateConfig.backgroundImage + ')','opacity':this.templateStyleService.$templateConfig.backgroundOpacity,'background-size':'100%','position':'absolute','width':'100vw','height':'100%', 'margin-left':'-16px', 'margin-right':'-16px'}"
    ></div>

    <!--Popular Dishes-->
    <ng-container
      *ngIf="(sessionDataService.$inhouseLink && gastroService.$gastro.popDishesInhouseShown) || (!sessionDataService.$inhouseLink && gastroService.$gastro.popDishesOuterhouseShown)"
    >
      <ng-container>
        <ion-row [id]="'catpopDishes'">
          <ion-item-divider mode="md" style="background: none; padding: 16px">
            <ion-col>
              <h1
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.headerConfig.font, 'color' : this.templateStyleService.$templateConfig.headerConfig.color}"
                style="color: black"
              >
                GERADE BELIEBT
              </h1>
            </ion-col>
          </ion-item-divider>
        </ion-row>
        <ng-container *ngFor="let dish of this.topDishes; let in = index">
          <ion-row class="list-item" (click)="addToCart(dish, in)">
            <ion-item-divider
              class="list-item-div"
              mode="md"
              style="background: none"
            >
              <ion-col
                sizeSm="7"
                sizeXs="7"
                sizeMd="9"
                sizeLg="10"
                style="margin-top: -8px"
              >
                <ion-row>
                  <h1 class="producttitle">
                    <span
                      class="plusminus"
                      [id]="'pm'+in"
                      *ngIf="!this.menuService.isUnclickable()"
                      style="background: none"
                      name="cart"
                      [ngStyle]="{'color': this.templateStyleService.$templateConfig.buttonColor}"
                      >+</span
                    >
                    <span
                      class="plusminus"
                      [ngStyle]="{'color': this.templateStyleService.$templateConfig.buttonColor}"
                      *ngIf="this.menuService.isUnclickable()"
                      style="background: none"
                      >&#8203;</span
                    >
                    <div
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.dishConfig.font, 'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
                      class="productName"
                    >
                      <span
                        [ngStyle]="{'color': this.templateStyleService.$templateConfig.buttonColor}"
                        *ngIf="countDish(dish)>0"
                        >{{countDish(dish)}}x </span
                      >{{this.getProductTranslation(dish).name}}
                    </div>
                    <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                  </h1>
                </ion-row>
                <ion-row
                  [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.descConfig.font, 'color' : this.templateStyleService.$templateConfig.descConfig.color}"
                  style="margin-left: 37px; margin-top: -24px"
                >
                  <ng-container
                    *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                  >
                    {{descriptionItem}}<br />
                  </ng-container>
                </ion-row>
                <ion-row style="margin-bottom: 8px">
                  <ion-col>
                    <ng-container
                      *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                    >
                      <h1
                        class="productprice"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                        >
                          ab {{ (dish.inhousePrice +
                          dish.extraData.minInhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                        >
                          {{ (dish.inhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </h1>
                    </ng-container>
                    <ng-container
                      *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                    >
                      <h1
                        class="productprice"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                        >
                          ab {{ (dish.outerhousePrice +
                          dish.extraData.minOuterhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                        >
                          {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </h1>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-badge
                  *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
                                || this.gastroService.$gastro.hasNoOfferFlag === false)"
                  class="my-badge"
                  color="danger"
                  >Angebot
                </ion-badge>
              </ion-col>
              <ion-col
                *ngIf="dish.allergens!=undefined && dish.allergens.length > 0 && this.gastroService.$gastro.hasAllergens"
              >
                <ion-icon
                  style="font-size: 22px"
                  name="information-circle-outline"
                  (click)="openAllergenPopOver(dish, $event)"
                ></ion-icon>
              </ion-col>
              <ion-col
                sizeSm="5"
                sizeXs="5"
                sizeMd="3"
                sizeLg="2"
                *ngIf="dish.img"
              >
                <div
                  (click)="openZoomedImage(dish.img, $event)"
                  class="productImage"
                  [style]="'background-image: url(' + dish.img +');background-size: contain;
              background-repeat: no-repeat;'"
                ></div>
              </ion-col>
            </ion-item-divider>
          </ion-row>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let category of this.categories">
      <ng-container
        *ngIf="(this.sessionDataService.$inhouseLink ? category.inhouseVisible : category.outerhouseVisible) && !menuService.isCategoryEmpty(category.id)"
      >
        <ion-row [id]="'cat' + category.id">
          <ion-item-divider mode="md" style="background: none; padding: 16px">
            <ion-col>
              <h1
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.headerConfig.font, 'color' : this.templateStyleService.$templateConfig.headerConfig.color}"
              >
                {{ this.getCategoryTranslation(category).name }}
              </h1>
              <span
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesDescConfig.font, 'color':this.templateStyleService.$templateConfig.categoriesDescConfig.color}"
                >{{ this.getCategoryTranslation(category).description }}</span
              >
            </ion-col>
          </ion-item-divider>
        </ion-row>
        <ng-container *ngFor="let dish of this.dishes; let in = index">
          <ion-row
            class="list-item"
            *ngIf="dish.categoryId == category.id && offerAvailable(dish)
            && (this.sessionDataService.$inhouseLink ? dish.inhouseVisible : dish.outerhouseVisible)"
            (click)="addToCart(dish, in)"
          >
            <ion-item-divider
              class="list-item-div"
              mode="md"
              style="background: none"
            >
              <ion-col
                sizeSm="7"
                sizeXs="7"
                sizeMd="9"
                sizeLg="10"
                style="margin-top: -8px"
              >
                <ion-row>
                  <h1 class="producttitle">
                    <span
                      class="plusminus"
                      [id]="'pm'+in"
                      *ngIf="!this.menuService.isUnclickable()"
                      name="cart"
                      style="background: none"
                      [ngStyle]="{'color': this.templateStyleService.$templateConfig.buttonColor}"
                      >+</span
                    >
                    <span
                      class="plusminus"
                      *ngIf="this.menuService.isUnclickable()"
                      >&#8203;</span
                    >
                    <div
                      class="productName"
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.dishConfig.font, 'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
                    >
                      <span
                        *ngIf="countDish(dish)>0"
                        [ngStyle]="{ 'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        {{countDish(dish)}}x
                      </span>
                      {{this.getProductTranslation(dish).name}}
                      <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                    </div>
                  </h1>
                </ion-row>
                <ion-row style="margin-left: 37px; margin-top: -24px">
                  <ng-container
                    *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                  >
                    <ion-label
                      text-wrap
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.descConfig.font, 'color' : this.templateStyleService.$templateConfig.descConfig.color}"
                    >
                      {{descriptionItem}}<br
                    /></ion-label>
                  </ng-container>
                </ion-row>
                <ion-row style="margin-bottom: 8px">
                  <ion-col>
                    <ng-container
                      *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                    >
                      <h1
                        class="productprice"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                        >
                          ab {{ (dish.inhousePrice +
                          dish.extraData.minInhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                        >
                          {{ (dish.inhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </h1>
                    </ng-container>
                    <ng-container
                      *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                    >
                      <h1
                        class="productprice"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                        >
                          ab {{ (dish.outerhousePrice +
                          dish.extraData.minOuterhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                        >
                          {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </h1>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-badge
                  *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
									|| this.gastroService.$gastro.hasNoOfferFlag === false)"
                  class="my-badge"
                  color="danger"
                  >Angebot
                </ion-badge>
              </ion-col>
              <ion-col
                *ngIf="dish.allergens!=undefined && dish.allergens.length > 0 && this.gastroService.$gastro.hasAllergens"
              >
                <ion-icon
                  style="font-size: 22px"
                  name="information-circle-outline"
                  (click)="openAllergenPopOver(dish, $event)"
                ></ion-icon>
              </ion-col>
              <ion-col
                sizeSm="5"
                sizeXs="5"
                sizeMd="3"
                sizeLg="2"
                *ngIf="dish.img"
              >
                <div
                  (click)="openZoomedImage(dish.img, $event)"
                  class="productImage"
                  [style]="'background-image: url(' + dish.img +');background-size: contain;
                  background-repeat: no-repeat;'"
                ></div>
              </ion-col>
            </ion-item-divider>
          </ion-row>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!--HINWEIS CATEGORY-->
  <ion-row [id]="'cathinweise'">
    <ion-col>
      <h1 style="color: black">Hinweise</h1>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-row>
        <h1 class="producttitle">Allergene & Zusatzstoffe</h1>
      </ion-row>
      <ion-row>
        <div
          *ngIf="this.gastroService.$gastro.allergicLink == undefined || this.gastroService.$gastro.allergicLink == ''"
          class="productdescription"
        >
          Für Allergene & Zusatzstoffe bitte das Personal fragen
        </div>
        <div
          (click)="alergicOpen(this.gastroService.$gastro.allergicLink)"
          *ngIf="this.gastroService.$gastro.allergicLink != undefined && this.gastroService.$gastro.allergicLink != ''"
          class="productdescription"
        >
          Für Allergene & Zusatzstoffe hier klicken
        </div>
      </ion-row>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col style="text-align: center">
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="/impressum"
        target="_blank"
        >Impressum</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/datenschutzerklaerung-bestellmanagement"
        target="_blank"
        >Datenschutz</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/agb-bestellmanagement"
        target="_blank"
        >AGB's</a
      >
    </ion-col>
  </ion-row>
</ion-content>

<!-- DIGITALE SPEISEKARTE kacheln -->
<ion-content
  *ngIf="this.sessionDataService.$comesFromPlatform == false && this.templateStyleService.$templateConfig.layout == 'Kacheln'"
  class="ion-padding"
  [scrollEvents]="true"
  #content
  style="

--background: none"
  (ionScroll)="scrolling($event)"
>
  <app-header (navigateBackEvent)="backToSite()"></app-header>

  <!-- this sections shows the kacheln/ categories -->
  <app-template-skeleton layout="Kacheln"></app-template-skeleton>
  <div
    id="horizontalCategorySelector"
    style="
      background: none;
      box-shadow: 0 2px 2px -2px rgb(0 0 0 / 30%);
      margin-left: -20px;
      margin-right: -20px;
      position: relative;
      top: 10px;
      z-index: 20000;
    "
  >
    <div
      *ngIf="this.selectedCategory === undefined"
      [ngStyle]="{'background-image': 'url(' + this.templateStyleService.$templateConfig.backgroundImage + ')','opacity':this.templateStyleService.$templateConfig.backgroundOpacity,'background-size':'100%','position':'absolute','width':'100vw','height':'100%', 'margin-left':'4px', 'margin-right':'-16px'}"
    ></div>
    <ion-backdrop *ngIf="showBackdrop" [tappable]="false"></ion-backdrop>
    <ion-tab-bar
      *ngIf="this.selectedCategory === undefined"
      id="tbBaseComponent"
      mode="ios"
      style="
        font-size: 1em;
        justify-content: center;
        margin-top: -4px;
        padding-bottom: 8px;
        z-index: 2000000000;
      "
    >
      <ion-tab-button
        (click)="this.foodDrinkFilter = 0"
        style="border-radius: 20px"
        [ngClass]="{'selectedTabButtonItem': this.foodDrinkFilter === 0}"
        selected="true"
      >
        <ion-item
          [ngClass]="{'selectedTabButton': this.foodDrinkFilter === 0}"
          lines="none"
        >
          <ion-icon
            style="margin: 0 6px 6px 0"
            src="../../assets/icons/food.svg"
          ></ion-icon>
          <ion-label
            [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
            class="categories"
            >Essen</ion-label
          >
        </ion-item>
      </ion-tab-button>
      <ion-tab-button
        style="border-radius: 20px"
        (click)="this.foodDrinkFilter = 1"
        [ngClass]="{'selectedTabButtonItem': this.foodDrinkFilter === 1}"
      >
        <ion-item
          [ngClass]="{'selectedTabButton': this.foodDrinkFilter === 1}"
          lines="none"
          lines="none"
        >
          <ion-icon
            style="margin: 0 6px 6px 0"
            src="../../assets/icons/drink.svg"
          ></ion-icon>
          <ion-label
            [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
            class="categories"
            >Getränke</ion-label
          >
        </ion-item>
      </ion-tab-button>
    </ion-tab-bar>
    <!--DISBALED until we validated opening hours are valid-->
    <!-- <ion-tab-button class="info-button" (click)="openInfoPopOver()">
				<ion-label
					[ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
					class="categories"><ion-icon style="color: #012c40" size="large" name="information-circle-outline"></ion-icon></ion-label>
			</ion-tab-button> -->
    <ion-tab-bar
      *ngIf="this.selectedCategory != undefined"
      id="tbBaseComponent"
      mode="ios"
      style="cursor: pointer; padding-bottom: 8px; z-index: 2000000000"
    >
      <ion-tab-button (click)="goBackFromSelectedCategory()">
        <ion-label
          [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
          style="font-size: 0.9em"
          class="categories"
          >Zurück</ion-label
        >
      </ion-tab-button>
    </ion-tab-bar>

    <ion-item
      *ngIf="gastroService.isTogoOptionAvailable === false && sessionDataService.$inhouseLink === false && gastroService.gastroLoaded === true; else banner2"
      color="danger"
      style="
        margin-left: -16px;
        margin-right: -16px;
        text-align: center;
        z-index: 5;
      "
    >
      <ion-label
        class="ion-text-wrap"
        style="hyphens: auto; word-break: break-word"
      >
        Der Liefer/Pickupservice ist leider gerade nicht verfügbar. Schau doch
        später nochmal vorbei.
      </ion-label>
    </ion-item>

    <app-closed-banner></app-closed-banner>

    <ng-template #banner2>
      <ion-item
        *ngIf="this.gastroService.$gastro.inhouseInactive && sessionDataService.$inhouseLink === true && gastroService.gastroLoaded === true; else banner3"
        color="danger"
        style="
          margin-left: -16px;
          margin-right: -16px;
          text-align: center;
          z-index: 5;
        "
      >
        <ion-label
          class="ion-text-wrap"
          style="hyphens: auto; word-break: break-word"
        >
          Bestellung per QR-Code ist leider gerade nicht verfügbar. Du kannst
          aber gerne beim Service bestellen.
        </ion-label>
      </ion-item>
    </ng-template>

    <ng-template #banner3>
      <app-banner
        *ngIf="((gastroService.hasRecentHeartbeat | async) === false || (this.gastroService.isInShift === false)) && (this.orderbirdService.active | async) === true && this.menuService.menuUnclickable === false"
      >
        Sorry, Bestellen per QR-Code geht gerade nicht. Bitte frage das Personal
        um Hilfe.
      </app-banner>
    </ng-template>

    <ion-row
      class="cat-kachel-container ion-align-self-center"
      style="justify-content: center"
      *ngIf="this.selectedCategory ==undefined"
    >
      <div
        class="ion-align-self-center"
        *ngIf="this.foodDrinkFilter === 0"
        style="margin-bottom: 8px"
      >
        <ion-card
          *ngIf="(sessionDataService.$inhouseLink && gastroService.$gastro.popDishesInhouseShown) || (!sessionDataService.$inhouseLink && gastroService.$gastro.popDishesOuterhouseShown)"
          class="kachelnCard"
          (click)="goToCategory('popDishes')"
          style="cursor: pointer"
        >
          <ion-grid>
            <ion-row>
              <ion-col class="ion-text-center">
                <ion-label
                  [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color,'font-size':'large'}"
                >
                  GERADE BELIEBT</ion-label
                >
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>
      <ng-container *ngFor="let category of categories; let i = index">
        <ng-container
          *ngIf="category.isFood === true && this.foodDrinkFilter === 0 || category.isFood === false && this.foodDrinkFilter === 1"
        >
          <ng-container
            *ngIf="(this.sessionDataService.$inhouseLink ? category.inhouseVisible : category.outerhouseVisible) && !menuService.isCategoryEmpty(category.id)"
          >
            <div class="ion-align-self-center" style="margin-bottom: 8px">
              <ion-card
                class="kachelnCard"
                (click)="goToCategory(category)"
                style="cursor: pointer"
              >
                <img
                  class="card-background-image"
                  [src]="category.categoryImg"
                />
                <ion-grid>
                  <ion-row>
                    <ion-col class="ion-text-center">
                      <ion-label
                        style="hyphens: auto; word-break: break-word"
                        [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color,'font-size':'large'}"
                      >
                        {{this.getCategoryTranslation(category).name}}</ion-label
                      >
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ion-row>
  </div>

  <!-- display the selected category -->
  <div
    style="background: none; opacity: 1; position: relative"
    *ngIf="this.selectedCategory != undefined"
  >
    <div
      [ngStyle]="{'background-image': 'url(' + this.templateStyleService.$templateConfig.backgroundImage + ')','opacity':this.templateStyleService.$templateConfig.backgroundOpacity,'background-size':'100%','position':'absolute','width':'100vw','height':'100%', 'margin-left':'-16px', 'margin-right':'-16px'}"
    ></div>

    <ng-container *ngIf="selectedCategory=='popDishes'">
      <ng-container>
        <ion-row [id]="'catpopDishes'">
          <ion-item-divider mode="md" style="background: none; padding: 16px">
            <ion-col>
              <h1
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.headerConfig.font, 'color' : this.templateStyleService.$templateConfig.headerConfig.color}"
              >
                GERADE BELIEBT
              </h1>
            </ion-col>
          </ion-item-divider>
        </ion-row>
        <ng-container *ngFor="let dish of this.topDishes; let in = index">
          <ion-row class="list-item" (click)="addToCart(dish, in)">
            <ion-item-divider
              class="list-item-div"
              mode="md"
              style="background: none"
            >
              <ion-col
                sizeSm="7"
                sizeXs="7"
                sizeMd="9"
                sizeLg="10"
                style="margin-top: -8px"
              >
                <ion-row>
                  <h1 class="producttitle">
                    <span
                      class="plusminus"
                      [id]="'pm'+in"
                      *ngIf="!this.menuService.isUnclickable()"
                      name="cart"
                      style="background: none"
                      [ngStyle]="{'color': this.templateStyleService.$templateConfig.buttonColor}"
                      >+</span
                    >
                    <span
                      class="plusminus"
                      *ngIf="this.menuService.isUnclickable()"
                      >&#8203;</span
                    >
                    <div
                      class="productName"
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.dishConfig.font, 'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
                    >
                      <span
                        *ngIf="countDish(dish)>0"
                        [ngStyle]="{ 'color' : this.templateStyleService.$templateConfig.buttonColor}"
                        >{{countDish(dish)}}x </span
                      >{{this.getProductTranslation(dish).name}}
                      <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                    </div>
                  </h1>
                </ion-row>
                <ion-row style="margin-left: 37px; margin-top: -24px">
                  <ng-container
                    *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                  >
                    <ion-label
                      text-wrap
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.descConfig.font}"
                    >
                      {{descriptionItem}}<br
                    /></ion-label>
                  </ng-container>
                </ion-row>
                <ion-row style="margin-bottom: 8px">
                  <ion-col>
                    <ng-container
                      *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                    >
                      <h1
                        class="productprice"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                        >
                          ab {{ (dish.inhousePrice +
                          dish.extraData.minInhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                        >
                          {{ (dish.inhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </h1>
                    </ng-container>
                    <ng-container
                      *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                    >
                      <h1
                        class="productprice"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                        >
                          ab {{ (dish.outerhousePrice +
                          dish.extraData.minOuterhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                        >
                          {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </h1>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-badge
                  *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
									|| this.gastroService.$gastro.hasNoOfferFlag === false)"
                  class="my-badge"
                  color="danger"
                  >Angebot
                </ion-badge>
                <ion-col
                  *ngIf="dish.allergens!=undefined && dish.allergens.length > 0 && this.gastroService.$gastro.hasAllergens"
                >
                  <ion-icon
                    style="font-size: 22px"
                    name="information-circle-outline"
                    (click)="openAllergenPopOver(dish, $event)"
                  ></ion-icon>
                </ion-col>
              </ion-col>
              <ion-col
                sizeSm="5"
                sizeXs="5"
                sizeMd="3"
                sizeLg="2"
                *ngIf="dish.img"
              >
                <div
                  (click)="openZoomedImage(dish.img, $event)"
                  class="productImage"
                  [style]="'background-image: url(' + dish.img +');background-size: contain;
                  background-repeat: no-repeat;'"
                ></div>
              </ion-col>
            </ion-item-divider>
          </ion-row>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedCategory!='popDishes'">
      <ng-container
        *ngIf="(this.sessionDataService.$inhouseLink ? this.selectedCategory.inhouseVisible : this.selectedCategory.outerhouseVisible) && !menuService.isCategoryEmpty(this.selectedCategory.id)"
      >
        <ion-row [id]="'cat' + this.selectedCategory.id">
          <ion-item-divider mode="md" style="background: none; padding: 16px">
            <ion-col>
              <h1
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.headerConfig.font, 'color' : this.templateStyleService.$templateConfig.headerConfig.color}"
              >
                {{ this.getCategoryTranslation(this.selectedCategory).name }}
              </h1>
              <span
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesDescConfig.font, 'color':this.templateStyleService.$templateConfig.categoriesDescConfig.color}"
                >{{
                this.getCategoryTranslation(this.selectedCategory).description
                }}</span
              >
            </ion-col>
          </ion-item-divider>
        </ion-row>
        <ng-container *ngFor="let dish of this.dishes; let in = index">
          <!--TEMPORARY DISABLED NON SWITCH IN KACHELN-->

          <ion-row
            class="list-item"
            *ngIf="dish.categoryId == this.selectedCategory.id && offerAvailable(dish)
            && (this.sessionDataService.$inhouseLink ? dish.inhouseVisible : dish.outerhouseVisible)"
            (click)="addToCart(dish, in)"
          >
            <ion-item-divider
              class="list-item-div"
              mode="md"
              style="background: none"
            >
              <ion-col
                sizeSm="7"
                sizeXs="7"
                sizeMd="9"
                sizeLg="10"
                style="margin-top: -8px"
              >
                <ion-row>
                  <h1 class="producttitle">
                    <span
                      class="plusminus"
                      [id]="'pm'+in"
                      *ngIf="!this.menuService.isUnclickable()"
                      name="cart"
                      style="background: none"
                      [ngStyle]="{'color': this.templateStyleService.$templateConfig.buttonColor}"
                      >+</span
                    >
                    <span
                      class="plusminus"
                      *ngIf="this.menuService.isUnclickable()"
                      >&#8203;</span
                    >
                    <div
                      class="productName"
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.dishConfig.font, 'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
                    >
                      <span
                        *ngIf="countDish(dish)>0"
                        [ngStyle]="{ 'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        {{countDish(dish)}}x
                      </span>
                      {{this.getProductTranslation(dish).name}}
                      <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                    </div>
                  </h1>
                </ion-row>
                <ion-row style="margin-left: 37px; margin-top: -24px">
                  <ng-container
                    *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                  >
                    <ion-label
                      text-wrap
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.descConfig.font, 'color' : this.templateStyleService.$templateConfig.descConfig.color}"
                    >
                      {{descriptionItem}}<br
                    /></ion-label>
                  </ng-container>
                </ion-row>
                <ion-row style="margin-bottom: 8px">
                  <ion-col>
                    <h1
                      *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                      class="productprice"
                      [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                    >
                      <ng-container
                        *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                      >
                        ab {{ (dish.inhousePrice +
                        dish.extraData.minInhousePrice) |
                        price:getPriceFormat()}}
                      </ng-container>
                      <ng-container
                        *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                      >
                        {{ (dish.inhousePrice) | price:getPriceFormat()}}
                      </ng-container>
                    </h1>
                    <h1
                      *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                      class="productprice"
                      [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                    >
                      <ng-container
                        *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                      >
                        ab {{ (dish.outerhousePrice +
                        dish.extraData.minOuterhousePrice) |
                        price:getPriceFormat()}}
                      </ng-container>
                      <ng-container
                        *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                      >
                        {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                      </ng-container>
                    </h1>
                  </ion-col>
                </ion-row>
                <ion-badge
                  *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
									|| this.gastroService.$gastro.hasNoOfferFlag === false)"
                  class="my-badge"
                  color="danger"
                  >Angebot
                </ion-badge>
              </ion-col>
              <ion-col
                *ngIf="dish.allergens!=undefined && dish.allergens.length > 0 && this.gastroService.$gastro.hasAllergens"
              >
                <ion-icon
                  style="font-size: 22px"
                  name="information-circle-outline"
                  (click)="openAllergenPopOver(dish, $event)"
                ></ion-icon>
              </ion-col>
              <ion-col
                sizeSm="5"
                sizeXs="5"
                sizeMd="3"
                sizeLg="2"
                *ngIf="dish.img"
              >
                <div
                  (click)="openZoomedImage(dish.img, $event)"
                  class="productImage"
                  [style]="'background-image: url(' + dish.img +');background-size: contain;
                  background-repeat: no-repeat;'"
                ></div>
              </ion-col>
            </ion-item-divider>
          </ion-row>

          <!--TEMPORARY DISABLED NON SWITCH IN KACHELN-->

          <!--SWITCH DESIGN IN KACHELN-->

          <!-- <ion-row [ngClass]="{'switcherRow' : dish.img !== undefined && dish.img !== '', 'switcher-row-without-image': dish.img === undefined || dish.img === ''}" *ngIf="dish.categoryId == this.selectedCategory.id && offerAvailable(dish)
                        && (this.sessionDataService.$inhouseLink ? dish.inhouseVisible : dish.outerhouseVisible)">
                                    <ion-col *ngIf="dish.img !== '' && dish.img !== undefined"  class="ion-text-center ion-align-self-center" sizeLg="5" sizeMd="5" sizeSm="5"
                                        sizeXs="6">
                                        <div (click)="openZoomedImage(dish.img, $event)" class=" switcherColImg switch-image" [style]="'background-image: url(' + dish.img +');background-size: contain;
                          background-repeat: no-repeat;width:100%;height:160px;'">
    
                                        </div>
                                    </ion-col>
                                    <ion-col [ngStyle]="{'text-align': dish.img === undefined || dish.img === '' ? 'center' : ''}" class="ion-align-self-center switcherColDishName switch-food-col" (click)="addToCart(dish, in)"
                                        sizeLg="7" sizeMd="7" sizeSm="7" sizeXs="6" style="margin-top:-8px;">
                                        <ion-col class=" ion-align-self-center">
                                            <ion-label style="color:#3A7995" *ngIf="countDish(dish)>0">
                                                {{countDish(dish)}}x
                                            </ion-label>
                                            <ion-label
                                                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.dishConfig.font, 'color' : this.templateStyleService.$templateConfig.dishConfig.color}">
                                                {{this.getProductTranslation(dish).name}}
                                            </ion-label>
                                            <ion-label *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                                                [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}">
                                                <ng-container *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0">
													ab {{ (dish.inhousePrice + dish.extraData.minInhousePrice) | price:getPriceFormat()}}
												</ng-container>
												<ng-container *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0">
													{{ (dish.inhousePrice) | price:getPriceFormat()}}
												</ng-container>
											</ion-label>
                                            <ion-label
                                                *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                                                [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}">
                                                <ng-container *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0">
													ab {{ (dish.outerhousePrice + dish.extraData.minOuterhousePrice) | price:getPriceFormat()}}
												</ng-container>
												<ng-container *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0">
													{{ (dish.outerhousePrice) | price:getPriceFormat()}}
												</ng-container>
    
                                            </ion-label>
                                        </ion-col>
                                        <ion-col class="ion-text-center ion-align-items-center"
                                            style="vertical-align: middle;">
                                            <ion-icon [id]="'pm'+in" *ngIf="!this.menuService.isUnclickable()" name="cart"
                                                (click)="addToCart(dish, in)"
                                                style="background: none; font-size: larger;font-weight: bolder;"
                                                [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                                                name="add-outline">
                                            </ion-icon>
                                        </ion-col>
    
    
                                        <ion-row  [ngStyle]="{'justify-content': dish.img === undefined || dish.img === '' ? 'center' : ''}" class="switch-desc-row">
                                            <ion-col class=" ion-align-self-center switch-desc-col">
    
    
                                                <ng-container
                                                    *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)">
                                                    <ion-label text-wrap
                                                    [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.descConfig.font, 'color' : this.templateStyleService.$templateConfig.descConfig.color}">
                                                        {{descriptionItem}}<br>
                                                    </ion-label>
                                                </ng-container>
                                            </ion-col>
                                        </ion-row>
    
                                        <ion-badge *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
                                    || this.gastroService.$gastro.hasNoOfferFlag === false)" class="my-badge"
                                            color="danger">Angebot
                                        </ion-badge>
                                    </ion-col>
    
                                </ion-row> -->

          <!--SWITCH DESIGN IN KACHELN-->
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!--HINWEIS CATEGORY-->
  <ion-row [id]="'cathinweise'">
    <ion-col>
      <h1 style="color: black">Hinweise</h1>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-row>
        <h1 class="producttitle">Allergene & Zusatzstoffe</h1>
      </ion-row>
      <ion-row>
        <div
          *ngIf="this.gastroService.$gastro.allergicLink == undefined || this.gastroService.$gastro.allergicLink == ''"
          class="productdescription"
        >
          Für Allergene & Zusatzstoffe bitte das Personal fragen
        </div>
        <div
          (click)="alergicOpen(this.gastroService.$gastro.allergicLink)"
          *ngIf="this.gastroService.$gastro.allergicLink != undefined && this.gastroService.$gastro.allergicLink != ''"
          class="productdescription"
        >
          Für Allergene & Zusatzstoffe hier klicken
        </div>
      </ion-row>
    </ion-col>
  </ion-row>
  <ion-row style="text-align: center">
    <ion-col>
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="/impressum"
        target="_blank"
        >Impressum</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/datenschutzerklaerung-bestellmanagement"
        target="_blank"
        >Datenschutz</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/agb-bestellmanagement"
        target="_blank"
        >AGB's</a
      >
    </ion-col>
  </ion-row>
</ion-content>

<!-- DIGITALE SPEIßeKARTE switch-->

<ion-content
  *ngIf="this.sessionDataService.$comesFromPlatform == false && this.templateStyleService.$templateConfig.layout == 'Switch'"
  [scrollEvents]="true"
  #content
  style="

    --background: none;

    position: relative;
  "
  (ionScroll)="scrolling($event)"
  class="ion-padding"
>
  <app-header (navigateBackEvent)="backToSite()"></app-header>

  <ion-grid style="opacity: 1; padding: 0; position: relative">
    <ion-row
      id="horizontalCategorySelector"
      style="
        margin-left: -20px;
        margin-right: -20px;
        margin-top: -8px;
        position: sticky;
        top: 48px;
        z-index: 20000;
      "
      [ngStyle]="{ 'background' : this.templateStyleService.$templateConfig.categoriesConfig.bgColor}"
    >
      <ion-col class="ion-text-center ion-align-items-end">
        <ion-tab-bar
          id="tbBaseComponent"
          mode="ios"
          [ngClass]="{'center-nav': categories.length>=3}"
          style="
            background: none;
            padding-bottom: 8px;
            padding-top: 8px;
            z-index: 2000000000;
          "
        >
          <ng-container
            *ngIf="(sessionDataService.$inhouseLink && gastroService.$gastro.popDishesInhouseShown) || (!sessionDataService.$inhouseLink && gastroService.$gastro.popDishesOuterhouseShown)"
          >
            <ion-tab-button
              style="background: none; margin: 10px"
              [selected]="this.displayedCategory == 'popDishes'"
              (click)="scrollTo('popDishes')"
              class="ion-tab-switch"
            >
              <ion-label
                [id]="'tbpopDishes'"
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
                class="categories"
                >GERADE BELIEBT</ion-label
              >
            </ion-tab-button>
          </ng-container>
          <ng-container *ngFor="let category of categories; let i = index">
            <ion-tab-button
              [selected]="this.displayedCategory == category.id"
              style="background: none; margin: 10px"
              class="ion-tab-switch"
              (click)="scrollTo(category.id)"
              *ngIf="(this.sessionDataService.$inhouseLink ? category.inhouseVisible : category.outerhouseVisible) && !menuService.isCategoryEmpty(category.id)"
            >
              <ion-label
                [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
                [id]="'tb' + category.id"
                class="categories"
                >{{this.getCategoryTranslation(category).name}}</ion-label
              >
            </ion-tab-button>
          </ng-container>
          <ion-tab-button
            style="background: none; margin: 10px"
            (click)="scrollTo('hinweise')"
          >
            <ion-label
              [id]="'tbhinweise'"
              [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
              class="categories"
              >HINWEISE</ion-label
            >
          </ion-tab-button>
        </ion-tab-bar>
        <app-closed-banner></app-closed-banner>
        <!--DISBALED until we validated opening hours are valid-->
        <!-- <ion-tab-button  class="info-button" (click)="openInfoPopOver()">
					<ion-label
						[ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesConfig.font, 'color' : this.templateStyleService.$templateConfig.categoriesConfig.color}"
						class="categories"><ion-icon style="color: #012c40" size="large" name="information-circle-outline"></ion-icon></ion-label>
				</ion-tab-button> -->
      </ion-col>
    </ion-row>
    <ion-item
      *ngIf="this.gastroService.$gastro.inhouseInactive && sessionDataService.$inhouseLink === true && gastroService.gastroLoaded === true; else banner2"
      color="danger"
      style="
        margin-left: -16px;
        margin-right: -16px;
        text-align: center;
        z-index: 5;
      "
    >
      <ion-label
        class="ion-text-wrap"
        style="hyphens: auto; word-break: break-word"
      >
        Bestellung per QR-Code ist leider gerade nicht verfügbar. Du kannst aber
        gerne beim Service bestellen.
      </ion-label>
    </ion-item>

    <ng-template #banner2>
      <app-banner
        *ngIf="((gastroService.hasRecentHeartbeat | async) === false || (this.gastroService.isInShift === false)) && (this.orderbirdService.active | async) === true && this.menuService.menuUnclickable === false"
      >
        Sorry, Bestellen per QR-Code geht gerade nicht. Bitte frage das Personal
        um Hilfe.
      </app-banner>
    </ng-template>

    <app-template-skeleton layout="Switch"></app-template-skeleton>

    <div style="background: none; opacity: 1; position: relative">
      <div
        [ngStyle]="{'background-image': 'url(' + this.templateStyleService.$templateConfig.backgroundImage + ')','opacity':this.templateStyleService.$templateConfig.backgroundOpacity,'background-size':'100%','position':'absolute','width':'100vw','height':'100%', 'margin-left':'-16px', 'margin-right':'-16px'}"
      ></div>
      <ion-row style="margin-left: -25px; margin-right: -25px">
        <ion-col>
          <ng-container
            *ngIf="(sessionDataService.$inhouseLink && gastroService.$gastro.popDishesInhouseShown) || (!sessionDataService.$inhouseLink && gastroService.$gastro.popDishesOuterhouseShown)"
          >
            <ng-container style="background: none">
              <ion-item-divider
                *ngIf="this.templateStyleService.$templateConfig.categoriesConfig.showCategoriesInSwitch"
                mode="md"
                style="background: none; border-bottom: none; padding: 16px"
              >
                <ion-col class="ion-text-center" style="background: none">
                  <h1
                    [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.headerConfig.font, 'color' : this.templateStyleService.$templateConfig.headerConfig.color}"
                  >
                    GERADE BELIEBT
                  </h1>
                </ion-col>
              </ion-item-divider>
              <ion-row [id]="'catpopDishes'" style="background: none">
              </ion-row>
              <ng-container *ngFor="let dish of this.topDishes; let in = index">
                <ion-row
                  [ngClass]="{'switcherRow' : dish.img !== undefined && dish.img !== '', 'switcher-row-without-image': dish.img === undefined || dish.img === ''}"
                  (click)="addToCart(dish, in)"
                >
                  <ion-col
                    *ngIf="dish.img !== '' && dish.img !== undefined"
                    class="ion-text-center ion-align-self-center"
                    sizeLg="5"
                    sizeMd="5"
                    sizeSm="5"
                    sizeXs="6"
                  >
                    <div
                      (click)="openZoomedImage(dish.img, $event)"
                      class="switcherColImg switch-image"
                      [style]="'background-image: url(' + dish.img +');background-size: contain;
                      background-repeat: no-repeat;width:100%;height:160px;'"
                    ></div>
                  </ion-col>
                  <ion-col
                    [ngStyle]="{'text-align': dish.img === undefined || dish.img === '' ? 'center' : ''}"
                    class="ion-align-self-center switcherColDishName switch-food-col"
                    sizeLg="7"
                    sizeMd="7"
                    sizeSm="7"
                    sizeXs="6"
                    style="margin-top: -8px"
                  >
                    <ion-col class="ion-align-self-center">
                      <ion-label
                        style="color: #3a7995"
                        *ngIf="countDish(dish)>0"
                      >
                        {{countDish(dish)}}x
                      </ion-label>
                      <ion-label
                        [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.dishConfig.font, 'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
                      >
                        {{this.getProductTranslation(dish).name}}
                      </ion-label>
                      <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                      <ion-label
                        *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                        >
                          ab {{ (dish.inhousePrice +
                          dish.extraData.minInhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                        >
                          {{ (dish.inhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </ion-label>
                      <ion-label
                        *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, false)"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                        >
                          ab {{ (dish.outerhousePrice +
                          dish.extraData.minOuterhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                        >
                          {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </ion-label>
                    </ion-col>
                    <ion-col
                      class="ion-text-center ion-align-items-center"
                      style="vertical-align: middle"
                    >
                      <ion-icon
                        [id]="'pm'+in"
                        *ngIf="!this.menuService.isUnclickable()"
                        name="cart"
                        (click)="addToCart(dish, in)"
                        style="
                          background: none;
                          font-size: larger;
                          font-weight: bolder;
                        "
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                        name="add-outline"
                      >
                      </ion-icon>
                    </ion-col>
                    <ion-col
                      *ngIf="dish.allergens!=undefined && dish.allergens.length > 0 && this.gastroService.$gastro.hasAllergens"
                    >
                      <ion-icon
                        style="font-size: 22px"
                        name="information-circle-outline"
                        (click)="openAllergenPopOver(dish, $event)"
                      ></ion-icon>
                    </ion-col>
                    <ion-row
                      [ngStyle]="{'justify-content': dish.img === undefined || dish.img === '' ? 'center' : ''}"
                      class="switch-desc-row"
                    >
                      <ion-col class="ion-align-self-center switch-desc-col">
                        <ng-container
                          *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                        >
                          <ion-label
                            text-wrap
                            [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.descConfig.font}"
                          >
                            {{descriptionItem}}<br />
                          </ion-label>
                        </ng-container>
                      </ion-col>
                    </ion-row>

                    <ion-badge
                      *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
                                || this.gastroService.$gastro.hasNoOfferFlag === false)"
                      class="my-badge"
                      color="danger"
                      >Angebot
                    </ion-badge>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let category of this.categories">
            <ng-container
              style="background: none"
              *ngIf="(this.sessionDataService.$inhouseLink ? category.inhouseVisible : category.outerhouseVisible) && !menuService.isCategoryEmpty(category.id)"
            >
              <ion-row [id]="'cat' + category.id" style="background: none">
                <ion-item-divider
                  *ngIf="this.templateStyleService.$templateConfig.categoriesConfig.showCategoriesInSwitch"
                  mode="md"
                  style="background: none; border-bottom: none; padding: 16px"
                >
                  <ion-col class="ion-text-center" style="background: none">
                    <h1
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.headerConfig.font, 'color' : this.templateStyleService.$templateConfig.headerConfig.color}"
                    >
                      {{ category.name }}
                    </h1>
                    <span
                      [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.categoriesDescConfig.font, 'color':this.templateStyleService.$templateConfig.categoriesDescConfig.color}"
                      >{{ this.getCategoryTranslation(category).description
                      }}</span
                    >
                  </ion-col>
                </ion-item-divider>
              </ion-row>
              <ng-container *ngFor="let dish of this.dishes; let in = index">
                <ion-row
                  [ngClass]="{'switcherRow' : dish.img !== undefined && dish.img !== '', 'switcher-row-without-image': dish.img === undefined || dish.img === ''}"
                  *ngIf="(dish.categoryId == category.id) && offerAvailable(dish)
                    && (this.sessionDataService.$inhouseLink ? dish.inhouseVisible : dish.outerhouseVisible)"
                  (click)="addToCart(dish, in)"
                >
                  <ion-col
                    *ngIf="dish.img !== '' && dish.img !== undefined"
                    class="ion-text-center ion-align-self-center"
                    sizeLg="5"
                    sizeMd="5"
                    sizeSm="5"
                    sizeXs="6"
                  >
                    <div
                      (click)="openZoomedImage(dish.img, $event)"
                      class="switcherColImg switch-image"
                      [style]="'background-image: url(' + dish.img +');background-size: contain;
                      background-repeat: no-repeat;width:100%;height:160px;'"
                    ></div>
                  </ion-col>
                  <ion-col
                    [ngStyle]="{'text-align': dish.img === undefined || dish.img === '' ? 'center' : ''}"
                    class="ion-align-self-center switcherColDishName switch-food-col"
                    sizeLg="7"
                    sizeMd="7"
                    sizeSm="7"
                    sizeXs="6"
                    style="margin-top: -8px"
                  >
                    <ion-col class="ion-align-self-center">
                      <ion-label
                        style="color: #3a7995"
                        *ngIf="countDish(dish)>0"
                      >
                        {{countDish(dish)}}x
                      </ion-label>
                      <ion-label
                        [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.dishConfig.font, 'color' : this.templateStyleService.$templateConfig.dishConfig.color,
											'display': dish.img === undefined || dish.img === '' ? 'inline-block' : ''}"
                      >
                        {{this.getProductTranslation(dish).name}}
                      </ion-label>
                      <app-tag-list [tagIds]="dish.tags"></app-tag-list>
                      <ion-label
                        *ngIf="this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minInhousePrice > 0"
                        >
                          ab {{ (dish.inhousePrice +
                          dish.extraData.minInhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minInhousePrice === 0"
                        >
                          {{ (dish.inhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </ion-label>
                      <ion-label
                        *ngIf="!this.sessionDataService.$inhouseLink && displayPrice(dish, true)"
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                      >
                        <ng-container
                          *ngIf="dish.extraData !== undefined && dish.extraData.minOuterhousePrice > 0"
                        >
                          ab {{ (dish.outerhousePrice +
                          dish.extraData.minOuterhousePrice) |
                          price:getPriceFormat()}}
                        </ng-container>
                        <ng-container
                          *ngIf="dish.extraData === undefined || dish.extraData.minOuterhousePrice === 0"
                        >
                          {{ (dish.outerhousePrice) | price:getPriceFormat()}}
                        </ng-container>
                      </ion-label>
                    </ion-col>
                    <ion-col
                      class="ion-text-center ion-align-items-center"
                      style="vertical-align: middle"
                    >
                      <ion-icon
                        [id]="'pm'+in"
                        *ngIf="!this.menuService.isUnclickable()"
                        name="cart"
                        style="
                          background: none;
                          font-size: larger;
                          font-weight: bolder;
                        "
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                        name="add-outline"
                      >
                      </ion-icon>
                      <ion-icon
                        [id]="'pm'+in"
                        *ngIf="dish.opt === true && menuService.isUnclickable() === true"
                        name="cart"
                        style="
                          background: none;
                          font-size: larger;
                          font-weight: bolder;
                        "
                        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.buttonColor}"
                        name="chevron-forward-outline"
                      >
                      </ion-icon>
                    </ion-col>
                    <ion-col
                      *ngIf="dish.allergens!=undefined && dish.allergens.length > 0 && this.gastroService.$gastro.hasAllergens"
                    >
                      <ion-icon
                        style="font-size: 22px"
                        name="information-circle-outline"
                        (click)="openAllergenPopOver(dish, $event)"
                      ></ion-icon>
                    </ion-col>

                    <ion-row
                      [ngStyle]="{'justify-content': dish.img === undefined || dish.img === '' ? 'center' : ''}"
                      class="switch-desc-row"
                    >
                      <ion-col class="ion-align-self-center switch-desc-col">
                        <ng-container
                          *ngFor="let descriptionItem of splitDescritption(this.getProductTranslation(dish).description)"
                        >
                          <ion-label
                            text-wrap
                            [ngStyle]="{ 'font-family' : this.templateStyleService.$templateConfig.descConfig.font, 'color' : this.templateStyleService.$templateConfig.descConfig.color}"
                          >
                            {{descriptionItem}}<br />
                          </ion-label>
                        </ng-container>
                      </ion-col>
                    </ion-row>

                    <ion-badge
                      *ngIf="dish.offer && (this.gastroService.$gastro.hasNoOfferFlag === undefined 
                                || this.gastroService.$gastro.hasNoOfferFlag === false)"
                      class="my-badge"
                      color="danger"
                      >Angebot
                    </ion-badge>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ng-container>
          </ng-container>

          <!--HINWEIS CATEGORY-->
          <ion-row [id]="'cathinweise'">
            <ion-col>
              <h1 style="color: black">Hinweise</h1>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-row>
                <h1 class="producttitle">Allergene & Zusatzstoffe</h1>
              </ion-row>
              <ion-row>
                <div
                  *ngIf="this.gastroService.$gastro.allergicLink == undefined || this.gastroService.$gastro.allergicLink == ''"
                  class="productdescription"
                >
                  Für Allergene & Zusatzstoffe bitte das Personal fragen
                </div>
                <div
                  (click)="alergicOpen(this.gastroService.$gastro.allergicLink)"
                  *ngIf="this.gastroService.$gastro.allergicLink != undefined && this.gastroService.$gastro.allergicLink != ''"
                  class="productdescription"
                >
                  Für Allergene & Zusatzstoffe hier klicken
                </div>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
  <ion-row>
    <ion-col style="text-align: center">
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="/impressum"
        target="_blank"
        >Impressum</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/datenschutzerklaerung-bestellmanagement"
        target="_blank"
        >Datenschutz</a
      >
      <a
        class="ftext"
        [ngStyle]="{'color' : this.templateStyleService.$templateConfig.dishConfig.color}"
        id="footer-impressum"
        href="https://www.orderbird.com/de/agb-bestellmanagement"
        target="_blank"
        >AGB's</a
      >
    </ion-col>
  </ion-row>
</ion-content>

<div
  class="floatingButtonContainer"
  *ngIf="(cartService.$cart.length || tableContentService.getTotal() > 0) && this.gastroService.$gastro.hasPayAtTable == true "
>
  <ion-button
    class="floatingButton toCart"
    *ngIf="cartService.$cart.length > 0"
    (click)="openCart()"
  >
    <ion-badge class="text"> Bestellung prüfen & senden </ion-badge>
    <div class="pricing">
      <ion-badge class="pricingText"
        >{{ cartService.productsCount }} Artikel</ion-badge
      >
      <ion-badge class="pricingPrice"
        >{{ orderService.cartTotalWithoutDiscounts |
        currency:'EUR':'symbol-narrow':'1.2-2':'de' }}</ion-badge
      >
    </div>
  </ion-button>
  <ion-button
    class="floatingButton toPat"
    *ngIf="cartService.$cart.length == 0 && tableContentService.getTotalCount() > 0"
    (click)="goToSelfCheckoutTableContent()"
  >
    <ion-badge class="text"> Bestellung ansehen & bezahlen </ion-badge>
    <div class="pricing">
      <ion-badge class="pricingText"
        >{{ tableContentService.getTotalCount() }} Artikel</ion-badge
      >
      <ion-badge class="pricingPrice"
        >{{ tableContentService.getTotal() |
        currency:'EUR':'symbol-narrow':'1.2-2':'de' }}</ion-badge
      >
    </div>
  </ion-button>
</div>
<ion-footer
  *ngIf="cartService.$cart.length && (this.gastroService.$gastro.hasPayAtTable == false || this.gastroService.$gastro.hasPayAtTable == undefined)"
>
  <ion-button
    (click)="openCart()"
    expand="full"
    style="height: 56px; margin: 0"
  >
    <ion-badge *ngIf="cartService.$cart.length > 0">{{ length() }}</ion-badge>
    <ion-icon
      style="
        filter: invert(100%) sepia(0%) saturate(1352%) hue-rotate(87deg)
          brightness(119%) contrast(119%);
        margin-right: 8px;
      "
      src="../../assets/icons/cart-outline.svg"
    ></ion-icon>
    <ion-badge> Zum Einkaufswagen </ion-badge>
    <ion-badge
      id="badgeCart"
      style="margin-left: 8px"
      *ngIf="orderService.cartTotal >= 0"
      >{{ orderService.cartTotal | currency:'EUR':'symbol-narrow':'1.2-2':'de'
      }}</ion-badge
    >
  </ion-button>
</ion-footer>
