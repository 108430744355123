import { HttpClientModule } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import {
  AngularFirestoreModule,
  SETTINGS as SETTINGS_FIRESTORE,
} from '@angular/fire/compat/firestore'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router, RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import {
  AlertController,
  Config,
  IonicModule,
  IonicRouteStrategy,
  LoadingController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage'
import * as Sentry from '@sentry/angular'
import { Integrations } from '@sentry/tracing'
import { normalize } from '@sentry/utils'
import {
  ConnectionServiceModule,
  ConnectionServiceOptions,
  ConnectionServiceOptionsToken,
} from 'ngx-connection-service'
import { environment } from 'src/environments/environment'
import '@angular/compiler'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { DishDetailPageModule } from './dish-detail/dish-detail.module'
import { GastroActiveGuard } from './guards/gastro-active.guard'
import { InGastroGuard } from './guards/in-gastro.guard'
import { OpenGuard } from './guards/open.guard'
import { NotificationsService } from './notifications.service'
import { StartOfInteractionPageModule } from './PayAtTable/start-of-interaction/start-of-interaction.module'
import { PaymentMethodModalPageModule } from './PayAtTable/table-content/modals/payment-method-modal/payment-method-modal.module'
import { TableContentPageModule } from './PayAtTable/table-content/table-content.module'
import { PipesModule } from './pipes/pipes.module'
import { ProfileEntryModalPageModule } from './profile-entry-modal/profile-entry-modal.module'
import { RestaurantPagePage } from './restaurant-page/restaurant-page.page'
import { DeviceService } from './services/device.service'
import { GastroService } from './services/gastro.service'
import { TableService } from './services/table.service'
import { UpdatesService } from './update.service'
import { UserprofileareaModalPageModule } from './userprofilearea-modal/userprofilearea-modal.module'

Sentry.init({
  beforeSend(event) {
    const eventValues = event.exception.values[0]
    //Filtering out all events that are not supposed to be shown in Sentry, by returning null
    if (eventValues.type == 'ChunkLoadError') {
      return null
    } else if (
      eventValues.type == 'Error' &&
      eventValues.value.includes('ChunkLoadError')
    ) {
      return null
    } else if (
      eventValues.type == 'Error' &&
      eventValues.value.includes('client is offline')
    ) {
      return null
    } else if (
      eventValues.type == 'FirebaseError' &&
      eventValues.value.includes('client is offline')
    ) {
      return null
    } else if (
      eventValues.type == 'TypeError' &&
      eventValues.value.includes('Failed to fetch')
    ) {
      return null
    }
    return normalize(event)
  },
  denyUrls: [/(.*(localhost).*)/gm],
  dsn: 'https://092b529c7fc1484da1687f84232e19d7@o264471.ingest.sentry.io/1466760',
  environment: environment.production ? 'production' : 'developement',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
})

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    IonicStorageModule.forRoot({ driverOrder: ['indexeddb', 'localstorage'] }),
    DishDetailPageModule,
    ProfileEntryModalPageModule,
    TableContentPageModule,
    StartOfInteractionPageModule,
    UserprofileareaModalPageModule,
    AngularFireAnalyticsModule,
    HttpClientModule,
    ConnectionServiceModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    FormsModule,
    PipesModule,
    PaymentMethodModalPageModule,
  ],
  providers: [
    AngularFireAuth,
    ToastController,
    DeviceService,
    GastroService,
    TableService,
    LoadingController,
    AlertController,
    {
      provide: ConnectionServiceOptionsToken,
      useValue: <ConnectionServiceOptions>{
        enableHeartbeat: false,
      },
    },
    {
      provide: SETTINGS_FIRESTORE,
      useValue: {
        experimentalForceLongPolling: true, //remove and test once we upgrade firebase to don't use compat anymore
        useFetchStreams: false, //remove and test once we upgrade firebase to don't use compat anymore
      },
    },
    Config,
    InGastroGuard,
    OpenGuard,
    GastroActiveGuard,
    NavController,
    Platform,
    UpdatesService,
    NotificationsService,
    AppComponent,
    RestaurantPagePage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
  ],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
