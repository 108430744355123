<ng-container *ngFor="let item of visibleItems">
  <ng-container
    *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
  ></ng-container>
</ng-container>

<ion-text *ngIf="showToggle && hiddenItems.length > 0">
  <button class="showMoreButton" (click)="toggleItems()">
    {{
      showAllItems
        ? 'Weniger anzeigen'
        : hiddenItems.length + ' Weitere anzeigen'
    }}
  </button>
</ion-text>
