import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { ProductsInCartDeletedAlertedComponent } from 'src/app/modals/products-in-cart-deleted-alerted/products-in-cart-deleted-alerted.component'
import { ArticleSkeletonComponent } from 'src/app/PayAtTable/table-content/components/article-skeleton/article.skeleton.component'
import { ArticleComponent } from './article/article.component'
import { FooterAgbComponent } from './footer-agb/footer-agb.component'
import { ModalObImgTextComponent } from './modal-ob-img-text/modal-ob-img-text.component'
import { ShowMoreComponent } from './show-more/show-more.component'
import { SimpleImgModalComponent } from './simple-img-modal/simple-img-modal.component'
import { TagListComponent } from './tag-list/tag-list.component'

@NgModule({
  declarations: [
    ModalObImgTextComponent,
    FooterAgbComponent,
    SimpleImgModalComponent,
    TagListComponent,
    ProductsInCartDeletedAlertedComponent,
    ShowMoreComponent,
    ArticleComponent,
    ArticleSkeletonComponent,
  ],
  exports: [
    SimpleImgModalComponent,
    FooterAgbComponent,
    TagListComponent,
    ProductsInCartDeletedAlertedComponent,
    ShowMoreComponent,
    ArticleComponent,
    ArticleSkeletonComponent,
  ],
  imports: [CommonModule, IonicModule],
})
export class ComponentsModule {}
