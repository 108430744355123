import { animate, style, transition, trigger } from '@angular/animations'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'

export interface articleObject {
  extras: any[]
  extrasString: string
  name: string
  maxNumber?: number
  counter: number
  price: number
}

@Component({
  animations: [
    trigger('horizontalGrowEffect', [
      transition(':enter', [
        style({ height: 'fit-content', width: '37px' }), // Start small width
        animate('300ms ease-out', style({ width: '115px' })), // Expand to full width
      ]),
      transition(':leave', [
        style({ width: '115px' }), // Start from full width
        animate('300ms ease', style({ height: 'fit-content', width: '37px' })), // Shrink to small width
      ]),
    ]),
  ],
  selector: 'app-article',
  styleUrls: ['./article.component.scss'],

  templateUrl: './article.component.html',
})
export class ArticleComponent implements OnInit {
  @Output() addArticle = new EventEmitter<void>()
  @Output() removeArticle = new EventEmitter<void>()
  @Input() article: articleObject
  @Input() isInteractive = true
  @Input() isStatus = false
  @Input() textClick = false
  showCollapsed = true
  showStepCounter = false
  total = '0,00'
  partialCounter: number

  /**
   * Sets partialCounter to 0 when maxNumber is defined, this scenario
   * applys when using the article component for partial pay.
   */
  ngOnInit() {
    this.partialCounter =
      this.article.maxNumber !== undefined ? 0 : this.article.counter
    this.calcTotal()
    this.addExtrasStringToArticle(this.article)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isInteractive) {
      if (this.isInteractive === true && this.article.maxNumber) {
        this.partialCounter = 0
      }
      if (this.isInteractive === false) {
        this.partialCounter = this.article.counter
        this.calcTotal()
      }
    }
    if (changes.article && !this.article.maxNumber) {
      this.partialCounter = this.article.counter
      this.calcTotal()
    }
  }

  /**
   * Adds an item to the order and updates the counter if certain conditions
   * are met.
   * @param {Object} item - The item to be added to the order.
   * @description
   * This function increments the `partialCounter` of an item by 1 if the
   * current `partialCounter` is less than the `counter`. It then emits the
   * the passed function to the parent
   */
  addItemToOrder() {
    if (
      this.article.maxNumber === undefined ||
      this.partialCounter < this.article.maxNumber
    ) {
      this.addArticle.emit()
      this.partialCounter++
      this.calcTotal()
    }
  }

  /**
   * Removes an item from the order and updates the counter if certain
   * conditions are met.
   * @param {Object} item - The item to be removed from the order.
   * @description
   * This function decrements the `partialCounter` of an item by 1 if the
   * current `partialCounter` is greater than 0. It then emits the
   * the passed function to the parent.
   */
  removeItemFromOrder() {
    if (this.partialCounter > 0) {
      this.partialCounter--
      this.calcTotal()
      this.removeArticle.emit()
    }
  }
  /**
   * Toggles the display state of the step counter.
   *
   * @description
   * This function switches the boolean state of `showStepCounter`
   * between true and false.
   * It is used to show or hide the step counter in the UI.
   *
   * @example
   * toggleStepCounter();
   * // If showStepCounter was true, it becomes false. If it was false,
   * it becomes true.
   */
  toggleStepCounter() {
    if (this.showStepCounter) {
      this.showCollapsed = false
    }
    this.showStepCounter = !this.showStepCounter
  }
  /**
   * To handle properly the horizontalGrowEffect animation
   */
  onAnimationDone() {
    if (!this.showStepCounter) {
      this.showCollapsed = true
    }
  }

  /**
   * Calculates the current total
   */
  calcTotal() {
    let total = 0
    total = this.article.price * this.partialCounter
    this.total = total.toFixed(2)
  }

  /**
   * iterates over the extras array to create a suitable string for the extras
   * @param orders
   */

  addExtrasStringToArticle(article) {
    let extrasString = ''
    if (article.extras) {
      for (const extra of article.extras) {
        // a switch case is used
        // because there are more than 2 kinds of 'kind'
        // but right now only 2 of those are in use
        // at least thats what it looks like in
        // test environment.
        switch (extra.kind) {
          case 0:
            if (extrasString === '') {
              extrasString = extra.select
            } else {
              extrasString = extrasString + ' • ' + extra.select
            }
            break
          case 1:
            for (const subExtra of extra.extra) {
              if (subExtra.selected) {
                if (extrasString === '') {
                  extrasString = subExtra.name
                } else {
                  extrasString = extrasString + ' • ' + subExtra.name
                }
              }
            }

            break
          case 2:
            for (const subExtra of extra.extra) {
              if (subExtra.count > 0) {
                if (extrasString === '') {
                  extrasString = subExtra.count + ' * ' + subExtra.name
                } else {
                  extrasString =
                    extrasString +
                    ' • ' +
                    subExtra.count +
                    ' * ' +
                    subExtra.name
                }
              }
            }
            break
          default:
            break
        }
      }
    }
    article.extrasString = extrasString
  }
}
