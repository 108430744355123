import { HttpClient } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { AngularFireAnalytics } from '@angular/fire/compat/analytics'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import {
  AlertController,
  LoadingController,
  NavController,
  ToastController,
} from '@ionic/angular'
import { Storage } from '@ionic/storage'
import firebase from 'firebase/compat/app'
import * as $ from 'jquery'
import { catchError, firstValueFrom, lastValueFrom, Observable, of } from 'rxjs'
import { ObErrorHelper } from 'src/app/components/obError/obErrorHelper'
import { environment } from 'src/environments/environment'
import { getDateString } from 'src/shared/split-submodules/functions/functions'
import { PaymentState } from 'src/shared/split-submodules/types/enums'
import {
  DishOrder,
  Order,
  WhereOption,
} from 'src/shared/split-submodules/types/types'
import Swal from 'sweetalert2'
import { AlertService } from './alert.service'
import {
  CheckoutSystem,
  CheckoutSystemApiService,
} from './api/checkout-system-api.service'
import { DeliverectService } from './api/deliverect/deliverect.service'
import { OrderbirdService } from './api/orderbird/orderbird.service'
import { CartService } from './cart.service'
import { CouponService } from './coupon.service'
import { DeviceService } from './device.service'
import { GastroService } from './gastro.service'
import { GeoService } from './geo.service'
import { GlobalService } from './global.service'
import { HistoryService } from './history.service'
import { LoyaltyService } from './loyalty.service'
import { Discount, PaymentService } from './payment.service'
import { ReportService } from './report.service'
import { GastroResettable, ResetService } from './reset.service'
import { SentryService } from './sentry.service'
import { SessionService } from './session.service'
import { SessionDataService } from './session-data.service'
import { StorageService } from './storage.service'
import { TableService } from './table.service'
import { UserService } from './user.service'
import { Order as UtilServiceOrder, UtilService } from './util.service'

@Injectable({
  providedIn: 'root',
})
export class OrderService implements GastroResettable {
  //CLEANUP:
  public deliveryMaxTimeItems

  public selectedWhereOption?: WhereOption

  public whereEatName = 'Bitte auswählen'

  //CLEANUP: GastroService/CartPage
  public pickupMinTime = ''

  //CLEANUP: GastroService/CartPage
  public pickupMaxTime = ''

  //CLEANUP: GastroService/CartPage
  public pickupMaxTimeItems

  public cartTotal
  public cartTotalWithoutDiscounts

  private tips = 0
  private tipsPercentage = 0
  private tipsFix = 0
  private tipsSelectedPercentage = 0

  private lastCartId

  public get $tips(): number {
    return this.tips
  }

  /**
   * Setter for tips
   * also rounds the value to 2 decimal places
   */
  public set $tips(newValue: number) {
    newValue = Math.round(newValue * 100) / 100
    this.tips = newValue
  }

  public get $tipsSelectedPercentage(): number {
    return this.tipsSelectedPercentage
  }

  public set $tipsSelectedPercentage(newValue: number) {
    this.tipsSelectedPercentage = newValue
  }

  public get $tipsPercentage(): number {
    return this.tipsPercentage
  }

  public set $tipsPercentage(newValue: number) {
    this.tipsPercentage = newValue
  }

  public get $tipsFix(): number {
    return this.tipsFix
  }

  public set $tipsFix(newValue: number) {
    this.tipsFix = newValue
  }

  constructor(
    private afs: AngularFirestore,
    private alertService: AlertService,
    public checkoutSystemApiService: CheckoutSystemApiService,
    private couponService: CouponService,
    private deviceService: DeviceService,
    private cartService: CartService,
    private alertController: AlertController,
    private http: HttpClient,
    public gastroService: GastroService,
    private geoService: GeoService,
    private historyService: HistoryService,
    private loadingCtrl: LoadingController,
    private loyaltyService: LoyaltyService,
    private navCtrl: NavController,
    private paymentService: PaymentService,
    private reportService: ReportService,
    private resetService: ResetService,
    private sentryService: SentryService,
    private sessionDataService: SessionDataService,
    private sessionService: SessionService,
    private storage: Storage,
    private storageService: StorageService,
    private tableService: TableService,
    public userService: UserService,
    public utilService: UtilService,
    private deliverectService: DeliverectService,
    private toastController: ToastController,
    private obApiErrorHelper: ObErrorHelper,
    private injector: Injector,
    private analytics: AngularFireAnalytics,
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    this.registerGastroReset()
  }

  onGastroLogout(): void {
    this.deliveryMaxTimeItems = undefined
    this.whereEatName = 'Bitte auswählen'
    this.selectedWhereOption = undefined
    this.pickupMinTime = ''
    this.pickupMaxTime = ''
    this.pickupMaxTimeItems = undefined
    this.$tips = 0
  }

  registerGastroReset(): void {
    this.resetService.registerGastroReset(this)
  }

  //CLEANUP: OrderService
  private deliveryOptions
  public get $deliveryOptions() {
    return this.deliveryOptions
  }
  public set $deliveryOptions(value) {
    this.deliveryOptions = value
  }

  //CLEANUP: SessionDataService/UserService
  private hasSeenNewOrders = true
  public get $hasSeenNewOrders() {
    return this.hasSeenNewOrders
  }
  public set $hasSeenNewOrders(value: boolean) {
    this.hasSeenNewOrders = value
    this.storage.set('hasSeenNewOrders', value)
  }

  private async alertProblemsWithPayment() {
    const alert = await this.alertController.create({
      buttons: [
        {
          cssClass: 'primary',
          role: 'ok',
          text: 'OK!',
        },
      ],
      header: 'Problem',
      message: 'Es gab ein Problem bei deinen Zahlunsinfos',
    })
    await alert.present()
  }

  private async sendOrderToApi(payedLastOrder: boolean) {
    let orderbirdData
    const api = await this.checkoutSystemApiService.getActiveApi()
    if (api !== undefined) {
      const amount = this.cartService.getSumOfPrices()
      const payment = this.createPaymentObjectForApi(api)

      const order: UtilServiceOrder = {
        gastroId: this.gastroService.$gastroId,
        isPayed: this.paymentService.$payedLastOrder,
        items: this.cartService.$cart,
        nickname: this.sessionDataService.$nickname,
        payment: payment,
        pickup: this.sessionDataService.$isPickUp,
        table: this.tableService.getTable(),
        tip: this.$tips,
        user: this.sessionService.sessionUser,
      }
      try {
        const [, data] = await api.sendOrder(order)
        if (api instanceof OrderbirdService) {
          orderbirdData = data
        }
      } catch (e) {
        if (api instanceof OrderbirdService && e.responseJSON !== undefined) {
          this.obApiErrorHelper.handleError(
            e.responseJSON.clientFacingMessage,
            e.responseJSON.detailedMessage,
            this.gastroService.$gastroId,
            'sendOrder()'
          )
        } else {
          this.sentryService.captureError('API ERROR', '')
          Swal.close()
          const alert = await this.alertController.create({
            buttons: [
              {
                cssClass: 'primary',
                role: 'ok',
                text: 'OK!',
              },
            ],
            header: 'Problem',
            message: `Es gab ein Problem beim Übermitteln Deiner Bestellung.
									  Bitte wende Dich an das Personal.`,
          })
          await alert.present()
        }
        return [-1, undefined]
      }
      if (payedLastOrder === true) {
        api.sendPayment(amount)
      }
    }
    return [1, orderbirdData]
  }

  createPaymentObjectForApi(api: CheckoutSystem) {
    const paymentOption = this.paymentService.$paymentOption
    let paymentObj: {
      addData?: unknown
      name: string
      option: string
      payed: 1 | 0
    } = {
      name: this.paymentService.$paymentName,
      option: this.paymentService.$paymentName,
      payed: this.paymentService.$payedLastOrder ? 1 : 0,
    }

    const actPayemtOpt = paymentOption.option
    if (
      (actPayemtOpt === 'credit' || actPayemtOpt === 'platform') &&
      api.hasMobilePayment &&
      api.getMobilePaymentSplitObject().name !== ''
    ) {
      paymentObj = {
        name: api.getMobilePaymentSplitObject().name,
        option: api.getMobilePaymentSplitObject().name,
        payed: this.paymentService.$payedLastOrder ? 1 : 0,
      }
    }

    if (paymentOption.addData !== undefined) {
      paymentObj.addData = paymentOption.addData
    }
    return paymentObj
  }

  // eslint-disable-next-line complexity
  async checkout() {
    try {
      const orderArray = []
      const orderDocArray = []
      if (!this.paymentService.mobilePaySecure()) {
        this.alertProblemsWithPayment()
        return
      }

      const [orderAPIStatus, orderbirdData] = await this.sendOrderToApi(
        this.paymentService.$payedLastOrder
      ) //async

      if (orderAPIStatus === -1) {
        this.utilService.loadingWindowDismiss()
        return
      }

      this.deviceService.$logInTime = new Date()

      const orderNumber = await firstValueFrom(this.generateOrderNumber())

      const userName = await this.userService.getName(
        this.gastroService.$gastroId,
        this.sessionDataService.$isDelivery
      )

      if (
        !this.gastroService.$gastro.hasPickup ||
        !this.sessionDataService.$isPickUp
      ) {
        this.sessionDataService.$pickUpTime = ''
      }

      const cartID = this.utilService.makeid(10)
      this.lastCartId = cartID

      //create orderarray
      for (let i = 0, len = this.cartService.$cart.length; i < len; i++) {
        const cartItem = this.cartService.$cart[i]
        if (
          this.globalService.globals.tracking?.sessionDuration ||
          this.gastroService.$gastro.hasAnalytics
        ) {
          try {
            for (let j = 0; j < cartItem.count; j++) {
              this.analytics.logEvent('orderedItem', {
                gastro: this.gastroService.$gastroId,
                item: cartItem.name,
              })
            }
          } catch (logError) {
            console.error('Error logging analytics event:', logError)
          }
        }

        const order = await this.createOrder(
          cartItem,
          i === 0,
          userName,
          cartID,
          orderNumber
        )
        if (orderbirdData !== undefined && orderbirdData[i] !== undefined) {
          order.additionalOrderbirdData = orderbirdData[i]
        }
        orderArray.push(order)
      }

      //send order to gastro
      for (let i = 0, len = orderArray.length; i < len; i++) {
        const order = orderArray[i]
        if (this.deliverectService.deliverectChannelOrderId) {
          order.deliverectChannelOrderId =
            this.deliverectService.deliverectChannelOrderId
          order.code = this.deliverectService.deliverectChannelOrderDisplayCode
        }
        const orderDoc = await this.afs
          .collection('gastro')
          .doc(this.gastroService.$gastroId)
          .collection('order')
          .add(order)
        orderDocArray.push(orderDoc)
      }
      //do profile stuff
      try {
        for (let i = 0, len = orderArray.length; i < len; i++) {
          const order = orderArray[i]
          const orderDoc = orderDocArray[i]
          const cartItem = this.cartService.$cart[i]
          if (
            this.paymentService.invoice ||
            this.userService.profile.isLoggedIn
          ) {
            await this.reportService.updateReportUserProfile(
              {
                cartID: order.cartID,
                delivery: this.sessionDataService.$isDelivery,
                gastroID: this.gastroService.$gastroId,
                pickup: this.sessionDataService.$isPickUp,
                totalCost: cartItem.price * cartItem.count,
              },
              this.userService.getUserId
            )

            await this.userService.saveAsCustomer(
              order,
              this.gastroService.$gastroId
            )
            await this.afs
              .collection('customer-profiles')
              .doc(this.userService.getUserId)
              .collection('orders')
              .doc(orderDoc.id)
              .set(order)

            this.userService.updateOrderHistory()
            if (
              this.gastroService.$gastro.loyaltyCard &&
              this.gastroService.$gastro.loyaltyCard.active &&
              this.userService.profile.settings.loyaltyActive
            ) {
              this.loyaltyService.updateLoyaltyCard(
                this.gastroService.$gastroId,
                this.gastroService.$gastro.loyaltyCard
              )
            }
          }

          this.historyService.addOrderItem(orderDoc.id)
        }

        //send the bill via email (rechnung per email)
        if (
          this.userService.user.email &&
          !(
            this.paymentService.$paymentId === 'bar' ||
            this.paymentService.$paymentId === 'ec-device'
          )
        ) {
          if (
            this.userService.profile.settings.autoBillAsEmail ||
            !this.userService.profile.isLoggedIn
          ) {
            const cartObjectForBill = { itemDetails: orderArray }
            this.getBillForCart(cartObjectForBill, orderDocArray, 'email')
          }
        }
      } catch (e) {
        console.error(e)
        this.sentryService.captureError(
          'Error while handle order in profile',
          ''
        )
      }

      if (this.paymentService.$paymentOption.option === 'credit') {
        this.utilService.loadingWindowDismiss()
      }

      this.orderAccept(userName, orderNumber)

      try {
        await this.reportService.createKPIData(
          {
            couponList: this.couponService.currentCouponList,
            orderArray: orderArray,
            orderTotalWithDiscount: this.getTotalBeforeDeliveryFee(),
            orderTotalWithoutDiscounts: this.getCartTotal(),
          },
          this.gastroService.$gastroId,
          this.userService.getUserId,
          this.userService.profile.isLoggedIn
        )
      } catch (e) {
        console.error(e)
        this.sentryService.captureError('Error in KPI checkout', '')
      }

      this.resetValuesForNextOrder()

      if (this.utilService.loadingWindow !== undefined) {
        this.utilService.loadingWindowDismiss()
      }

      this.couponService.subtractCouponUsesFromCouponList(1)
      this.couponService.resetCouponList()
    } catch (e) {
      console.error(e)
      this.sentryService.captureError('Error while sending order', '')
    }
  }

  resetValuesForNextOrder() {
    this.$tips = 0
    this.paymentService.invoice = false
    this.cartService.$cart = []
    this.paymentService.$payedLastOrder = false
  }

  private createDish(cartItem: any): DishOrder {
    const dish: DishOrder = {
      baseDishPrice: this.sessionDataService.$inhouseLink
        ? cartItem.inhousePrice
        : cartItem.outerhousePrice,
      categorie: cartItem.category === undefined ? -1 : cartItem.category,
      checkboxen: cartItem.checkboxen === undefined ? [] : cartItem.checkboxen,
      count: cartItem.count,
      dropdown: cartItem.dropdown === undefined ? [] : cartItem.dropdown,
      extraId: cartItem.extraId === undefined ? '' : cartItem.extraId,
      extras: cartItem.extras === undefined ? [] : cartItem.extras,
      id: cartItem.id === undefined ? 0 : cartItem.id,
      image: cartItem.img === undefined ? '' : cartItem.img,
      kind: cartItem.kind === undefined ? 0 : cartItem.kind,
      name: cartItem.name,
      note: cartItem.note === undefined ? '' : cartItem.note,
      price: cartItem.price,
      prices: cartItem.prices === undefined ? [] : cartItem.prices,
      printer: cartItem.printer === undefined ? -1 : cartItem.printer,
      sorted: cartItem.sorted === undefined ? false : true,
    }

    if (
      this.gastroService.$gastro.hasArticleNr &&
      cartItem.articleNr !== undefined
    ) {
      dish.id = cartItem.articleNr
    }
    return dish
  }

  /**
   * this function creates the order item
   * don't change paramters here -
   * just use it for generation because it is used twice in the preflight check
   * @param cartItem
   * @param isFirstOrder
   * @param userName
   * @param cartID
   * @returns an order item
   */
  // eslint-disable-next-line complexity
  private createOrder(
    cartItem: any,
    isFirstOrder: boolean,
    userName: string,
    cartID: string,
    orderNumber: string
  ): Order {
    const serverTime = firebase.firestore.FieldValue.serverTimestamp()
    const gastro = this.gastroService.$gastro
    let splitCashFee = 0.0
    let paypalFee = 0

    //TODO: Should stripeFee influence splitCashFee &paypalFee
    if (gastro.stripeFee !== undefined) {
      if (isFirstOrder) {
        if (
          this.paymentService.$paymentId === 'bar' ||
          this.paymentService.$paymentId === 'ec-device'
        ) {
          splitCashFee = this.calcSplitCashFeeWithoutCeil()
        }
        if (this.paymentService.$paymentId === 'paypal') {
          paypalFee = this.calcSplitCashFeeWithoutCeil()
        }
      }
    }

    if (
      !this.userService.profile.email ||
      !this.gastroService.$gastro.hasCustomerLoyality
    ) {
      this.userService.profile.email = ''
    }

    let deliveryInformations = {}

    if (gastro.hasDelivery && this.sessionDataService.$isDelivery) {
      deliveryInformations = this.sessionDataService.$deliveryInformations
    } else if (
      (this.sessionDataService.$isToGo &&
        (this.paymentService.$paymentId === 'bar' ||
          this.paymentService.$paymentId === 'ec-device')) ||
      gastro.mobileNumberRequired === true
    ) {
      deliveryInformations = this.sessionDataService.$deliveryInformations
    } else if (
      gastro.sms &&
      gastro.selfService &&
      this.sessionDataService.$inhouseLink
    ) {
      deliveryInformations = this.sessionDataService.$deliveryInformations
    }

    if (deliveryInformations === undefined) {
      deliveryInformations = {}
    }
    const retValue: Order = {
      cartID: cartID,
      cartSize: this.cartService.$cart.length
        ? this.cartService.$cart.length
        : 0,
      code: '',
      createdAt: serverTime,
      customer: {
        email: this.userService.user.email,
      },
      delivery: gastro.hasDelivery && this.sessionDataService.$isDelivery,
      deliveryFee: isFirstOrder
        ? this.geoService.getDeliveryFee(this.getTotalBeforeDeliveryFee())
        : 0,
      deliveryInformations: deliveryInformations,
      deviceID: this.deviceService.getDeviceID(),
      dibsPayment:
        this.paymentService.$paymentId === 'credit' &&
        this.gastroService.$gastro.hasDibsPayment === true,
      discount: this.calcDiscount(cartItem.count * cartItem.price),
      dish: this.createDish(cartItem),
      fee: this.gastroService.getGastroFee(),
      finishedAt: 0,
      gastroID: this.gastroService.$gastroId,
      inhousePickupTable: this.sessionDataService.inhousePickupTable
        ? this.sessionDataService.inhousePickupTable
        : false,
      invoice: this.paymentService.invoice,
      marketplaceOrder:
        this.sessionDataService.$comesFromPlatform !== undefined &&
        this.sessionDataService.$comesFromPlatform !== null
          ? this.sessionDataService.$comesFromPlatform
          : false,
      mobilePayed: this.paymentService.$payedLastOrder,
      nickname: userName,
      number: this.userService.profile.mobileNr,
      orderNumber: orderNumber,
      payed: this.paymentService.invoice ? 0 : 1,
      payedAt: this.paymentService.invoice ? null : serverTime,
      paymentID: this.paymentService.$paymentId,
      paymentMethod: this.paymentService.$paymentOption.name,
      paymentState: PaymentState.ACCEPTED,
      paypalFee: paypalFee,
      pickup: gastro.hasPickup && this.sessionDataService.$isPickUp,
      pickupDate: this.sessionDataService.$pickupDate,
      pickupTime: this.sessionDataService.$pickUpTime,
      printed: 0,
      smsState: 0,
      splitCashFee: splitCashFee,
      state: 0,
      stripeFee:
        this.paymentService.$paymentId === 'paypal' || !isFirstOrder
          ? 0
          : this.paymentService.calcStripeFee(this.getTotal()),
      table: this.tableService.getTable().tischNR,
      tableName: this.tableService.getTable().addData?.name
        ? this.tableService.getTable().addData.name
        : this.tableService.getTable().tischNR,
      tableNr: this.tableService.getTable().tischNR,
      tip: isFirstOrder ? this.$tips : 0,
      togo: this.sessionDataService.$isToGo,
      vytalQR:
        this.gastroService.$gastro.hasVytal &&
        this.sessionDataService.inhouseLink === false &&
        this.sessionDataService.$vytalQR !== undefined &&
        this.sessionDataService.$vytalQR !== null
          ? this.sessionDataService.$vytalQR
          : '',
      whereName: this.whereEatName,
      withCoupon: this.couponService.isCouponUsed(),
    }

    if (
      (this.paymentService.$paymentId === 'credit' &&
        this.gastroService.gastro?.hasDibsPayment !== true) ||
      this.paymentService.$paymentId === 'platform'
    ) {
      retValue.stripe = {
        paymentIntentId: this.paymentService.paymentIntent.id,
      }
    }

    return retValue
  }

  //TODO: not correct?
  // getTotal(): number {
  //   const cartTotal = this.getCartTotal();
  //   let total = cartTotal;

  //   // Reihenfolge?
  //   total = this.applyCoupons(total, cartTotal);
  //   total = this.applyDiscounts(total, cartTotal);

  //   total = this.applyFees(total);
  //   total = this.applyTips(total);

  //   return total;
  // }

  getTotal() {
    let total = this.getTotalBeforeDeliveryFee()
    total += this.geoService.getDeliveryFee(total)

    return total
  }

  getTotalBeforeDeliveryFee() {
    let total = this.getCartTotal()
    this.couponService.deleteIfCouponCodeIsNotValidRestriction(total)
    let discount = 0
    for (const item of this.cartService.$cart) {
      discount += this.calcDiscount(item.price * item.count)
    }
    total += discount

    this.couponService.deleteCouponIfPriceMinus(total)

    if (
      this.gastroService.$gastro.hasFee &&
      this.cartService.$cart.length > 0
    ) {
      total += this.gastroService.$gastro.fee
    }

    this.recalcTips(total)

    total += this.$tips

    return total
  }

  recalcTips(total) {
    this.tipsPercentage = total * this.tipsSelectedPercentage
    this.$tips = this.tipsPercentage + this.tipsFix
  }

  getTotalBeforeTipsAndDeliveryFee() {
    let total = this.getCartTotal()

    this.couponService.deleteIfCouponCodeIsNotValidRestriction(total)
    let discount = 0
    for (const item of this.cartService.$cart) {
      discount += this.calcDiscount(item.price * item.count)
    }

    total += discount

    this.couponService.deleteCouponIfPriceMinus(total)

    if (
      this.gastroService.$gastro.hasFee &&
      this.cartService.$cart.length > 0
    ) {
      total += this.gastroService.$gastro.fee
    }

    return total
  }

  public getCartTotal(): number {
    return (
      this.cartService.$cart
        // eslint-disable-next-line no-return-assign
        .reduce((total, item) => {
          return (total += item.count * item.price)
        }, 0)
    )
  }
  /**
   * updates cartTotal Variable using getCartTotal()
   */
  public updateCartTotal() {
    this.cartTotal = this.getTotal()
    this.cartTotalWithoutDiscounts = this.getCartTotal()
  }

  public calcDiscount(itemPrice: number) {
    return this.calcGastroDiscounts(itemPrice) + this.calcCoupons(itemPrice)
  }

  private calcGastroDiscounts(itemPrice: number): number {
    const gastro = this.gastroService.$gastro
    if (gastro.discount === undefined) {
      return 0
    }

    let discountValue = 0
    for (const discount of gastro.discount) {
      if (this.checkDiscounts()) {
        discountValue += this.calcGastroDiscount(itemPrice, discount)
      }
    }
    return discountValue
  }

  private calcGastroDiscount(itemPrice: number, discount: any) {
    return -(
      (discount.fix * itemPrice) / this.getCartTotal() +
      (itemPrice * discount.percentage) / 100
    )
  }

  private checkDiscounts(): boolean {
    const gastro = this.gastroService.$gastro
    if (gastro.discount === undefined) {
      return false
    }

    for (const discount of gastro.discount) {
      if (this.isDiscountActive(discount)) {
        return true
      }
    }

    return false
  }

  private isDiscountActive(discount: Discount): boolean {
    if (
      this.sessionDataService.$isDelivery === true &&
      discount.delivery === true
    ) {
      return this.isDiscountActiveForWhereType(discount)
    }

    if (
      this.sessionDataService.$isPickUp === true &&
      discount.pickup === true
    ) {
      return this.isDiscountActiveForWhereType(discount)
    }

    if (
      this.sessionDataService.$inhouseLink === true &&
      discount.inhouse === true
    ) {
      return this.isDiscountActiveForWhereType(discount)
    }

    return false
  }

  public isDiscountWithinTime(discount: Discount): boolean {
    if (
      (discount.from === undefined && discount.to === undefined) ||
      (discount.from === '' && discount.to === '')
    ) {
      discount.isWithinTime = true
      return true
    }

    if (
      (this.sessionDataService.$isDelivery === true &&
        this.timeToDate(discount.from) <=
          this.timeToDate(this.sessionDataService.$deliveryTime) &&
        this.timeToDate(this.sessionDataService.$deliveryTime) <=
          this.timeToDate(discount.to)) ||
      (this.timeToDate(discount.from) <=
        this.timeToDate(this.cartService.getEarlyTime()) &&
        this.timeToDate(this.cartService.getEarlyTime()) <=
          this.timeToDate(discount.to))
    ) {
      discount.isWithinTime = true
      return true
    }

    if (
      this.sessionDataService.$isPickUp === true &&
      this.timeToDate(discount.from) <=
        this.timeToDate(this.sessionDataService.$pickUpTime) &&
      this.timeToDate(this.sessionDataService.$pickUpTime) <=
        this.timeToDate(discount.to)
    ) {
      discount.isWithinTime = true
      return true
    }
    discount.isWithinTime = false
    return false
  }

  isDiscountActiveForWhereType(discount) {
    if (!this.isDiscountWithinTime(discount)) {
      return false
    }

    if (discount.days === undefined) {
      return true
    }

    const now = new Date()
    if (discount.days[now.getDay()] === true) {
      return true
    }

    return false
  }

  public timeToDate(time: string): Date {
    const hours = parseInt(time.slice(0, 2))
    const minutes = parseInt(time.slice(-2))
    return new Date(2000, 0, 1, hours, minutes)
  }

  private applyCoupons(currentPrice: number, total: number): number {
    this.couponService.deleteIfCouponCodeIsNotValidRestriction(currentPrice)

    let newPrice
    do {
      newPrice = currentPrice
      for (const coupon of this.couponService.currentCouponList) {
        newPrice = this.applyCoupon(newPrice, total, coupon)
      }
      this.couponService.deleteCouponIfPriceMinus(newPrice)
    } while (newPrice < 0)

    return Math.max(newPrice, 0)
  }

  private applyCoupon(currentPrice: number, total: number, coupon) {
    if (coupon.rewardType === 'flatAmount') {
      currentPrice -= coupon.rewardMagnitude
    } else if (coupon.rewardType === 'flatPercentage') {
      currentPrice -= (total * coupon.rewardMagnitude) / 100
    }
    return currentPrice
  }

  //Important: If we don't use this one, we make more cash fee then we should!
  //Netto split fee (we calc brutto in reports later)
  public calcSplitCashFeeWithoutCeil() {
    const gastro = this.gastroService.$gastro
    let fixedFee = 0.0
    let percentualFee = 0.0
    if (gastro.splitFee) {
      fixedFee = gastro.splitFee.fixed
      percentualFee = gastro.splitFee.percentual
    }

    let fee = fixedFee + this.getTotal() * 100 * percentualFee
    //fee = Math.ceil(fee);
    fee = fee / 100
    return fee
  }

  orderAccept(name: string, orderNumber?: string) {
    this.userService.user.lastOrderedName = name
    if (!this.gastroService.$gastro.self) {
      Swal.fire({
        allowOutsideClick: false,
        heightAuto: false,
        html: 'Deine Bestellung wurde abgeschickt. Das Personal wird diese in Kürze annehmen.',
        imageHeight: 200,
        imageUrl: '../assets/logos/orderbird/RGB_obLogo_blue.svg',
        imageWidth: 200,
        showConfirmButton: false,
        timer: 4000,
        title: 'Bestellung wurde Abgeschickt',
      }).then(() => {
        Swal.fire({
          allowOutsideClick: false,
          cancelButtonColor: '#3D9970',
          cancelButtonText: 'Rechnung anschauen',
          confirmButtonColor: '#7FDBFF',
          confirmButtonText: 'Zurück zur Speisekarte',
          heightAuto: false,
          icon: 'success',
          showCancelButton: true,
          text: 'Hurra! Deine Bestellung wurde angenommen',
          title: 'Angenommen',
        }).then((result) => {
          if (result.value) {
            this.navCtrl.navigateRoot('restaurant-page')
            this.$hasSeenNewOrders = false
          } else {
            this.navCtrl.navigateRoot('checkout')
            this.$hasSeenNewOrders = true
          }
        })
      })
    } else {
      const span = document.createElement('span')
      let text = `Bestellung #${orderNumber} kommt gleich an Deinen Tisch! 🚀 Einen Moment noch.`

      if (this.sessionDataService.$isPickUp) {
        const href = ''
        //href += "https://www.google.de/maps/place/"
        //href += this.gastro.lat + "N+"
        //href += this.gastro.long + "E"
        text += 'Du kannst deine Bestellung '
        if (this.sessionDataService.$pickupDate !== getDateString(new Date())) {
          text += `am <b style='font-size: 1.2em;'>${this.sessionDataService.$pickupDate}</b> `
        }
        text += `um <b style='font-size: 1.2em;'>${this.sessionDataService.$pickUpTime}</b>`
        if (href !== '') {
          text += ` im <a target='_blank' rel='noopener noreferrer' href='${href}'>
					${this.gastroService.$gastro.name}</a> abholen`
        } else {
          text += ` im <b style='font-size: 1.2em;'>${this.gastroService.$gastro.name}</b> abholen`
        }
      } else if (this.sessionDataService.$isDelivery) {
        if (this.sessionDataService.$deliveryTime === 'Sobald wie möglich') {
          if (
            this.sessionDataService.$deliveryInformations
              .expectedDeliveryTime !== undefined
          ) {
            text += 'Deine Bestellung wird '
            if (
              this.sessionDataService.$deliveryInformations.deliveryDate !==
              getDateString(new Date())
            ) {
              text += `am <b style='font-size: 1.2em;'>
							${this.sessionDataService.$deliveryInformations.deliveryDate}</b> `
            }
            text += `ungefähr um <b style='font-size: 1.2em;'>
						${this.sessionDataService.$deliveryInformations.expectedDeliveryTime}</b> bei dir sein.`
          }
        } else {
          text += 'Deine Bestellung wird '
          if (
            this.sessionDataService.$deliveryInformations.deliveryDate !==
            getDateString(new Date())
          ) {
            text += `am <b style='font-size: 1.2em;'>
						${this.sessionDataService.$deliveryInformations.deliveryDate}</b> `
          }
          text += `ungefähr um <b style='font-size: 1.2em;'>
					${this.sessionDataService.$deliveryTime}</b> bei dir sein.`
        }
      }

      span.innerHTML = text
      if (!this.gastroService.$gastro.hasPayAtTable) {
        Swal.fire({
          allowOutsideClick: false,
          confirmButtonColor: '#7FDBFF',
          confirmButtonText: 'OK',
          heightAuto: false,
          html: span,
          icon: 'success',
          title: 'Abgeschickt',
        }).then((result) => {
          if (result.value) {
            this.$hasSeenNewOrders = false
            this.navCtrl.navigateRoot('status')
          }
        })
      } else {
        this.alertService.updateLoadingContentSuccess(text, true, 0)

        const confirmButton = Swal.getConfirmButton()
        confirmButton.addEventListener('click', () => {
          this.$hasSeenNewOrders = false
          this.navCtrl.navigateRoot('restaurant-page')
        })
      }
    }

    if (
      this.paymentService.$paymentId === 'credit' &&
      this.gastroService.gastro?.hasDibsPayment === true
    ) {
      this.paymentService.updateDibsReference(
        this.paymentService.dibsPaymentId,
        this.lastCartId
      )
    }
  }

  calcCoupons(itemPrice: number) {
    let couponDiscount = 0
    for (const coupon of this.couponService.currentCouponList) {
      if (coupon.rewardType === 'flatAmount') {
        couponDiscount += -(
          coupon.rewardMagnitude / this.cartService.$cart.length
        )
      } else if (coupon.rewardType === 'flatPercentage') {
        couponDiscount += -((itemPrice * coupon.rewardMagnitude) / 100)
      }
    }
    return couponDiscount
  }
  getFixDiscountForSingleItem(val, fixDiscount, per) {
    let ret = 0
    ret = fixDiscount
    ret += val * (per / 100)
    ret *= -1
    return ret
  }

  /**
   * this function checks all parameters.
   * Should be called before a payment is executed
   * ideally this function reduces the error where payments are performed but no
   * order is send to the gastronom
   * @returns true if order is valid or false if not
   */
  async preFlightCheckPayment(): Promise<boolean> {
    const orderArray = []
    const userName = ''
    const cartID = 'test'
    const orderNumber = ''
    if (
      this.gastroService.$gastroId === undefined ||
      this.gastroService.$gastroId === null ||
      this.gastroService.$gastroId === ''
    ) {
      this.sentryService.captureError(
        'Preflight Error: Invalid gastroID during validation',
        this.gastroService.$gastroId
      )
      return false
    }

    try {
      await this.afs.collection('preflight').add({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        gastroID: this.gastroService.$gastroId,
        success: true,
      })
    } catch (e) {
      this.sentryService.captureError(
        'Preflight Error: Invalid firebase instance during validation',
        this.gastroService.$gastroId
      )
      return false
    }

    //create orderarray
    for (let i = 0, len = this.cartService.$cart.length; i < len; i++) {
      const cartItem = this.cartService.$cart[i]
      const order = this.createOrder(
        cartItem,
        i === 0,
        userName,
        cartID,
        orderNumber
      )
      orderArray.push(order)
    }

    return !this.checkIfFieldisUndefined(orderArray)
  }

  /**
   *
   * @param orderArray
   * @returns true if something is undefined false if not
   */
  checkIfFieldisUndefined(orderArray): boolean {
    let found = false
    const invalidVal = []
    JSON.stringify(orderArray, (k, v) => {
      if (v === undefined) {
        found = true
        invalidVal.push(k)
        return null
      } else {
        return v
      }
    })

    if (invalidVal.length > 0) {
      this.sentryService.captureError(
        `Preflight Error: Invalid undefined value during validation with paymentID: 
				${this.paymentService.$paymentId}. `,
        invalidVal
      )
    }
    return found
  }

  async createStripePaymentMethod(): Promise<any> {
    const elements = this.paymentService.elements

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      throw submitError
    }

    const options = {
      card: elements.getElement('cardNumber'),
      type: 'card',
      // params: {
      // 	billing_details: {
      // 		name: 'Jenny Rosen',
      // 	}
      // }
    }

    const { error, paymentMethod } =
      await this.paymentService.stripe.createPaymentMethod(options)

    if (error) {
      throw error
    }

    return paymentMethod
  }

  /**
   * generates a bill for a given cart and provides it by [method]
   *
   * @param cart
   * the items that have been purchased
   *
   * @param orderDocArray
   * the array of document-Ids of each order
   *
   * @param method
   * Decides which way the bill is acquired, either by download or email
   */

  async getBillForCart(
    cart: any,
    orderDocArray?: any[],
    method: 'download' | 'email' = 'download'
  ) {
    if (await this.isOrderbirdAPI()) {
      this.downloadEReceipt(cart.itemDetails[0].cartID)
      return
    }
    if (method === 'email') {
      this.sendInvoiceAsMail(cart, orderDocArray)
    } else if (method === 'download') {
      this.downloadInvoice(cart, orderDocArray)
    }
  }

  async downloadInvoice(cart: any, orderDocArray?: any[]) {
    this.utilService.loadingWindow = await this.loadingCtrl.create({
      message: 'Die Rechnung wird für den Download vorbereitet.',
    })
    this.utilService.loadingWindow.present()

    let followupInvoice
    let invoiceNr = ''

    /* if there is already invoiceNr dont increase it,
    otherwise taxes could be abused */
    if (!cart.itemDetails[0].invoiceNumber) {
      this.paymentService.$invoiceNumber = `${
        this.gastroService.$gastro.invoicePre
      }-${this.gastroService.$gastro.invoiceCounter}`
      followupInvoice = false
    } else {
      followupInvoice = true
      invoiceNr = cart.itemDetails[0].invoiceNumber
    }

    const orders = []
    for (const e of cart.itemDetails) {
      if (e.id === undefined && e.mobilePayed === true) {
        orders.push(orderDocArray[cart.itemDetails.indexOf(e)].id)
      } else if (e.mobilePayed === true) {
        orders.push(e.id)
      }
    }

    const data = {
      followupInvoice: followupInvoice,
      gastro: this.gastroService.$gastroId,
      invoiceNr: invoiceNr,
      orders: orders,
    }
    try {
      const url = `${environment.functionsUrl}bucketTest`
      // eslint-disable-next-line import/namespace
      const response = await Promise.resolve($.post(url, data))
      const a = document.createElement('a')
      a.href = response.downloadLink
      a.setAttribute(
        'download',
        `${this.gastroService.$gastro.name} Rechnung - ${response.invoiceNumber}`
      )
      a.click()
      this.utilService.loadingWindowDismiss()
    } catch (e) {
      console.error('error download bill', e)
      this.utilService.loadingWindowDismiss()
    }
  }

  async sendInvoiceAsMail(cart: any, orderDocArray?: any[]) {
    if (await this.isOrderbirdAPI()) {
      return
    }
    let followupInvoice
    let invoiceNr = ''

    /* if there is already invoiceNr dont increase it,
    otherwise taxes could be abused */
    if (!cart.itemDetails[0].invoiceNumber) {
      this.paymentService.$invoiceNumber = `${
        this.gastroService.$gastro.invoicePre
      }-${this.gastroService.$gastro.invoiceCounter}`
      followupInvoice = false
    } else {
      followupInvoice = true
      invoiceNr = cart.itemDetails[0].invoiceNumber
    }

    const orders = []
    for (const e of cart.itemDetails) {
      if (e.mobilePayed === true) {
        orders.push(orderDocArray[cart.itemDetails.indexOf(e)].id)
      }
    }

    const data = {
      followupInvoice: followupInvoice,
      gastro: this.gastroService.$gastroId,
      invoiceNr: invoiceNr,
      orders: orders,
    }

    try {
      const url = `${environment.functionsUrl}bucketTest`
      // eslint-disable-next-line import/namespace
      const response = await Promise.resolve($.post(url, data))
      this.sendBillAsMail({
        downloadLink: response.downloadLink,
        invoiceNr: data.invoiceNr,
      })
    } catch (e) {
      console.error('error sending bill as email', e)
    }
  }

  /**
   * Sends the bill as an email to the user's email address.
   *
   * @param {Object} invoiceData
   *  - An object containing invoice data.
   *
   * @param {string} invoiceData.downloadLink
   *  - The download link for the invoice.
   *
   * @param {string} invoiceData.invoiceNr
   *  - The invoice number.
   *
   * @returns {Promise<void>}
   *  - A Promise that resolves once the email has been sent.
   */
  public async sendBillAsMail(invoiceData) {
    const mail = this.userService.user.email
    const url = `${environment.functionsUrl}sendInvoiceAsMail`
    const body = {
      gastroName: this.gastroService.$gastro.name,
      invoiceLink: invoiceData.downloadLink,
      invoiceName: invoiceData.invoiceNr,
      mail: mail,
    }

    // eslint-disable-next-line import/namespace
    await $.post(url, body)
    this.presentBillAsEmailToast('top')
  }

  /**
   * creates a small success toast to show that a bill has been sent via email
   * @param position
   */

  async presentBillAsEmailToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 1500,
      message: 'Rechnung wurde an deine Email verschickt!',
      position: position,
    })

    await toast.present()
  }

  /**
   * is given a profile cart object
   * and creates a second cart object that fits the bill-creator
   * returns this cart object
   *
   * @param position
   */
  async createCartForBill(cart) {
    const cartForBill: any = {}

    cartForBill.cartID = cart.cartID
    cartForBill.gastroID = cart.gastroID
    cartForBill.itemDetails = []

    const arrayOfOrders = []
    for (const loadedOrder of cart.loadedOrders) {
      arrayOfOrders.push(
        await this.afs
          .collection('gastro')
          .doc(cart.gastroID)
          .collection('order')
          .doc(loadedOrder.id)
          .get()
          .toPromise()
      )
    }
    for (const orderDoc of arrayOfOrders) {
      cartForBill.itemDetails.push(orderDoc.data())
    }

    return cartForBill
  }

  async isOrderbirdAPI() {
    const api = await this.checkoutSystemApiService.getActiveApi()
    if (api !== undefined && api instanceof OrderbirdService) {
      return true
    } else {
      return false
    }
  }

  async downloadEReceipt(cartId: string) {
    // eslint-disable-next-line import/namespace
    $.ajax({
      data: {
        cartId: cartId,
        gastroId: this.gastroService.$gastroId,
      },
      method: 'POST',
      url: `${environment.functionsUrlEU}downloadEReceipt`,
      xhrFields: {
        responseType: 'blob', // Important for handling the binary response
      },
      // eslint-disable-next-line sort-keys
      success: function (blob) {
        // Create a Blob from the PDF Stream
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'downloaded.pdf' // Provide a file name

        // Append anchor to body.
        document.body.appendChild(a)
        a.click() // Simulate click to download file

        // Cleanup
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      },
      // eslint-disable-next-line sort-keys
      error: function (xhr, status, error) {
        console.error('Error downloading PDF: ', error)
      },
    })
  }
  /**
   * Calls DownloadEReceipt, this downloads a pdf of the E-Receipt of the
   * provided URL
   * @param invoiceLink contains the URL of the Receipt to be downloaded
   */
  public async downloadEReceiptWithUrl(invoiceLink: string): Promise<void> {
    const url = `${environment.functionsUrlEU}downloadEReceipt`
    const payload = { invoiceLink: invoiceLink }

    try {
      const blob = await lastValueFrom(
        this.http.post(url, payload, { responseType: 'blob' })
      )

      const blobUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = blobUrl
      a.download = 'elektronischer_beleg.pdf'

      document.body.appendChild(a)
      a.click()

      window.URL.revokeObjectURL(blobUrl)
      document.body.removeChild(a)
    } catch (error) {
      console.error('Error downloading PDF:', error)
      throw error
    }
  }
  /**
   * Calls downloadExpenseReceipt, this downloads a expense receipt pdf of the
   * provided URL
   * @param expenseEReceiptUrl contains the URL of the Receipt to be downloaded
   */
  public async downloadExpenseReceipt(
    expenseEReceiptUrl?: string
  ): Promise<void> {
    const url = `${environment.functionsUrlEU}downloadExpenseReceipt`
    const payload = {
      invoiceLink: expenseEReceiptUrl,
    }

    try {
      const blob = await lastValueFrom(
        this.http.post(url, payload, { responseType: 'blob' })
      )

      const blobUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = blobUrl
      a.download = 'Bewirtungsbeleg.pdf'

      document.body.appendChild(a)
      a.click()

      window.URL.revokeObjectURL(blobUrl)
      document.body.removeChild(a)
    } catch (error) {
      console.error('Error downloading PDF:', error)
      throw error
    }
  }

  /**
   * Makes the API call "generateOrderNumber" to retrieve the Order Number
   * @returns the generated order number
   */
  generateOrderNumber(): Observable<string> {
    const url = `${environment.functionsUrlEU}generateOrderNumber`
    const body = { gastroId: this.gastroService.$gastroId }

    return this.httpClient
      .post<string>(url, body, {
        headers: { 'Content-Type': 'application/json' },
      })
      .pipe(
        catchError(() => {
          return of('') // Return an observable with an empty string as a fallback
        })
      )
  }
}
