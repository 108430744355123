import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { GastroService } from 'src/app/services/gastro.service'
import { MenuService } from 'src/app/services/menu.service'
import { TableService } from 'src/app/services/table.service'
import { TableContentService } from 'src/app/services/table-content.service'

@Component({
  selector: 'app-start-of-interaction',
  templateUrl: './start-of-interaction.page.html',
  styleUrls: ['./start-of-interaction.page.scss'],
})
export class StartOfInteractionPage implements OnInit {
  constructor(
    public gastroService: GastroService,
    public menuService: MenuService,
    public router: Router,
    public tableService: TableService,
    public tableContentService: TableContentService
  ) {}

  public tableNr = 0
  public isTableLoading = false
  public isTableEmpty = true

  ngOnInit() {}

  async ionViewWillEnter() {
    this.tableNr = (await this.tableService.getAsyncTable()).tischNR
    this.isTableLoading = true
    this.tableContentService.fetchOBTableData().then(() => {
      this.isTableLoading = false
      this.isTableEmpty = this.checkIfTableEmpty()
    })
  }

  /**
   * Navigates to the "restaurant-page" when called.
   */
  goToMenu() {
    this.router.navigate(['restaurant-page'])
  }

  /**
   * Navigates to the "table-content" when called, typically used for the pay-at-table feature.
   */
  goToPayAtTable() {
    this.router.navigate(['table-content'])
  }

  /**
   * Checks if the table is empty by examining the length of the table content.
   * @returns {boolean} - Returns true if the table is empty, otherwise false.
   */
  checkIfTableEmpty() {
    let isTableEmpty = false

    if (this.tableContentService.tableContent.value.length < 1) {
      isTableEmpty = true
    }

    return isTableEmpty
  }
}
