import { Injectable } from '@angular/core'
import {
  AngularFirestore,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore'
import { Gastro, TemplateConfig } from 'src/shared/split-submodules/types/types'
import { FontService } from './font.service'
import { GastroService } from './gastro.service'

@Injectable({ providedIn: 'root' })
export class TemplateStyleService {
  constructor(
    public gastroService: GastroService,
    public db: AngularFirestore
  ) {}
  private templateConfig: TemplateConfig = {
    backgroundImage: '',
    backgroundOpacity: '0.1',
    buttonColor: '',
    categoriesConfig: {
      bgColor: '#FFFFFF',
      color: '',
      font: '',
      showCategoriesInSwitch: false,
    },
    categoriesDescConfig: {
      color: 'grey',
      font: 'Oswald, sans-serif',
    },
    descConfig: {
      color: '',
      font: '',
    },
    dishConfig: {
      color: '',
      font: '',
    },
    headerConfig: {
      color: 'black',
      font: '',
    },
    layout: 'Unset',
    ownFonts: [],
    titleConfig: {
      color: '',
      font: '',
    },
  }
  public get $templateConfig() {
    return this.templateConfig
  }
  public set $templateConfig(value) {
    this.templateConfig = value
  }

  /**
   * load the gastro object and takes the config object. Sets a local variable to its value
   */
  // eslint-disable-next-line max-lines-per-function
  public async loadTemplateConfig() {
    const gastroRef = await this.db
      .collection<Gastro>('gastro')
      .doc(this.gastroService.$gastroId)
      .get()
      .toPromise()
    if (gastroRef.data().templateConfig != undefined) {
      if (gastroRef.data().templateConfig.titleConfig != undefined) {
        this.$templateConfig.titleConfig =
          gastroRef.data().templateConfig.titleConfig
      } else {
        this.$templateConfig.titleConfig = {
          font: 'Oswald, sans-serif',
          color: 'black',
        }
      }
      if (gastroRef.data().templateConfig.headerConfig != undefined) {
        this.$templateConfig.headerConfig =
          gastroRef.data().templateConfig.headerConfig
      } else {
        this.$templateConfig.headerConfig = {
          font: 'Oswald, sans-serif',
          color: 'black',
        }
      }
      if (gastroRef.data().templateConfig.categoriesConfig != undefined) {
        this.$templateConfig.categoriesConfig =
          gastroRef.data().templateConfig.categoriesConfig
      } else {
        this.$templateConfig.categoriesConfig = {
          bgColor: '#FFFFFF',
          color: 'black',
          font: 'Oswald, sans-serif',
          showCategoriesInSwitch: false,
        }
      }
      if (gastroRef.data().templateConfig.categoriesDescConfig != undefined) {
        this.$templateConfig.categoriesDescConfig =
          gastroRef.data().templateConfig.categoriesDescConfig
      } else {
        this.$templateConfig.categoriesDescConfig = {
          font: 'Oswald, sans-serif',
          color: 'grey',
        }
      }
      if (gastroRef.data().templateConfig.dishConfig != undefined) {
        this.$templateConfig.dishConfig =
          gastroRef.data().templateConfig.dishConfig
      } else {
        this.$templateConfig.dishConfig = {
          font: 'Oswald, sans-serif',
          color: 'black',
        }
      }
      if (gastroRef.data().templateConfig.descConfig != undefined) {
        this.$templateConfig.descConfig =
          gastroRef.data().templateConfig.descConfig
      } else {
        this.$templateConfig.descConfig = {
          font: 'Oswald, sans-serif',
          color: 'grey',
        }
      }
      if (gastroRef.data().templateConfig.layout != undefined) {
        this.$templateConfig.layout = gastroRef.data().templateConfig.layout
      } else {
        this.$templateConfig.layout = 'default'
      }
      if (gastroRef.data().templateConfig.backgroundImage != undefined) {
        this.$templateConfig.backgroundImage =
          gastroRef.data().templateConfig.backgroundImage
      } else {
        this.$templateConfig.backgroundImage = ''
      }
      if (gastroRef.data().templateConfig.buttonColor != undefined) {
        this.$templateConfig.buttonColor =
          gastroRef.data().templateConfig.buttonColor
      } else {
        this.$templateConfig.buttonColor = ''
      }
      if (gastroRef.data().templateConfig.backgroundOpacity != undefined) {
        this.$templateConfig.backgroundOpacity =
          gastroRef.data().templateConfig.backgroundOpacity
      } else {
        this.$templateConfig.backgroundOpacity = '0.1'
      }
      if (gastroRef.data().templateConfig.priceFormatting != undefined) {
        this.$templateConfig.priceFormatting =
          gastroRef.data().templateConfig.priceFormatting
      } else {
        this.$templateConfig.priceFormatting = {
          currencyCode: 'EUR',
          digitsInfo: '1.2-2',
          display: 'symbol-narrow',
          locale: 'de',
        }
      }
      if (gastroRef.data().templateConfig.ownFonts != undefined) {
        this.$templateConfig.ownFonts = gastroRef.data().templateConfig.ownFonts
      }
      if (gastroRef.data().templateConfig.ownFonts != undefined) {
        this.$templateConfig.ownFonts = gastroRef.data().templateConfig.ownFonts
      }
      if (
        gastroRef.data().templateConfig.categoriesConfig
          .showCategoriesInSwitch != undefined
      ) {
        this.$templateConfig.categoriesConfig.showCategoriesInSwitch =
          gastroRef.data().templateConfig.categoriesConfig.showCategoriesInSwitch
      }
    }
  }

  static InjectFonts(ownFonts: ownFonts[]) {
    if (ownFonts === undefined) {
      return
    }
    if (ownFonts.length > 0) {
      ownFonts.forEach((font: any) => {
        if (font.tracked === true) {
          FontService.trackFont(font.id)
        }
        FontService.InjectFont(font.path, font.name)
      })
    }
  }
}

export interface ownFonts {
  tracked?: boolean
  id?: string
  path: string
  name: string
}
