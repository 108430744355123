import { Injectable } from '@angular/core'
import { ProductTag } from 'src/shared/split-submodules/types/types'

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor() {}

  /**
   * Returns the path to an icon which belongs to [tag].
   * If there is no icon related to [tag] it will return ''
   * @param tag
   * @returns
   */
  getPathToProductTagIcon(tag: number): string {
    switch (tag) {
      case 0:
        return 'assets/icon/vegan_symbol.svg'
      case 1:
        return 'assets/icon/vegetarian_symbol.svg'
      default:
        return ''
    }
  }
}
