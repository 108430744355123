import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Subscription } from 'rxjs'
import { AlertService } from './alert.service'
import { GastroService } from './gastro.service'
import { GastroResettable, ResetService } from './reset.service'
import { StorageService } from './storage.service'

@Injectable({ providedIn: 'root' })
export class HistoryService implements GastroResettable {
  private allOrderedItemsIds: string[] = []

  private allOrderedItems: any[] = []
  public get $allOrderedItems(): any[] {
    return this.allOrderedItems
  }
  public set $allOrderedItems(value: any[]) {
    this.allOrderedItems = value
  }

  private allOrderedItemsSubscriptions: Subscription[] = []

  private allOrderedCarts: any[] = []

  public get $allOrderedCarts(): any[] {
    return this.allOrderedCarts
  }
  public set $allOrderedCarts(value: any[]) {
    this.allOrderedCarts = value
  }

  private alertsSent: Map<string, Date> = new Map<string, Date>()

  public resetOrderIds() {
    this.allOrderedItemsIds = []
    this.storageService.clear('allOrderedItemsIds')

    for (const sub of this.allOrderedItemsSubscriptions) {
      sub.unsubscribe()
    }

    this.allOrderedItems = []
    this.allOrderedItemsSubscriptions = []
  }

  public set $allOrderedItemsIds(ids: string[]) {
    this.allOrderedItemsIds = ids
    this.storageService.store('allOrderedItemsIds', ids)

    for (const sub of this.allOrderedItemsSubscriptions) {
      sub.unsubscribe()
    }

    this.allOrderedItems = []
    this.allOrderedItemsSubscriptions = []

    if (ids.length > 0) {
      for (const id of this.$allOrderedItemsIds) {
        this.addSubscription(id)
      }
    }
  }

  public get $allOrderedItemsIds(): string[] {
    return this.allOrderedItemsIds
  }

  public addOrderItem(id: string) {
    this.addSubscription(id)
  }

  constructor(
    private afs: AngularFirestore,
    private alertService: AlertService,
    private gastroService: GastroService,
    private resetService: ResetService,
    private storageService: StorageService
  ) {
    this.storageService.load('allOrderedItemsIds').then((val) => {
      if (val) {
        this.$allOrderedItemsIds = val
      }
    })
    this.registerGastroReset()
  }

  onGastroLogout(): void {
    this.resetOrderIds()
  }

  registerGastroReset(): void {
    this.resetService.registerGastroReset(this)
  }

  private shouldShowNewTimesAlert(order): boolean {
    if (!this.alertsSent.has(order.cartID)) {
      return true
    }

    const lastAlert = this.alertsSent.get(order.cartID)
    const now = new Date()

    if (now.getTime() - lastAlert.getTime() >= 1000 * 10) {
      return true
    }

    return false
  }

  private addSubscription(id: string) {
    const index = this.allOrderedItemsSubscriptions.length
    this.allOrderedItemsSubscriptions.push(
      this.afs
        .collection('gastro')
        .doc(this.gastroService.$gastroId)
        .collection('order')
        .doc(id)
        .snapshotChanges()
        .subscribe((doc) => {
          const newValue = {
            id: doc.payload.id,
            ...(doc.payload.data() as any),
          }

          const oldValue = this.allOrderedItems[index]
          if (oldValue !== undefined) {
            if (newValue.delivery === true) {
              if (
                newValue.deliveryInformations.deliveryDate !==
                  oldValue.deliveryInformations.deliveryDate ||
                newValue.deliveryInformations.expectedDeliveryTime !==
                  oldValue.deliveryInformations.expectedDeliveryTime
              ) {
                if (this.shouldShowNewTimesAlert(newValue)) {
                  this.alertService.confirmAlert(
                    'Veränderte Zeiten',
                    'Die Lieferzeit für eine Bestellung hat sich verändert.'
                  )
                  this.alertsSent.set(newValue.cartID, new Date())
                }
              }
            } else if (newValue.pickup === true) {
              if (
                newValue.pickupDate !== oldValue.pickupDate ||
                newValue.pickupTime !== oldValue.pickupTime
              ) {
                if (this.shouldShowNewTimesAlert(newValue)) {
                  this.alertService.confirmAlert(
                    'Veränderte Zeiten',
                    'Die Abholzeit für eine Bestellung hat sich verändert.'
                  )
                  this.alertsSent.set(newValue.cartID, new Date())
                }
              }
            }
          }

          this.allOrderedItemsIds[index] = doc.payload.id
          this.allOrderedItems[index] = newValue
          this.groupOrdersCart()
          this.storageService.store(
            'allOrderedItemsIds',
            this.allOrderedItemsIds
          )
        })
    )
  }

  public getAllTips() {
    let tips = 0
    this.allOrderedItems.forEach((e) => {
      tips += e.tip
    })
    return tips
  }
  public getAllDeliveryFee() {
    let deliveryFee = 0
    this.allOrderedItems.forEach((e) => {
      deliveryFee += e.deliveryFee
    })
    return deliveryFee
  }
  public getAllDiscount() {
    let discount = 0
    this.allOrderedItems.forEach((e) => {
      discount += e.discount
    })
    return discount
  }
  public getOpenOrders(): any[] {
    return this.$allOrderedItems.filter((elem) => elem.state !== 1)
  }

  public getFinishedOrders(): any[] {
    return this.$allOrderedItems
      .filter((elem) => elem.state === 1)
      .sort((a, b) => {
        return b.finishedAt.toDate() - a.finishedAt.toDate()
      })
  }
  /**
   * Calculates the total tips for a given cart by summing up the tip amount from each item.
   *
   * @param {Object} cart - The cart containing item details.
   * @returns {number} - The total tips for the cart.
   */

  getCartTips(cart) {
    let tips = 0
    cart.itemDetails.forEach((e) => {
      tips += e.tip
    })
    return tips
  }
  /**
   * Calculates the total delivery fee for a given cart by summing up the delivery fee from each item.
   *
   * @param {Object} cart - The cart containing item details.
   * @returns {number} - The total delivery fee for the cart.
   */

  getCartDeliveryFee(cart) {
    let deliveryFee = 0
    cart.itemDetails.forEach((e) => {
      deliveryFee += e.deliveryFee
    })
    return deliveryFee
  }

  /**
   * Calculates the total cost of the cart by summing up the price of each dish multiplied by its count,
   * then adding the cart discount, tips, and delivery fee.
   *
   * @param {Object} cart - The cart containing item details.
   * @returns {number} - The total cost of the cart.
   */
  getCartTotal(cart) {
    let sum = 0
    cart.itemDetails.forEach((element) => {
      sum += element.dish.count * element.dish.price
    })
    sum += this.getCartDiscount(cart)
    sum += this.getCartTips(cart)
    sum += this.getCartDeliveryFee(cart)
    return sum
  }
  /**
   * Calculates the total discount for a given cart by summing up the discount amount from each item.
   *
   * @param {Object} cart - The cart containing item details.
   * @returns {number} - The total discount for the cart.
   */
  getCartDiscount(cart) {
    let discount = 0
    cart.itemDetails.forEach((e) => {
      discount += e.discount
    })
    return discount
  }

  /**
   * groups all orders by cart
   * @param orders all orders which should be grouped
   */
  async groupOrdersCart() {
    const carts: any[] = []
    for (const order of this.allOrderedItems) {
      let found = false
      for (const cart of carts) {
        if (cart.cartID == order.cartID) {
          cart.itemDetails.push(order)
          found = true
          break
        }
      }
      if (found == false) {
        carts.push({
          cartID: order.cartID,
          gastroID: order.gastroID,
          itemDetails: [order],
          orderNr: order.code,
        })
      }
    }
    this.allOrderedCarts = carts
  }
}
