import { Injectable } from '@angular/core'
//import {firebase} from '@firebase/compat/app';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore'
import { environment } from 'src/environments/environment'
import 'firebase/compat/messaging'
import { CartService } from './services/cart.service'

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(/**private cartService: CartService*/) {}

  requestPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      try {
        // const messaging = firebase.messaging();
        //await messaging.requestPermission();
        // const token: string = await messaging.getToken();
        //console.log('User notifications token:', token);
        //this.cartService.setDeviceID(token);
      } catch (err) {
        console.log('err')
      }

      resolve()
    })
  }
}
