import { Injectable } from '@angular/core'
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore'
import { ToastController } from '@ionic/angular'
import { UserService } from 'src/app/services/user.service'

@Injectable({ providedIn: 'root' })
export class LoyaltyService {
  private couponCodeLength = 6

  constructor(
    public db: AngularFirestore,
    public userService: UserService,
    public toastController: ToastController
  ) {}

  /**
   * Updates an existing loyaltyCard by incrementing the counter or calls addLoyaltyCard if it does not exist
   *
   * @param gastroID
   * @param config - gastro loyalty programm config
   */
  public async updateLoyaltyCard(gastroID, loyaltyCardConfig) {
    const loyaltyCardRefCustomer = <any>(
      await this.db
        .collection('customer-profiles')
        .doc(this.userService.profile.userID)
        .collection('loyalties')
        .doc(`${gastroID}-${loyaltyCardConfig.loyaltyType}`)
        .get()
        .toPromise()
    )
    let loyaltyCardCustomer = loyaltyCardRefCustomer.data()
    const rewardCouponsRef = <any>await this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('coupons', (ref) =>
        ref.where('gastroID', '==', gastroID).where('personalized', '==', true)
      )
      .get()
      .toPromise()
    const rewardCouponDocs = rewardCouponsRef.docs

    /*   let loyaltyCardRefGastro = <any> await this.db.collection('gastro').doc(gastroID).get().toPromise()
	let loyaltyCardGastro = loyaltyCardRefGastro.data().loyaltyCard */

    //if no loyalty card yet, create one
    if (loyaltyCardCustomer == undefined) {
      loyaltyCardCustomer = await this.addLoyaltyCard(
        gastroID,
        loyaltyCardConfig
      )
    }

    if (!loyaltyCardCustomer.profileActive) {
      //if loyalty card is inactive, do nothing and return.
      return
    }

    //both cards ( the customers and the gastro config ) will be compared for any changes that have been made by the gastro
    if (loyaltyCardCustomer.limit != loyaltyCardConfig.limit) {
      loyaltyCardCustomer.limit = loyaltyCardConfig.limit
    }
    if (loyaltyCardCustomer.combine != loyaltyCardConfig.combine) {
      loyaltyCardCustomer.combine = loyaltyCardConfig.combine
    }
    if (loyaltyCardCustomer.loyaltyType != loyaltyCardConfig.loyaltyType) {
      loyaltyCardCustomer.loyaltyType = loyaltyCardConfig.loyaltyType
    }
    if (loyaltyCardCustomer.restriction != loyaltyCardConfig.restriction) {
      loyaltyCardCustomer.restriction = loyaltyCardConfig.restriction
    }
    if (
      loyaltyCardCustomer.rewardMagnitude != loyaltyCardConfig.rewardMagnitude
    ) {
      loyaltyCardCustomer.rewardMagnitude = loyaltyCardConfig.rewardMagnitude
    }
    if (loyaltyCardCustomer.rewardType != loyaltyCardConfig.rewardType) {
      loyaltyCardCustomer.rewardType = loyaltyCardConfig.rewardType
    }
    if (loyaltyCardCustomer.numberOfUses != loyaltyCardConfig.numberOfUses) {
      loyaltyCardCustomer.numberOfUses = loyaltyCardConfig.numberOfUses
    }
    if (loyaltyCardCustomer.milestones != loyaltyCardConfig.milestones) {
      loyaltyCardCustomer.milestones = loyaltyCardConfig.milestones
    }
    if (loyaltyCardCustomer.resetAt != loyaltyCardConfig.resetAt) {
      loyaltyCardCustomer.resetAt = loyaltyCardConfig.resetAt
    }

    if (
      loyaltyCardCustomer.loyaltyType == 'splitometer' &&
      loyaltyCardCustomer.points < loyaltyCardCustomer.limit
    ) {
      //check if the limit has been reached
      this.increasePointsLoyaltyCard(loyaltyCardCustomer, 1)
      const splitometerCouponDoc = rewardCouponDocs.find((couponDoc) => {
        return couponDoc.data().couponType == 'splitometer'
      })
      if (splitometerCouponDoc == undefined) {
        this.generateRewardSplitometer(loyaltyCardCustomer, gastroID)
      } //if there is no coupon yet, create new one
      else {
        this.updateRewardSplitometer(loyaltyCardCustomer, splitometerCouponDoc)
      } //update current coupon
    } else if (loyaltyCardCustomer.loyaltyType == 'standard') {
      this.increasePointsLoyaltyCard(loyaltyCardCustomer, 1)
    }
    await this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('loyalties')
      .doc(`${gastroID}-${loyaltyCardConfig.loyaltyType}`)
      .set(loyaltyCardCustomer, { merge: true })
    this.userService.fetchLoyaltyCards(this.userService.profile.userID)
    this.userService.fetchCoupons(this.userService.profile.userID)
  }

  /**
   * converts a full loyalty card into a Coupon
   * @param gastroID -> ID of gastro providing the loyalty card
   * @param loyaltyCardConfig -> loyalty object of the user from the firestore
   * TODO: Change behaviour based on countertype
   */
  async convertLoyaltyCard(gastroID, loyaltyCardConfig) {
    const loyaltyCardRefCustomer = <any>(
      await this.db
        .collection('customer-profiles')
        .doc(this.userService.profile.userID)
        .collection('loyalties')
        .doc(`${gastroID}-${loyaltyCardConfig.loyaltyType}`)
        .get()
        .toPromise()
    )
    const loyaltyCardCustomer = loyaltyCardRefCustomer.data()
    const rewardCouponsRef = <any>await this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('coupons', (ref) =>
        ref.where('gastroID', '==', gastroID).where('personalized', '==', true)
      )
      .get()
      .toPromise()
    const rewardCouponDocs = rewardCouponsRef.docs

    //generate reward on full points, do nothing if points is full till reward used and point is reset
    if (loyaltyCardCustomer.counterType == 'blocking') {
      if (loyaltyCardCustomer.points >= loyaltyCardCustomer.limit) {
        //if point reach the limit
        this.generateRewardStandard(
          loyaltyCardCustomer,
          gastroID,
          rewardCouponDocs
        )
        this.subtractPointsLoyaltyCard(loyaltyCardCustomer)
      }
    }

    //generate only 1 reward, add points overlimit.
    else if (loyaltyCardCustomer.counterType == 'softblocking') {
      if (loyaltyCardCustomer.points >= loyaltyCardCustomer.limit) {
        this.generateRewardStandard(
          loyaltyCardCustomer,
          gastroID,
          rewardCouponDocs
        )
        this.subtractPointsLoyaltyCard(loyaltyCardCustomer)
      }
    }

    //always add points and reset if points full, reward can be stacked
    else if (loyaltyCardCustomer.counterType == 'nonblocking') {
      if (loyaltyCardCustomer.points >= loyaltyCardCustomer.limit) {
        this.generateRewardStandard(
          loyaltyCardCustomer,
          gastroID,
          rewardCouponDocs
        )
        this.subtractPointsLoyaltyCard(loyaltyCardCustomer)
      }
    }

    await this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('loyalties')
      .doc(`${gastroID}-${loyaltyCardConfig.loyaltyType}`)
      .set(loyaltyCardCustomer, { merge: true })
    this.userService.fetchLoyaltyCards(this.userService.profile.userID)
    this.userService.fetchCoupons(this.userService.profile.userID)
  }

  /**
   * this functions adds a fresh loyaltyCard to a users Profile
   *
   * @param gastroID
   * @param loyaltyCardConfig - loyaltyCard config from gastro
   * @returns new loyalty card
   */
  public async addLoyaltyCard(gastroID, loyaltyCardConfig) {
    const loyaltyCardCustomer = {
      combine: loyaltyCardConfig.combine,
      counterType: loyaltyCardConfig.counterType,
      gastroActive: loyaltyCardConfig.active,
      gastroID: gastroID,
      limit: loyaltyCardConfig.limit,
      loyaltyType: loyaltyCardConfig.loyaltyType,
      milestones: loyaltyCardConfig.milestones,
      numberOfUses: loyaltyCardConfig.numberOfUses,
      points: 0,
      profileActive: true,
      resetAt: loyaltyCardConfig.resetAt,
      restriction: loyaltyCardConfig.restriction,
      rewardMagnitude: loyaltyCardConfig.rewardMagnitude,
      rewardType: loyaltyCardConfig.rewardType,
    }
    await this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('loyalties')
      .doc(`${gastroID}-${loyaltyCardCustomer.loyaltyType}`)
      .set(loyaltyCardCustomer)
    return loyaltyCardCustomer
  }

  /**
   * This function create a new splitometer coupon for a given gastroID
   *
   * @param loyaltyCard
   * @param gastroID
   */
  public async generateRewardSplitometer(loyaltyCard, gastroID) {
    const reachedMilestone =
      loyaltyCard.points - (loyaltyCard.points % loyaltyCard.milestones)
    const rewardMagnitude =
      (reachedMilestone / loyaltyCard.limit) * loyaltyCard.rewardMagnitude
    const now = new Date()
    const couponCode = 'SPLITOMETER'

    this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('coupons')
      .add({
        combine: loyaltyCard.combine,
        couponCode: couponCode,
        couponType: 'splitometer',
        createdAt: now,
        expireAt: 'none',
        gastroID: gastroID,
        numberOfUses: 'none',
        personalized: true,
        restriction: loyaltyCard.restriction,
        rewardMagnitude: rewardMagnitude,
        rewardType: loyaltyCard.rewardType,
        validFrom: now,
      })
  }

  /**
   * This function update the coupon reward to reached milestones.
   *
   * @param loyaltyCard
   * @param gastroID
   */
  public async updateRewardSplitometer(loyaltyCard, splitometerCouponDoc) {
    const reachedMilestone =
      loyaltyCard.points - (loyaltyCard.points % loyaltyCard.milestones)
    const rewardMagnitude =
      (reachedMilestone / loyaltyCard.limit) * loyaltyCard.rewardMagnitude
    const splitometerCoupon = splitometerCouponDoc.data()
    splitometerCoupon.rewardMagnitude = rewardMagnitude
    this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('coupons')
      .doc(splitometerCouponDoc.id)
      .set(splitometerCoupon, { merge: true })
  }

  /**
   * This function generates Reward for loyalty card for standard loyalty type
   *
   * @param loyaltyCard - loyalty card to be rewarded
   * @param gastroID - gastro ID
   * @param rewardCouponDocs - Array of documents of available coupons of the gastro
   */
  public async generateRewardStandard(loyaltyCard, gastroID, rewardCouponDocs) {
    const now = new Date()
    let couponCode
    do {
      couponCode = `SPLIT-${this.generateCouponCode()}`
    } while (
      rewardCouponDocs.findIndex(
        (coupon) => coupon.data().couponCode == couponCode
      ) != -1
    )
    this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('coupons')
      .add({
        combine: loyaltyCard.combine,
        couponCode: couponCode,
        couponType: 'standard',
        createdAt: now,
        expireAt: 'none',
        gastroID: gastroID,
        numberOfUses: 1,
        personalized: true,
        restriction: loyaltyCard.restriction,
        rewardMagnitude: loyaltyCard.rewardMagnitude,
        rewardType: loyaltyCard.rewardType,
        validFrom: now,
      })
  }

  /**
   * Generate a random coupon Code.
   *
   * @returns - randomized code
   */
  public generateCouponCode() {
    const result = []
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < this.couponCodeLength; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      )
    }
    return result.join('')
  }

  /**
   * This function increase/add points to loyalty card
   *
   * @param loyaltyCard - loyalty card, it's points to be added
   * @param points - number of points to be added.
   */
  public increasePointsLoyaltyCard(loyaltyCard, points) {
    loyaltyCard.points += points
  }

  /**
   * This function will subtract the points with the limit.
   *
   * @param loyaltyCard - loyalty card, it's points to be reset
   */
  public subtractPointsLoyaltyCard(loyaltyCard) {
    loyaltyCard.points = loyaltyCard.points - loyaltyCard.limit
  }

  /**
   * This function will reset the points to zero.
   *
   * @param loyaltyCard - loyalty card, it's points to be reset
   */
  public resetPointsLoyaltyCard(loyaltyCard) {
    loyaltyCard.points = 0
  }

  /**
   * This function save the changes of loyalty card activeness
   * @param loyaltyCard
   */
  saveLoyaltyCardProfileActiveChange(loyaltyCard) {
    this.db
      .collection('customer-profiles')
      .doc(this.userService.profile.userID)
      .collection('loyalties')
      .doc(`${loyaltyCard.gastroID}-${loyaltyCard.loyaltyType}`)
      .update({ profileActive: loyaltyCard.profileActive })
  }

  /**
   * This function creates and shows a success toast
   *
   * @param message - message to be showed
   */
  private async successToast(message: string) {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2000,
      message: message,
      position: 'bottom',
    })
    toast.present()
  }
}
