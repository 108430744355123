import { Injectable } from '@angular/core'
import { AlertController, LoadingController } from '@ionic/angular'
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {}

  public async confirmAlert(
    header: string,
    message: string
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      buttons: [
        {
          handler: () => {
            alert.dismiss()
          },
          text: 'Okay',
        },
      ],
      header: header,
      message: message,
    })

    alert.present()
    return alert
  }

  public async errorAlert(
    errorMessage: string,
    errorHeader = 'Fehler',
    subHeader = ''
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      buttons: [
        {
          handler: () => {
            alert.dismiss()
          },
          text: 'Okay',
        },
      ],
      cssClass: 'payment-error-modal',
      header: errorHeader,
      message: errorMessage,
      subHeader: subHeader,
    })

    alert.present()
    return alert
  }
  /**
   * Creates and returns a Promise that resolves to an Ionic Loading Controller
   * element with the specified message.
   * @param message - The message to be displayed on the loading spinner.
   * @returns A Promise that resolves to an HTMLIonLoadingElement representing
   * the created loading spinner.
   */

  public createLoading(message: string): Promise<HTMLIonLoadingElement> {
    return this.loadingController.create({
      backdropDismiss: false,
      cssClass: 'custom-loading',
      message: message,
      spinner: null,
    })
  }
  /**
   * Creates a Swal alert
   * @param htmlMessage the message that the alert should display, accepts html
   * @param cssClass add a custom css class
   * defaults to : "custom-loading-swal"
   */
  async createLoadingSwal(
    htmlMessage: string,
    cssClass = 'custom-loading-swal'
  ) {
    Swal.fire({
      allowOutsideClick: false, // Prevent closing by clicking outside
      customClass: {
        popup: cssClass, // Apply custom CSS classes to modal
      },
      heightAuto: false,
      html: `<div class="loading-wrapper">
          			<div class="loading-content spinner">
            			<p>${htmlMessage}</p>
          			</div>
        		</div>`,

      // Use the custom HTML for message and GIF
      showConfirmButton: false, // Hide the default confirm button
    })
  }

  /**
   * Updates the alert for the payment proccess(next instance)
   * @param htmlMessage the message that the alert should display accepts html
   */
  updateLoadingForPayment(htmlMessage: string) {
    Swal.update({
      html: `<div class="loading-wrapper">
          			<div class="loading-content spinner">
            			<p class="fade-text">${htmlMessage}</p>
          			</div>
        		</div>`,
    })
    // Triggering the fade-in animation after updating
    const fadeTextElement = document.querySelector('.fade-text') as HTMLElement
    if (fadeTextElement) {
      fadeTextElement.classList.remove('fade-in') // Remove the fade-in-text class if it exists
      void fadeTextElement.offsetWidth // Trigger reflow to restart animation
      fadeTextElement.classList.add('fade-in') // Add the fade-in-text class to start the animation
    }
  }
  /**
   * Updates to the final state of the Swal alert
   * @param htmlMessage the message that the alert should display accepts html
   * @param showConfirmButton if true: shows confirm Button with 'Alles klar!'
   * @param timeout timeout in seconds, 0 for no timeout
   */
  updateLoadingContentSuccess(
    htmlMessage: string,
    showConfirmButton: boolean,
    timeout: number
  ) {
    Swal.update({
      ...(showConfirmButton && {
        confirmButtonText: 'Alles Klar!',
        showConfirmButton: true,
      }),
      html: `<div class="loading-wrapper">
          			<div class="loading-content success">
            			<p class="fade-text">${htmlMessage}</p>
          			</div>
        		</div>`,
    })
    // Triggering the fade-in animation after updating
    const fadeTextElement = document.querySelector('.fade-text') as HTMLElement
    if (fadeTextElement) {
      fadeTextElement.classList.remove('fade-in')
      void fadeTextElement.offsetWidth
      fadeTextElement.classList.add('fade-in')
    }
    if (timeout !== 0) {
      setTimeout(() => {
        Swal.close()
      }, timeout * 1000)
    }
  }

  /**
   * creates a simple Swal alert with text and optional confirm(close) button
   * @param htmlMessage The text to display for the alert
   * @param cssClass css custom global class to modify the alert style
   * | use undefined for default value
   * @param confirmButtonText confirm button text | no button if
   * undefined/not provided
   */
  createAlert(
    htmlMessage: string,
    cssClass = 'swal-default-alert',
    confirmButtonText?: string
  ) {
    Swal.fire({
      allowOutsideClick: false,
      customClass: {
        popup: cssClass,
      },
      heightAuto: false,
      html: `<div class="alert-wrapper">
              <div class="alert-content">
                <p>${htmlMessage}</p>
              </div>
            </div>`,
      showConfirmButton: false,
    })

    if (confirmButtonText) {
      Swal.update({
        confirmButtonText: confirmButtonText,
        showConfirmButton: true,
      })
    }
  }
}
