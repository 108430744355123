import { HttpClient } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import {
  AngularFirestore,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore'
import { Router } from '@angular/router'
import {
  AlertController,
  LoadingController,
  NavController,
  PickerController,
  ToastController,
} from '@ionic/angular'
import { Storage } from '@ionic/storage'
import firebase from 'firebase/compat/app'
import * as $ from 'jquery'
import { Subscription } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Gastro } from 'src/shared/split-submodules/types/types'
import { GastroService } from './gastro.service'
import { ReportService } from './report.service'
import { GastroResettable, ResetService } from './reset.service'
import { SessionDataService } from './session-data.service'
import { StorageService } from './storage.service'
import { UtilService } from './util.service'

declare const Stripe

@Injectable({ providedIn: 'root' })
export class UserService implements GastroResettable {
  public profileAreaTabs = [
    {
      active: true,
      display: 'Persönliche Daten',
      enabled: true,
      iconFilled: 'person-circle',
      iconOutline: 'person-circle-outline',
      name: 'main',
      url: '/profile-area/main',
    },
    {
      active: false,
      display: 'Bezahlmethoden',
      enabled: true,
      iconFilled: 'card',
      iconOutline: 'card-outline',
      name: 'paymentMethods',
      url: '/profile-area/paymentMethods',
    },
    {
      active: false,
      display: 'Adressbuch',
      enabled: true,
      iconFilled: 'home',
      iconOutline: 'home-outline',
      name: 'addresses',
      url: '/profile-area/addresses',
    },
    {
      active: false,
      display: 'Meine Bestellungen',
      enabled: true,
      iconFilled: 'restaurant',
      iconOutline: 'restaurant-outline',
      name: 'history',
      url: '/profile-area/history',
    },
    {
      active: false,
      display: 'Gespeicherte Restaurants',
      enabled: true,
      iconFilled: 'heart',
      iconOutline: 'heart-outline',
      name: 'favGastros',
      url: '/profile-area/favGastros',
    },
    {
      active: false,
      display: 'Gutscheine',
      enabled: true,
      iconFilled: 'pricetags',
      iconOutline: 'pricetags-outline',
      name: 'coupons',
      url: '/profile-area/coupons',
    },
    {
      active: false,
      display: 'Split Rewards',
      enabled: false,
      iconFilled: 'gift',
      iconOutline: 'gift-outline',
      name: 'rewards',
      url: '/profile-area/rewards',
    },
    {
      active: false,
      display: 'Statistiken',
      enabled: false,
      iconFilled: 'stats-chart',
      iconOutline: 'stats-chart-outline',
      name: 'statistics',
      url: '/profile-area/statistics',
    },
    {
      active: false,
      display: 'Kundenkarten',
      enabled: true,
      iconFilled: 'cash',
      iconOutline: 'cash-outline',
      name: 'loyalty',
      url: '/profile-area/loyalty',
    },
    {
      active: false,
      display: 'Einstellungen',
      enabled: true,
      iconFilled: 'cog',
      iconOutline: 'cog-outline',
      name: 'settings',
      url: '/profile-area/settings',
    },

    // ,{
    //   name:"logout",
    //   display:"Gespeicherte Restaurants",
    //   active:false
    // },
  ]
  public fromHomepage = false
  public loadingWindow
  public profile = {
    addressList: <any>[],
    coupons: [],
    dateOfBirth: '',
    email: '',
    favGastros: <any>[],
    gender: '',
    hasStripeAccount: false,
    isLoggedIn: false,
    lastname: '',
    loyaltyCards: [],
    mobileNr: '',
    name: '',
    orderHistory: [],
    paymentMethods: [],
    settings: {
      autoBillAsEmail: true,
      couponAutoApply: false,
      loyaltyActive: true,
      statistics: false,
    },
    stripeUser: '',
    userID: '',
  }
  public user = {
    address: {
      city: '',
      houseNr: '',
      PLZ: '',
      street: '',
    },
    email: '',
    lastOrderedName: '',
    mobileNr: '',
    name: '',
  }

  public userID
  public username
  public email
  public exists
  public stripe
  public orderSubscription: Subscription
  public openInvoices = <any>[]
  public openInvoicesSnapshot: Subscription

  public loggingOut: EventEmitter<boolean> = new EventEmitter()

  constructor(
    public alertController: AlertController,
    public afa: AngularFireAuth,
    public toastController: ToastController,
    public db: AngularFirestore,
    public router: Router,
    public http: HttpClient,
    public gastroService: GastroService,
    public storageService: StorageService,
    public reportService: ReportService,
    private utilService: UtilService,
    private resetService: ResetService,
    public sessionDataService: SessionDataService
  ) {
    this.registerGastroReset()
    this.afa.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser != undefined && firebaseUser != null) {
        this.profile.userID = firebaseUser.uid
        this.profile.email = firebaseUser.email

        await this.fetchProfileInformation(firebaseUser.uid)
        await this.fetchLoyaltyCards(firebaseUser.uid)
        await this.fetchCoupons(firebaseUser.uid)
        await this.fetchFavGastro(firebaseUser.uid)
        this.profile.isLoggedIn = true
        this.storageService.store('isLoggedIn', true)
        this.fetchOpenInvoiceCarts(firebaseUser.uid)
      } else {
        console.log('onUathChangendFalse')
        this.openInvoicesSnapshot?.unsubscribe()
        //is the user is not logged in, reset all data
        // this.profile.isLoggedIn = false
        this.storageService.store('isLoggedIn', false)
        const user = await this.storageService.load('user')
        if (user !== undefined && user !== '' && user !== null) {
          this.user = user
        }
        //   this.profile.userID = "";
        //   this.profile.email = "";
        //   this.profile.name = "";
        //   this.openInvoices = <any>[];
      }
    })
  }

  onGastroLogout(): void {
    this.user = {
      address: {
        city: '',
        houseNr: '',
        PLZ: '',
        street: '',
      },
      email: '',
      lastOrderedName: '',
      mobileNr: '',
      name: '',
    }
  }

  registerGastroReset(): void {
    this.resetService.registerGastroReset(this)
  }

  /**
   * imporant to call before use stripe
   *  because init stripe cant be in constructor
   */
  initStripe() {
    if (this.stripe == undefined) {
      this.stripe = Stripe(environment.stripePK)
    }
  }

  /**
   * checks if the given username exists in the Vino Central database
   * @param username
   * @returns
   */
  async checkIfVinoUserExists(username) {
    let exist = false
    const data = {
      hash: '51912f25a9401200b0ce52f9152125fa',
      user: username,
    }
    const url = `${environment.functionsUrl}vinoUser`
    await $.post(url, data, (e) => {
      if (e.customer) {
        exist = true
      } else {
        exist = false
      }
    })
    return exist
  }

  /**   creates a string that fits the document name of this weeks report
   *
   */
  createWeekReportString() {
    const reportNameList = []
    const now = new Date()

    switch (now.getDay()) {
      //sunday
      case 0:
        for (let i = 6; i >= 0; i--) {
          const now = new Date()
          now.setDate(now.getDate() - i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        break
      //monday
      case 1:
        for (let i = 0; i < 7; i++) {
          const now = new Date()
          now.setDate(now.getDate() + i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        break

      //tuesday
      case 2: {
        const now = new Date()
        now.setDate(now.getDate() - 1)
        reportNameList.push(
          `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
        )

        for (let i = 0; i < 6; i++) {
          const now = new Date()
          now.setDate(now.getDate() + i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }
        break
      }

      //wednesday
      case 3:
        for (let i = 2; i > 0; i--) {
          const now = new Date()
          now.setDate(now.getDate() - i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        for (let i = 0; i < 5; i++) {
          const now = new Date()
          now.setDate(now.getDate() + i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        break

      //thursday
      case 4:
        for (let i = 3; i > 0; i--) {
          const now = new Date()
          now.setDate(now.getDate() - i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        for (let i = 0; i < 4; i++) {
          const now = new Date()
          now.setDate(now.getDate() + i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        break
      //friday
      case 5:
        for (let i = 4; i > 0; i--) {
          const now = new Date()
          now.setDate(now.getDate() - i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        for (let i = 0; i < 3; i++) {
          const now = new Date()
          now.setDate(now.getDate() + i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        break

      //saturday
      case 6:
        for (let i = 5; i > 0; i--) {
          const now = new Date()
          now.setDate(now.getDate() - i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        for (let i = 0; i < 2; i++) {
          const now = new Date()
          now.setDate(now.getDate() + i)

          reportNameList.push(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString()}-${now.getDate()}`
          )
        }

        break

      default:
        break
    }

    return reportNameList
  }

  /**
   * returns the current and last 3 weeks of weekly reports as an array
   * @returns
   */
  createLastFourWeeksReportString() {
    const reportNameList = []
    const now = new Date()
    for (let i = 3; i >= 0; i--) {
      const thisWeek = this.getWeekNumber(now)
      if (i < thisWeek) {
        reportNameList.push(`${now.getFullYear()}-KW-${thisWeek - i}`)
      } else {
        reportNameList.push('Empty')
      }
    }

    return reportNameList
  }

  /**
   * returns a fitting document name for the current yearly report
   * @returns
   */
  createYearlyReportString() {
    const reportNameList = []
    const now = new Date()
    for (let i = 0; i < 12; i++) {
      now.getMonth()
      reportNameList.push(`${now.getFullYear()}-M-${i + 1}`)
    }

    return reportNameList
  }

  /**
   * creates the chart data for the user Statistics, depending on the timespan,
   * chartData consists of the two charts and their corresponsing data
   * @param timespan
   * @returns
   */
  async createChartData(timespan) {
    const chartData = {
      orderAmountChart: <any>{ botomLabel: [], orderAmount: [] },
      orderTypeChart: <any>{},
    }
    const orderData = []
    switch (timespan) {
      case 'weekly':
        // get the last 7 reports
        chartData.orderAmountChart.bottomLabel = this.createWeekReportString()

        // fetch daily total orders
        for (const reportName of chartData.orderAmountChart.bottomLabel) {
          const report = await this.db
            .collection('customer-profiles')
            .doc(this.getUserId)
            .collection('reports')
            .doc(reportName)
            .get()
            .toPromise()

          if (report.exists) {
            chartData.orderAmountChart.orderAmount.push(
              report.data().inhouse +
                report.data().pickup +
                report.data().delivery
            )
          } else {
            chartData.orderAmountChart.orderAmount.push(0)
          }
        }

        break
      case 'monthly':
        // get the last 4 weeks reports
        chartData.orderAmountChart.bottomLabel =
          this.createLastFourWeeksReportString()

        // fetch daily total orders
        for (const reportName of chartData.orderAmountChart.bottomLabel) {
          const report = await this.db
            .collection('customer-profiles')
            .doc(this.getUserId)
            .collection('reports')
            .doc(reportName)
            .get()
            .toPromise()

          if (report.exists) {
            chartData.orderAmountChart.orderAmount.push(
              report.data().inhouse +
                report.data().pickup +
                report.data().delivery
            )
          } else {
            chartData.orderAmountChart.orderAmount.push(0)
          }
        }

        break
      case 'overall':
        // get the last 12 months reports
        //create the report-name-list?
        chartData.orderAmountChart.bottomLabel = this.createYearlyReportString()

        // fetch overall total orders
        for (const reportName of chartData.orderAmountChart.bottomLabel) {
          const report = await this.db
            .collection('customer-profiles')
            .doc(this.getUserId)
            .collection('reports')
            .doc(reportName)
            .get()
            .toPromise()

          if (report.exists) {
            chartData.orderAmountChart.orderAmount.push(
              report.data().inhouse +
                report.data().pickup +
                report.data().delivery
            )
          } else {
            chartData.orderAmountChart.orderAmount.push(0)
          }
        }
        break
      default:
        break
    }

    return chartData
  }

  /**
   * checks whether or not the user exists in the firebaseauth system under the given username
   * @param username
   * @returns
   */
  async checkUserExist(username) {
    let exist = false

    await this.afa.fetchSignInMethodsForEmail(username).then((e) => {
      if (e.length > 0) {
        exist = true
      } else {
        exist = false
      }
    })
    return exist
  }
  get getUserId() {
    return this.profile.userID
  }

  public fillInUserInfoFromProfile() {
    if (
      `${this.getFavAddress().name} ${this.getFavAddress().lastname}` !== ' ' &&
      `${this.getFavAddress().name} ${this.getFavAddress().lastname}` !==
        undefined
    ) {
      this.user.name = `${this.getFavAddress().name} ${this.getFavAddress().lastname}`
      this.sessionDataService.$nickname = `${this.getFavAddress().name} ${this.getFavAddress().lastname}`
    } else {
      this.user.name = `${this.profile.name} ${this.profile.lastname}`
      this.sessionDataService.$nickname = `${this.profile.name} ${this.profile.lastname}`
    }
    this.user.address.PLZ = this.getFavAddress().PLZ
    this.user.address.street = this.getFavAddress().street
    this.user.address.houseNr = this.getFavAddress().houseNr

    if (
      this.getFavAddress().phoneNr != undefined &&
      this.getFavAddress().phoneNr != '' &&
      this.user.mobileNr === ''
    ) {
      this.user.mobileNr = this.getFavAddress().phoneNr
    }
  }

  /**
   *  calculates the current week number with the given date
   * @param now
   * @returns
   */
  getWeekNumber(now: Date) {
    const date = now
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4)
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    )
  }

  /**
   * is given a timespan ( weekly, monthly or overall),
   * creates a fitting document ID and loads the corresponding report from db
   * @param timespan
   * @returns
   */
  public async getUserProfileReport(timespan) {
    const now = new Date()
    const year = now.getFullYear()
    let report
    switch (timespan) {
      case 'weekly': {
        const kw = this.getWeekNumber(now)

        const reportStringWeek = `${year}-KW-${kw}`
        report = await this.db
          .collection('customer-profiles')
          .doc(this.profile.userID)
          .collection('reports')
          .doc(reportStringWeek)
          .get()
          .toPromise()
        if (report.exists) {
          return report.data()
        } else {
          // return {
          //   totalCost: 0,
          //   pickup: 0,
          //   delivery:0,
          //   inhouse:0,
          //   gastroCounter:[]
          // };
        }

        break
      }
      case 'monthly': {
        const reportStringMonth = `${now.getFullYear()}-M-${now.getMonth() + 1}`
        report = await this.db
          .collection('customer-profiles')
          .doc(this.profile.userID)
          .collection('reports')
          .doc(reportStringMonth)
          .get()
          .toPromise()
        if (report.exists) {
          return report.data()
        } else {
          // return {
          //   totalCost: 0,
          //   pickup: 0,
          //   delivery:0,
          //   inhouse:0,
          //   gastroCounter:[]
          // };
        }
        break
      }
      case 'overall':
        report = await this.db
          .collection('customer-profiles')
          .doc(this.profile.userID)
          .collection('reports')
          .doc('overall')
          .get()
          .toPromise()
        if (report.exists) {
          return report.data()
        } else {
          // return {
          //   totalCost: 0,
          //   pickup: 0,
          //   delivery:0,
          //   inhouse:0,
          //   gastroCounter:[]
          // };
        }
        break
      default:
        break
    }
    return undefined
  }

  /**
   * grabs the user data from the db with the given userID,
   *  fills in any undefined attributes,
   *  after fetching all data, refreshes the local user object
   * @param profileID
   */
  async fetchProfileInformation(profileID) {
    this.profile.userID = profileID
    this.profile.isLoggedIn = true
    if (this.profile.userID != undefined) {
      const userRef: any = await this.db
        .collection('customer-profiles')
        .doc(profileID)
        .get()
        .toPromise()

      if (userRef.exists) {
        const userPrivateRef: any = await this.db
          .collection('customer-profiles')
          .doc(profileID)
          .collection('private')
          .doc('private')
          .get()
          .toPromise()
        if (
          userPrivateRef?.data()?.name != undefined &&
          userPrivateRef?.data()?.name != ''
        ) {
          this.profile.name = userPrivateRef.data().name
        } else if (userRef.data().name?.includes('@')) {
          //this is for vino users -> don't remove
          this.profile.name = userRef.data().name
        } else {
          this.profile.name = ''
        }
        if (userPrivateRef?.data()?.lastname != undefined) {
          this.profile.lastname = userPrivateRef.data().lastname
        } else {
          this.profile.lastname = ''
        }
        if (userRef.data().email != undefined) {
          this.profile.email = userRef.data().email
        } else {
          this.profile.email = ''
        }
        if (userPrivateRef?.data()?.dateOfBirth != undefined) {
          this.profile.dateOfBirth = userPrivateRef.data().dateOfBirth
        } else {
          this.profile.dateOfBirth = ''
        }
        if (userPrivateRef.data()?.gender != undefined) {
          this.profile.gender = userPrivateRef.data()?.gender
        } else {
          this.profile.gender = ''
        }
        if (userPrivateRef?.data()?.mobileNr != undefined) {
          this.profile.mobileNr = userPrivateRef.data().mobileNr
        } else {
          this.profile.mobileNr = ''
        }
        if (userRef.data().paymentMethods != undefined) {
          this.profile.paymentMethods = userRef.data().paymentMethods
        } else {
          this.profile.paymentMethods = []
        }
        if (userRef.data().hasStripeAccount != undefined) {
          this.profile.hasStripeAccount = userRef.data().hasStripeAccount
        } else {
          this.profile.hasStripeAccount = false
        }
        if (userRef.data().stripeUser != undefined) {
          this.profile.stripeUser = userRef.data().stripeUser
        } else {
          this.profile.stripeUser = ''
        }

        //apply settings
        this.applySettings(userRef.data().settings)
        await this.fetchLoyaltyCards(profileID)
        await this.fetchCoupons(profileID)
        await this.fetchFavGastro(profileID)
        await this.fetchAddressList(profileID)

        this.applySettings(userRef.data().settings)
        this.updateOrderHistory()
        if (this.profile.hasStripeAccount) {
          this.fetchPaymentMethods(profileID)
        }
      }
    }
  }

  /**
   *  sets the logged in flag for the given userID in the db
   * @param userID
   */
  async setLoggedInStatus(userID) {
    this.db
      .collection('customer-profiles')
      .doc(userID)
      .set({ isLoggedIn: true }, { merge: true })
  }

  /**
   * calls the fetch method for orderhistory and awaits the result
   */
  async updateOrderHistory() {
    this.profile.orderHistory = await this.fetchOrderHistory(
      this.profile.userID
    )
  }

  /** gets the collection FavGastro from the db under the current userID and loads it into the current user object
   *  @param
   *
   */
  async fetchFavGastro(userID) {
    this.profile.favGastros = []
    const favGastrosRef = await this.db
      .collection('customer-profiles')
      .doc(userID)
      .collection('favGastros')
      .get()
      .toPromise()
    for (const favGastro of favGastrosRef.docs) {
      const gastro = favGastro.data()
      this.profile.favGastros.push(gastro)
    }
  }

  public async getName(gastroId = '', isDelivery: boolean): Promise<string> {
    let name = this.user.name

    // if (this.gastroService.$gastro.nicknameDB !== undefined || this.gastroService.$gastro.nicknameDB !== "") {

    // }

    if (name === '' || name === undefined) {
      name = await this.utilService.nicknameGenerator(gastroId)
      this.user.name = name
      this.user.lastOrderedName = name
      this.storageService.store('user', this.user)
    }

    return name
  }
  /** gets the collection address-list from the db under the current userID and loads it into the current user object
   *  @param
   *
   */
  async fetchAddressList(userID) {
    this.profile.addressList = []
    const addressListRef = await this.db
      .collection('customer-profiles')
      .doc(userID)
      .collection('address-list')
      .get()
      .toPromise()
    this.profile.addressList = []
    for (const addressDoc of addressListRef.docs) {
      const address = addressDoc.data()
      this.profile.addressList.push(address)
    }
  }

  /**
   * gets the loyaltyCards saved under the given UserID.
   * These are then stored under the User-Object and can be accesed through it
   * @param userID
   */
  async fetchLoyaltyCards(userID) {
    this.profile.loyaltyCards = []
    const loyaltyRefs = await this.db
      .collection('customer-profiles')
      .doc(userID)
      .collection('loyalties')
      .get()
      .toPromise()
    this.profile.loyaltyCards = []
    const tempLoyaltyCards = []
    for (const loyaltyCard of loyaltyRefs.docs) {
      const loyaltyCardData = loyaltyCard.data()
      loyaltyCardData.id = loyaltyCard.id
      const tmpGastro: any = await this.getGastroFromID(
        loyaltyCardData.gastroID
      ) // TODO typisieren
      loyaltyCardData.gastro = tmpGastro
      if (loyaltyCardData.loyaltyType == tmpGastro.loyaltyCard.loyaltyType) {
        loyaltyCardData.gastroActive = tmpGastro.loyaltyCard.active
      } else {
        loyaltyCardData.gastroActive = false
      }
      this.db
        .collection('customer-profiles')
        .doc(userID)
        .collection('loyalties')
        .doc(`${loyaltyCardData.gastroID}-${loyaltyCardData.loyaltyType}`)
        .update({
          gastroActive: loyaltyCardData.gastroActive,
        })
      tempLoyaltyCards.push(loyaltyCardData)
    }
    this.profile.loyaltyCards = tempLoyaltyCards
  }
  /**
   * deletes a db entry for the given loyaltyCard id and removes it from the local object
   *  @param loyaltyCardID
   */
  async deleteLoyaltyCard(loyaltyCardID) {
    const confirm = await this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('loyalties')
      .doc(loyaltyCardID)
      .delete()

    for (const loyaltyCard of this.profile.loyaltyCards) {
      if (loyaltyCard.id == loyaltyCardID) {
        this.profile.loyaltyCards.splice(
          this.profile.loyaltyCards.indexOf(loyaltyCard),
          1
        )
      }
    }
    return confirm
  }

  /**
   * This function fetch coupons saved under the given UserID.
   * These are then stored under the User-Object and can be accesed through it.
   * It also convert timestamp to locale date (createdAt attribute)
   * and then sort based on gastro name and reward magnitude.
   * @param userID
   */
  async fetchCoupons(userID) {
    const couponRefs = await this.db
      .collection('customer-profiles')
      .doc(userID)
      .collection('coupons')
      .get()
      .toPromise()
    const dateOptions = {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      second: 'numeric',
      weekday: 'short',
      year: 'numeric',
    }
    this.profile.coupons = []
    const tempCoupons = []
    for (const coupon of couponRefs.docs) {
      let couponData = coupon.data()

      //fetch coupon data if coupon is not personalizec (coupon that generated by gastro, not from loyalty card)
      if (couponData.personalized == false) {
        const nonPersonalizedCoupon = await this.db
          .collection('coupons', (ref) =>
            ref
              .where('couponCode', '==', couponData.couponCode)
              .where('gastroID', '==', couponData.gastroID)
          )
          .get()
          .toPromise()
        if (!nonPersonalizedCoupon.empty) {
          const nonPersonalizedCouponData = nonPersonalizedCoupon.docs[0].data()
          //merge 2 object with spread operator
          couponData = {
            ...couponData,
            // eslint-disable-next-line @typescript-eslint/ban-types
            ...(nonPersonalizedCouponData as Object),
          }
          couponData.valid = true
        } else {
          couponData.valid = false
        }
      }

      //check if all coupons still valid
      const now = new Date().getTime()
      if (
        couponData.numberOfUses != undefined &&
        (couponData.numberOfUses > 0 || couponData.numberOfUses == 'none')
      ) {
        if (couponData.expireAt != undefined && couponData.expireAt == 'none') {
          couponData.valid = true
        } else if (
          couponData.expireAt != undefined &&
          couponData.expireAt.toDate().getTime() > now &&
          couponData.validFrom.toDate().getTime() < now
        ) {
          couponData.valid = true
        } else {
          couponData.valid = false
        }
      } else {
        couponData.valid = false
      }

      const tmpGastro = await this.getGastroFromID(couponData.gastroID)
      couponData.gastro = tmpGastro
      if (couponData.createdAt != undefined) {
        couponData.createdAt = couponData.createdAt
          .toDate()
          .toLocaleDateString('de-DE', dateOptions)
      }
      tempCoupons.push(couponData)
    }

    this.profile.coupons = tempCoupons
    this.profile.coupons.sort((a, b) => {
      return (
        a.gastro?.name.localeCompare(b.gastro?.name) ||
        a.rewardMagnitude - b.rewardMagnitude
      )
    })
  }

  /**
   * fetches the gastro object from the db with the given gastroID
   * @param gastroID
   * @returns
   */
  async getGastroFromID(gastroID) {
    const gastro = await this.db
      .collection('gastro')
      .doc(gastroID)
      .get()
      .toPromise()
    if (gastro.exists) {
      if (gastro.data() != undefined) {
        return gastro.data()
      }
    }
    return undefined
  }

  /**
   * returns the address saved as favourite of this user, if there is none, it returns an empty address
   * @returns
   */

  getFavAddress() {
    let tmpAddress = <any>{
      city: '',
      fav: true,
      houseNr: '',
      lastname: '',
      name: '',
      nickname: '',
      phoneNr: '',
      PLZ: '',
      street: '',
    }
    if (
      this.profile.addressList !== undefined &&
      this.profile.addressList.length !== 0
    ) {
      for (const address of this.profile.addressList) {
        if (address.fav == true) {
          tmpAddress = address
        }
      }
    }

    return tmpAddress
  }

  /**
   * check if the given gastroID is saved as a favourite for this user
   * @param gastroID
   * @returns
   */
  isFavGastro(gastroID) {
    if (
      this.profile.favGastros === undefined ||
      this.profile.favGastros?.length === 0
    ) {
      return false
    } else {
      for (const favGastro of this.profile.favGastros) {
        if (favGastro.id == gastroID) {
          return true
        }
      }
      return false
    }
  }
  /** gets the last 20 orders for the given userID
   *
   * @param userID id of the user the history should be fetched for
   */
  async fetchOrderHistory(userID) {
    const orders = await this.db
      .collection('customer-profiles')
      .doc(userID)
      .collection('orders', (ref) => ref.orderBy('createdAt', 'desc').limit(20))
      .get()
      .toPromise()
    let ordersByCart = []
    const ret = []
    if (!orders.empty) {
      ordersByCart = this.groupOrdersCart(orders)
      for (const [orderKey, order] of Object.entries(ordersByCart)) {
        const gastro = await this.db
          .collection<Gastro>('gastro')
          .doc(order.gastroID)
          .get()
          .toPromise()
        ordersByCart[orderKey].img = gastro.data().logoImage
        ret.push(ordersByCart[orderKey])
      }
    }
    return ret
  }

  /**
   * groups all orders by cart
   * @param orders all orders which should be grouped
   */
  groupOrdersCart(
    orders: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) {
    const carts: any[] = []
    orders.forEach((order) => {
      if (carts[order.data().cartID] == undefined) {
        const tempDate = order.data().createdAt.toDate()
        const date = `${this.addZeroToDate(tempDate.getDate())}
				.${this.addZeroToDate(tempDate.getMonth() + 1)}.${tempDate.getFullYear()}`
        carts[order.data().cartID] = {
          bigExpand: false,
          cartID: order.data().cartID,
          date: date,
          gastroID: order.data().gastroID,
          img: '',
          miniExpand: false,
          mobilePayed: order.data().mobilePayed,
          orderNr: order.data().code,
          showDeliveryDetails: false,
        }
      }
    })
    return carts
  }

  /**
   * get all orders of a cart
   * @param id cartid of the orders that should be fetched
   */
  async loadCart(
    id: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) {
    const orders = await this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('orders', (ref) => ref.where('cartID', '==', id))
      .get()
      .toPromise()
    return orders
  }

  /**
   * adds a 0 prefix to the given date if its below 10
   * @param date
   * @returns
   */
  addZeroToDate(date: number) {
    if (date < 9) {
      return `0${date}`
    }
    return date
  }
  /**
   * with the given email and password, logs the user into the firebaseAuth system.
   * Sets logIn persistance depending on the persist flag.
   * does not allow gastro account log in.
   * after succesfull log in, calls the function to fetch all relevant user info into the local object
   * @param email
   * @param password
   * @param persist
   * @returns true
   * @throws errorCode of firebase
   */
  async signIn(email: string, password: string, persist: boolean) {
    let errorCode
    const gastroProfile = await this.db
      .collection('Gastro-Profiles', (ref) => ref.where('mail', '==', email))
      .get()
      .toPromise()
    if (!gastroProfile.empty) {
      errorCode = 'auth/invalid-email'
      throw errorCode
    }

    if (persist == true) {
      await this.afa.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      try {
        const result = <any>(
          await this.afa.signInWithEmailAndPassword(email, password)
        )
        await this.fetchProfileInformation(result.user.uid)
      } catch (error) {
        errorCode = error.code
      }

      if (errorCode != null) {
        throw errorCode
      } else {
        return true
      }
    } else {
      await this.afa.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      try {
        const result = <any>(
          await this.afa.signInWithEmailAndPassword(email, password)
        )
        await this.fetchProfileInformation(result.user.uid)
      } catch (error) {
        errorCode = error.code
      }
      if (errorCode != null) {
        throw errorCode
      } else {
        return true
      }
    }
  }

  /** creates a firebaseAuth account with the given email and password.
   * Then sets a profile in the db with the name, lastname, email, time of creation and stripeaccountID
   * lastly it refreshes the local user object with the new information
   *
   * @param name
   * @param lastname
   * @param email
   * @param password
   * @param vino true if this one is a vino user
   * @returns
   */
  async register(name, lastname, email, password, vino = false) {
    let errorCode
    try {
      const result = <any>(
        await this.afa
          .createUserWithEmailAndPassword(email, password)
          .then((u) => {
            this.createUser(name, lastname, email, u.user, vino)
            this.reportService.increaseRegisterCounter()
          })
      )
    } catch (error) {
      errorCode = error.code
    }
    if (errorCode != null) {
      return errorCode
    } else {
      return true
    }
  }

  private createUser(name, lastname, email, user, vino) {
    this.profile.userID = user.uid
    this.profile.name = name
    this.createNewStripeAccount(email).then((stripeUser) => {
      if (vino == true) {
        this.db
          .collection('customer-profiles')
          .doc(user.uid)
          .set(
            {
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              email: email,
              hasStripeAccount: true,
              password: firebase.firestore.FieldValue.delete(),
              stripeUser: stripeUser.customer,
              vinoUser: true,
            },
            { merge: true }
          )
          .then(async (e) => {
            await this.db
              .collection('customer-profiles')
              .doc(user.uid)
              .collection('private')
              .doc('private')
              .set({
                name: name,
                lastname: lastname,
              })
            this.fetchProfileInformation(user.uid)
          })
      } else {
        this.db
          .collection('customer-profiles')
          .doc(user.uid)
          .set(
            {
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              email: email,
              hasStripeAccount: true,
              stripeUser: stripeUser.customer,
            },
            { merge: true }
          )
          .then(async (e) => {
            await this.db
              .collection('customer-profiles')
              .doc(user.uid)
              .collection('private')
              .doc('private')
              .set({
                lastname: lastname,
                name: name,
              })
            this.fetchProfileInformation(user.uid)
          })
      }
    })
  }

  /** creates an account on the stripe platform with the given email address
   *
   * @param email
   * @returns
   */
  async createNewStripeAccount(email) {
    const url = `${environment.functionsUrl}createCustomerProfiles`
    return $.post(url, { mail: email }, async (stripeUser) => {})
  }

  /** for the given userID, gives the user the attribute hasStripeAccount and save the StripeID in our db
   *
   * @param userID
   * @param stripeUser
   * @returns
   */

  async setStripeAccountFlag(userID, stripeUser) {
    return this.db.collection('customer-profiles').doc(userID).set(
      {
        stripeUser: stripeUser.customer,
        hasStripeAccount: true,
      },
      { merge: true }
    )
  }

  /**
   * sorts all payment Method so that the favourite one is the first element of the paymentMethods array
   *
   */
  public sortPaymentMethods() {
    const tmpList = []
    for (const element of this.profile.paymentMethods) {
      if (element.fav) {
        tmpList.unshift(element)
      } else {
        tmpList.push(element)
      }
    }
    this.profile.paymentMethods = tmpList
  }

  /**
   * resets all information associated with the profile object
   */
  private resetUser() {
    console.log('resetUser')
    this.profile = {
      addressList: [],
      coupons: [],
      dateOfBirth: null,
      email: '',
      favGastros: [],
      gender: '',
      hasStripeAccount: false,
      isLoggedIn: false,
      lastname: '',
      loyaltyCards: [],
      mobileNr: '',
      name: '',
      orderHistory: [],
      paymentMethods: [],
      settings: {
        autoBillAsEmail: false,
        couponAutoApply: false,
        loyaltyActive: true,
        statistics: false,
      },
      stripeUser: '',
      userID: '',
    }
  }

  /**
   * logs the user out of firebaseAuth and resets all local data with resetUser()
   */
  async signOut() {
    this.profile.isLoggedIn = false
    await this.saveProfileInfo()
    this.resetUser()
    this.openInvoicesSnapshot?.unsubscribe()
    this.loggingOut.emit(true)
    this.afa.signOut()
  }

  /**
   * is given an userID in order to look for any orders that have not been payed and orders them into carts
   * @param userID
   */
  async fetchOpenInvoiceCarts(userID) {
    if (this.profile.userID != undefined && this.profile.isLoggedIn == true) {
      this.openInvoicesSnapshot = this.db
        .collection('customer-profiles')
        .doc(userID)
        .collection('orders', (ref) => ref.where('payed', '==', 0))
        .snapshotChanges()
        .subscribe((openInvoices) => {
          this.openInvoices = []
          openInvoices
            .map((gastro) => gastro.payload.doc)
            .forEach((invoice) => {
              this.groupOrdersIntoArray(invoice.data(), this.openInvoices).then(
                (e) => {}
              )
            })
          this.getGastrosForOrders(this.openInvoices)
        })
    }
  }

  /**
   * saves the current userObject to the db and overwrites any changes
   *
   */
  async saveProfileInfo() {
    //maybe save info before logging out?
    const profile = JSON.parse(JSON.stringify(this.profile))

    delete this.profile.coupons
    delete this.profile.orderHistory
    delete this.profile.loyaltyCards
    delete this.profile.paymentMethods
    delete this.profile.favGastros
    delete this.profile.addressList

    await this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('private')
      .doc('private')
      .set(
        {
          dateOfBirth: this.profile.dateOfBirth,
          gender: this.profile.gender,
          lastname: this.profile.lastname,
          mobileNr: this.profile.mobileNr,
          name: this.profile.name,
        },
        { merge: true }
      )
    delete this.profile.dateOfBirth
    delete this.profile.mobileNr
    delete this.profile.name
    delete this.profile.lastname
    await this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .set(this.profile, { merge: true })
    this.profile = profile
    return true
  }

  /**
   * is given an array of orders and appends to each order a GastroName based on the gastro ID of that order
   * @param arr
   */
  getGastrosForOrders(arr) {
    arr.forEach(async (e) => {
      const doc = await this.db
        .collection<Gastro>('gastro')
        .doc(e.key)
        .get()
        .toPromise()
      e.data.gastroName = doc.data().name
      // this.db.collection('gastro').doc(e.key).get().subscribe((doc) => {
      //   e.data.gastroName = doc.data().name
      // })
    })
  }

  /**
   * is given a document with orders and sorts them by gastroID into the given array
   * i.e. [[all orders from gastroID_1],[all orders from gastroID_2],...]
   * @param doc
   * @param arr
   */
  async groupOrdersIntoArray(doc, arr) {
    const inArray = arr.filter((item) => {
      return item.key == doc.gastroID // look for the item where ID is equal to value
    })
    const index = arr.indexOf(inArray[0])
    if (index != -1) {
      arr[index].data.push({
        id: doc.id,
        data: doc,
      })
    } else {
      arr.push({
        key: doc.gastroID,
        data: [
          {
            id: doc.id,
            data: doc,
          },
        ],
      })
    }
  }

  /**
	 * loads the paymentmethods from the stripe account and updates the local paymentMethods object
  
	 * @param stripeID 
	 * @returns 
	 */
  async fetchPaymentMethods(stripeID) {
    const url3 = `${environment.functionsUrl}getPaymentMethodsProfiles`
    return $.post(url3, { customerID: this.profile.stripeUser }, async (p) => {
      this.profile.paymentMethods = []
      for (const element of p.data) {
        if (element.metadata.fav != undefined) {
          if (element.metadata.fav == 'true') {
            this.profile.paymentMethods.push({
              brand: element.card.brand,
              expMonth: element.card.exp_month,
              expYear: element.card.exp_year,
              fav: true,
              lastFour: element.card.last4,
              paymentID: element.id,
              type: this.filterPaymentType(element.type),
            })
          } else {
            this.profile.paymentMethods.push({
              brand: element.card.brand,
              expMonth: element.card.exp_month,
              expYear: element.card.exp_year,
              fav: false,
              lastFour: element.card.last4,
              paymentID: element.id,
              type: this.filterPaymentType(element.type),
            })
          }
        } else {
          this.profile.paymentMethods.push({
            brand: element.card.brand,
            expMonth: element.card.exp_month,
            expYear: element.card.exp_year,
            fav: false,
            lastFour: element.card.last4,
            paymentID: element.id,
            type: this.filterPaymentType(element.type),
          })
        }
      }
      this.sortPaymentMethods()
    })
  }

  /** crutch method to change cart.page payment type names to stripe payment type names
   */
  public filterPaymentType(type) {
    if (type == 'card') {
      return 'credit'
    }
    return undefined
  }

  /** fetches the paymentMethod that is marked as fav==true
   * @returns
   */
  public getFavPaymentMethod() {
    for (const element of this.profile.paymentMethods) {
      if (element.fav == true) {
        return element
      } else {
        return false
      }
    }
    return false
  }

  /** is called when a user toggles the favourite icon on a payment Method,
   * first it creates a loading window so the user cannot interact with the page further,
   * afterwards it dismisses it and shows a success toast
   *  @param customerID
   *  @param cardID
   */
  public async toggleFavPaymentMethod(customerID, cardID) {
    this.loadingWindow.present()
    await this.selectFavPaymentMethod(customerID, cardID).then(async (e) => {
      await this.fetchPaymentMethods(customerID).then((i) => {
        this.loadingWindow.dismiss()
        this.successAlert(
          'Die Bezahlmethode wurde erfolgreich als Favorit gesetzt.'
        )
      })
    })
  }

  /** is called to toggle the fav attribute of a paymentMethod
   *  fetches all paymentMethod of a user first,
   *  then searches for the correct one and toggles the metadata Attribute "fav"
   *  @param customerID
   *  @param cardID
   */
  async selectFavPaymentMethod(customerID, cardID) {
    const url3 = `${environment.functionsUrl}getPaymentMethodsProfiles`
    const url4 = `${environment.functionsUrl}updatePaymentMethodProfiles`

    let tmpList
    await $.post(url3, { customerID: customerID }, async (p) => {
      tmpList = await p.data
      return tmpList
    }).then(async (tmpList2) => {
      let selectedFav
      for (const element of await tmpList2.data) {
        if (element.id == cardID) {
          if (element.metadata.fav == 'true') {
            selectedFav = await $.post(url4, {
              cardID: element.id,
              update: {
                metadata: {
                  fav: 'false',
                },
              },
            })
          } else {
            selectedFav = await $.post(url4, {
              cardID: element.id,
              update: {
                metadata: {
                  fav: 'true',
                },
              },
            })
          }
        } else {
          await $.post(url4, {
            cardID: element.id,
            update: { metadata: { fav: 'false' } },
          })
        }
      }
      return await selectedFav
    })
  }

  /** ToDo used to update the information of a paymentMethod.
   * In the future this should be made generic in roder to cover most paymentMethods
   * cannot be used with Stripe Cards, since the card information cannot be modified,
   * so instead the user must use deletePaymentMethod and addPaymentMethod
   *  @param customer
   * @param cardID
   * @param changes
   */
  async updatePaymentMethod(customer, cardID, changes) {}

  /** is given a cardID in order to delete the card from the users Stripe Account.
   *  calls the environment function "detachPaymnetMethodProfiles"
   *  After deleting, it refreshes the payment methods
   *  @param cardID
   *
   */
  async deletePaymentMethod(cardID) {
    this.loadingWindow.present()
    const url = `${environment.functionsUrl}detachPaymentMethodProfiles`
    return await $.post(url, { cardID: cardID }, async (p) => {
      this.loadingWindow.dismiss()
      return this.fetchPaymentMethods(this.profile.stripeUser)
    })
  }

  /** sets the fav flag of an address in the local object and in db.
   *  Only requires the nickname in order to search the entry
   *  @param addressNickname
   */
  async toggleFavAddress(addressNickname) {
    this.profile.addressList.forEach((element) => {
      element.fav = false
      this.profile.addressList.forEach((element2) => {
        if (element2.nickname == addressNickname) {
          element2.fav = true
        }
      })
    })
    for (const address of this.profile.addressList) {
      await this.db
        .collection('customer-profiles')
        .doc(this.profile.userID)
        .collection('address-list')
        .doc(address.nickname)
        .set({ fav: address.fav }, { merge: true })
    }

    this.successAlert(
      `Die Adresse <b>${addressNickname}</b> wurde erfolgreich als Favorit gesetzt`
    )
  }

  /**   is given edit to overwrite addressToBeEdited in local object and in db, and creates a success toast
   *  @param addressToBeEdited
   *  @param edit
   *
   */

  async editAddress(addressToBeEdited, edit) {
    this.profile.addressList[
      this.profile.addressList.indexOf(addressToBeEdited)
    ] = edit
    return this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('address-list')
      .doc(addressToBeEdited.nickname)
      .set(edit, { merge: true })
      .then(() => {
        this.successAlert('Die Änderungen wurden erfolgreich gespeichert')
      })
  }

  /**   adds  a fav gastroEntry to the local user obejct and the db
   *  @param gastro
   *
   */
  async addFavGastro(gastro) {
    this.profile.favGastros.push(gastro)
    return await this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('favGastros')
      .doc(gastro.id)
      .set(gastro)
  }
  /**   deletes  a fav gastroEntry from the local user obejct and the db
   *  @param gastroID
   */
  async deleteFavGastro(gastroID) {
    for (const favGastro of this.profile.favGastros) {
      if (favGastro.id === gastroID) {
        this.profile.favGastros.splice(
          this.profile.favGastros.indexOf(favGastro),
          1
        )
      }
    }
    await this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('favGastros')
      .doc(gastroID)
      .delete()
  }

  /**   adds an address object to the local list and the db and creates an success Alert
   *  @param address
   */
  async addAddress(address) {
    if (address.fav) {
      this.toggleFavAddress(address.nickname)
    }
    if (address.nickname == '' || address.nickname == undefined) {
      address.nickname = 'Hauptadresse'
    }
    this.profile.addressList.push(address)
    await this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('address-list')
      .doc(address.nickname)
      .set(address)
      .then(() => {
        this.successAlert('Neue Adresse wurde erfolgreich gespeichert')
      })
    return true
  }

  /**   deletes the an address specified by a nickname, deletes it locally and from the db
   *  @param addressNickname
   */

  async deleteAddress(addressNickname) {
    // this.db.collection('customer-profiles').doc(this.user.userID).get().subscribe((user)=>{
    // let tmpAddressList = user.data().addressList
    for (const address of this.profile.addressList) {
      if (address.nickname == addressNickname) {
        this.profile.addressList.splice(
          this.profile.addressList.indexOf(address),
          1
        )
        if (address.fav) {
          this.profile.addressList[0].fav == true
        }
      }
    }
    this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .collection('address-list')
      .doc(addressNickname)
      .delete()
      .then(() => {
        this.successAlert('Die Adresse wurde erfolgreich gelöscht')
      })
    // })
  }

  /**
   * Check if an address nickname is unique.
   *
   * @param nickname - nickname of address to be checked
   * @returns
   */
  checkIfAddressUnique(nickname): boolean {
    const foundIndex = this.profile.addressList.findIndex(
      (address) => address.nickname == nickname
    )
    if (foundIndex == -1) {
      return true
    } else {
      return false
    }
  }
  /**   sends a password reset mail to the specified email address
   *  @param userMail
   */
  async resetUserPswd(userMail) {
    return this.afa.sendPasswordResetEmail(userMail)
  }
  /**
   * auxiliary function to get the age of a profile from the given dateOfBirth
   */

  getAge() {
    const birthdayAsDate = new Date(this.profile.dateOfBirth)
    if (birthdayAsDate === null) {
      const defaultDate = new Date()
      return defaultDate.getFullYear()
    }
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()
    const currentDay = currentDate.getDate()
    let calculatedAge = currentYear - birthdayAsDate.getFullYear()

    if (currentMonth < birthdayAsDate.getMonth() - 1) {
      calculatedAge--
    }
    if (
      birthdayAsDate.getMonth() - 1 == currentMonth &&
      currentDay < birthdayAsDate.getDate()
    ) {
      calculatedAge--
    }
    return calculatedAge
  }

  /**
   * Update Database and apply new settings
   */
  async updateSettings() {
    this.db
      .collection('customer-profiles')
      .doc(this.profile.userID)
      .set(
        {
          settings: this.profile.settings,
        },
        { merge: true }
      )
      .then(() => {
        this.successAlert('Änderungen erfolgreich gespeichert')
        this.applySettings(this.profile.settings)
      })
      .catch((error) => {
        console.error(error)
        this.failedAlert('Änderungen konnten nicht gesichert werden.')
      })
  }

  /**   applies specific settings that are saved under a user
   *  @param settings
   */

  applySettings(settings) {
    //Hide and show "Statistics Page"
    const foundIndex = this.profileAreaTabs.findIndex(
      (elem) => elem.name == 'statistics'
    )
    this.profileAreaTabs[foundIndex].enabled = this.profile.settings.statistics

    //Settings
    if (settings != undefined) {
      //Statistic
      if (settings.statistics != undefined) {
        this.profile.settings.statistics = settings.statistics
      } else {
        this.profile.settings.statistics = false
      }
      //Kundenkarten aktiv
      if (settings.loyaltyActive != undefined) {
        this.profile.settings.loyaltyActive = settings.loyaltyActive
      } else {
        this.profile.settings.loyaltyActive = true
      }
      //Coupon Auto Apply
      if (settings.couponAutoApply != undefined) {
        this.profile.settings.couponAutoApply = settings.couponAutoApply
      } else {
        this.profile.settings.couponAutoApply = false
      }
    } else {
      this.profile.settings = {
        autoBillAsEmail: true,
        couponAutoApply: false,
        loyaltyActive: true,
        statistics: false,
      }
    }
  }

  /**   parses an errorCode in order to notify the user on the log-in/register error
   *  @param errorCode
   *  @return
   */

  authErrorsParser(errorCode: string): string {
    let message: string

    switch (errorCode) {
      case 'auth/wrong-password':
        // message = 'Invalid login credentials.';
        message = 'Dein Passwort stimmt nicht überein!'
        break
      case 'auth/network-request-failed':
        // message = 'Please check your internet connection';
        message = 'Bitte überprüfen Sie Ihre Internetverbindung!'
        break
      case 'auth/too-many-requests':
        // message =
        //   'We have detected too many requests from your device. Take a break please!';
        message =
          'Wir haben zu viele Anfragen von Ihrem Gerät erkannt. Bitte machen Sie eine Pause!'
        break
      case 'auth/user-disabled':
        // message =
        //   'Your account has been disabled or deleted. Please contact the system administrator.';
        message =
          'Ihr Konto wurde deaktiviert oder gelöscht. Bitte wenden Sie sich an den Systemadministrator!'
        break
      case 'auth/requires-recent-login':
        // message = 'Please login again and try again!';
        message =
          'Bitte melden Sie sich erneut an und versuchen Sie es nochmal!'
        break
      case 'auth/email-already-exists':
        // message = 'Email address is already in use by an existing user.';
        message =
          'Die E-Mail-Adresse wird bereits von einem bestehenden Benutzer verwendet!'
        break
      case 'auth/email-already-in-use':
        // message = 'Email address is already in use by an existing user.';
        message =
          'Die E-Mail-Adresse wird bereits von einem bestehenden Benutzer verwendet!'
        break
      case 'auth/user-not-found':
        // message =
        //   'We could not find user account associated with the email address or phone number.';
        message = `Wir konnten kein Benutzerkonto finden, 
			dass mit der E-Mail-Adresse oder Telefonnummer verbunden ist!`
        break
      case 'auth/phone-number-already-exists':
        // message = 'The phone number is already in use by an existing user.';
        message =
          'Die Telefonnummer wird bereits von einem bestehenden Benutzer verwendet!'
        break
      case 'auth/invalid-phone-number':
        // message = 'The phone number is not a valid phone number!';
        message = 'Die Telefonnummer ist keine gültige Telefonnummer!'
        break
      case 'auth/invalid-email':
        // message = 'The email address is not a valid email address!';
        message = 'Keine gültige E-Mail Adresse!'
        break
      case 'auth/cannot-delete-own-user-account':
        // message = 'You cannot delete your own user account.';
        message = 'Sie können Ihr eigenes Benutzerkonto nicht löschen!'
        break
      default:
        message = 'Oops! Something went wrong. Try again later.'
        break
    }

    return message
  }

  /**   creates a success Toas with a specified message
   *  @param message
   */
  private async successAlert(message: string) {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 3000,
      message: message,
      position: 'bottom',
    })
    toast.present()
  }
  /**   creates a fail Toast with a specified message
   *  @param message
   */
  private async failedAlert(message: string) {
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 3000,
      message: message,
      position: 'bottom',
    })
    toast.present()
  }

  /**
   * returns the loyaltyCards that are saved on the current user object
   * @returns
   */
  getLoyaltyCards() {
    return this.profile.loyaltyCards
  }

  /**
   * returns the loyaltyCards that are saved on the current user object and are active
   * @returns array of active Cards
   */
  getActiveLoyaltyCards() {
    const activeCards = []
    this.profile.loyaltyCards.forEach((card) => {
      if (card.gastroActive && card.profileActive) {
        activeCards.push(card)
      }
    })
    return activeCards
  }

  /**
   * returns the loyaltyCards that are saved on the current user object but are inactive
   * @returns array of inactive Cards
   */
  getInactiveLoyaltyCards() {
    const inactiveCards = []
    this.profile.loyaltyCards.forEach((card) => {
      if (!card.gastroActive || !card.profileActive) {
        inactiveCards.push(card)
      }
    })
    return inactiveCards
  }

  hasActiveSplitometerOfCurrentGastro(gastroId) {
    let hasSplitometer = false
    for (const loyaltyCard of this.getLoyaltyCards()) {
      if (
        loyaltyCard.loyaltyType == 'splitometer' &&
        loyaltyCard.gastroID == gastroId &&
        loyaltyCard.active == true
      ) {
        hasSplitometer = true
      }
    }
    return hasSplitometer
  }
  hasActivePointCardOfCurrentGastro(gastroId) {
    let hasSplitometer = false
    for (const loyaltyCard of this.getLoyaltyCards()) {
      if (
        loyaltyCard.loyaltyType == 'standard' &&
        loyaltyCard.gastroID == gastroId &&
        loyaltyCard.active == true
      ) {
        hasSplitometer = true
      }
    }
    return hasSplitometer
  }

  hasActiveLoyaltyCardOfGastro(gastroId) {
    if (
      this.hasActiveSplitometerOfCurrentGastro(gastroId) ||
      this.hasActivePointCardOfCurrentGastro(gastroId)
    ) {
      return true
    } else {
      return false
    }
  }

  /**
   * changes the password of the loggedin user - returns error alters if there are any
   * password length is minimum of 6 characters
   *  @param oldPW - old password of the user
   *  @param newPW - new password
   *  @param message - confirmation of the new password
   *  @param vino - this one is for the special vino case 0 - means no vino user,
   *                1 - means vino user old without stripe account
   *                2 - means vino user new with stripe acc
   */
  async changePW(oldPW: string, newPW: string, newPWConfirm: string, vino = 0) {
    //First you get the current logged in user
    const cpUser = await this.afa.currentUser

    /*Then you set credentials to be the current logged in user's email
	and the password the user typed in the input named "old password"
	where he is basically confirming his password just like facebook for example.*/

    const credentials = firebase.auth.EmailAuthProvider.credential(
      cpUser.email,
      oldPW
    )

    //Reauthenticating here with the data above
    cpUser.reauthenticateWithCredential(credentials).then(
      async (success) => {
        let alert
        let pwSuccess = true
        if (newPW != newPWConfirm) {
          alert = await this.alertController.create({
            header: 'Passwort ändern fehlgeschlagen',
            message: 'Die neuen Passwörter stimmen leider nicht überein.',
            buttons: ['Nochmal versuchen'],
          })
          pwSuccess = false
        } else if (newPW.length < 6) {
          alert = await this.alertController.create({
            header: 'Passwort ändern fehlgeschlagen',
            message: 'Dein Passwort sollte mindestens 6 Zeichen lang sein.',
            buttons: ['Nochmal versuchen'],
          })
          pwSuccess = false
        } else {
          alert = await this.alertController.create({
            header: 'Passwort ändern erfolgreich',
            message: 'Dein Passwort wurde erfolgreich geändert.',
            buttons: ['OK'],
          })
          /* Update the password to the password the user typed into the
			  new password input field */
          cpUser
            .updatePassword(newPW)
            .then(function () {
              //Success
            })
            .catch(function (error) {
              //Failed
            })
        }
        if (vino == 0 && pwSuccess == false) {
          alert = await this.alertController.create({
            header: 'Registrieren Fehlgeschlagen',
            message: `Durch Dein Vinocentral-Konto gibt es Probleme beim Registrieren. 
						Bitte wende Dich an den Split-Support, um das Problem zu lösen.`,
            buttons: ['OK'],
          })
          alert.present()
        } else if (vino == 1 && pwSuccess == true) {
          this.createUser('', '', cpUser.email, cpUser, true)
        } else if (vino == 2 && pwSuccess == true) {
        } else {
          alert.present()
        }
      },
      async (error) => {
        console.log(error)
        if (error.code === 'auth/wrong-password') {
          const alert = await this.alertController.create({
            header: 'Passwort ändern fehlgeschlagen',
            message: 'Dein altes Passwort wurde falsch eingegeben',
            buttons: ['Nochmal versuchen'],
          })
          alert.present()
        }
      }
    )
  }

  /**
   * saves the customer as an entry under the gastro, only when the customer finishes an order,
   * if the entry already exists, then the data is updated
   * the following data is saved: plz,age,favGastro,gender,lastOrdered*,loyaltyCard,name,ID,orderAmount*,orderValue*
   * the attributes that are marked with a * are those that increase over time( with each order)
   */
  async saveAsCustomer(order: any, gastroId: string) {
    const oldCustomerInfo = await this.db
      .collection('gastro')
      .doc(gastroId)
      .collection('customers')
      .doc(this.profile.userID)
      .get()
      .toPromise()
    if (oldCustomerInfo.exists) {
      const now = new Date()
      const orderValue = order.dish.count * order.dish.price
      this.db
        .collection('gastro')
        .doc(gastroId)
        .collection('customers')
        .doc(this.profile.userID)
        .set(
          {
            age: this.getAge(),
            favGastro: this.isFavGastro(gastroId),
            gender: this.profile.gender,
            hasPointCard: this.hasActivePointCardOfCurrentGastro(gastroId),
            hasSplitometer: this.hasActiveSplitometerOfCurrentGastro(gastroId),
            Id: this.profile.userID,
            lastOrdered: order.createdAt,
            loyaltyCard: this.hasActiveLoyaltyCardOfGastro(gastroId),
            name: `${this.profile.name} ${this.profile.lastname}`,
            orderAmount: oldCustomerInfo.data().orderAmount + 1,
            orderValue: oldCustomerInfo.data().orderValue + orderValue,
            PLZ: this.user.address.PLZ,
          },
          { merge: true }
        )
    } else {
      const now = new Date()
      const orderValue = order.dish.count * order.dish.price
      this.db
        .collection('gastro')
        .doc(gastroId)
        .collection('customers')
        .doc(this.profile.userID)
        .set(
          {
            age: this.getAge(),
            favGastro: this.isFavGastro(gastroId),
            gender: this.profile.gender,
            hasPointCard: this.hasActivePointCardOfCurrentGastro(gastroId),
            hasSplitometer: this.hasActiveSplitometerOfCurrentGastro(gastroId),
            Id: this.profile.userID,
            lastOrdered: order.payedAt,
            loyaltyCard: this.hasActiveLoyaltyCardOfGastro(gastroId),
            name: `${this.profile.name} ${this.profile.lastname}`,
            orderAmount: 1,
            orderValue: orderValue,
            PLZ: this.user.address.PLZ,
          },
          { merge: true }
        )
    }
  }
}
