import { Component } from '@angular/core'

@Component({
  selector: 'app-banner',
  styleUrls: ['./banner.component.scss'],
  templateUrl: './banner.component.html',
})
export class BannerComponent {
  constructor() {
    return
  }
}
