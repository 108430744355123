<footer class="footer">
  <ion-grid>
    <ion-row style="display: flex; flex-wrap: wrap">
      <ion-col>
        <ion-row>
          <h3>Für Gastronomen</h3>
        </ion-row>
        <ion-row>
          <ul>
            <li>
              <a
                class="ftext"
                id="footer-impressum"
                href="https://gastro.split-app.de/digitale-speisekarte"
                target="_blank"
                >Digitale Speisekarte</a
              >
            </li>
            <li>
              <a
                class="ftext"
                id="footer-datenschutz"
                href="https://gastro.split-app.de/restaurant-bestellsystem"
                target="_blank"
                >Self Ordering</a
              >
            </li>
            <li>
              <a
                class="ftext"
                id="footer-AGBK"
                href="https://gastro.split-app.de/vorbestellung-lieferservice"
                target="_blank"
                >Abhol- und Lieferservice</a
              >
            </li>
          </ul>
        </ion-row>
      </ion-col>
      <ion-col>
        <ion-row>
          <h3>Split in Deiner Stadt</h3>
        </ion-row>
        <ion-row>
          <ul>
            <li>
              <a
                class="ftext"
                id="footer-impressum"
                href="https://split-app.de/home/Frankfurt"
                target="_blank"
                >Frankfurt</a
              >
            </li>
            <li>
              <a
                class="ftext"
                id="footer-impressum"
                href="https://split-app.de/home/Köln"
                target="_blank"
                >Köln</a
              >
            </li>
            <li>
              <a
                class="ftext"
                id="footer-impressum"
                href="https://split-app.de/home/Darmstadt"
                target="_blank"
                >Darmstadt</a
              >
            </li>
            <li>
              <a
                class="ftext"
                id="footer-impressum"
                href="https://split-app.de/home/Würzburg"
                target="_blank"
                >Würzburg</a
              >
            </li>
            <li>
              <a
                class="ftext"
                id="footer-impressum"
                href="https://split-app.de/home/Heidelberg"
                target="_blank"
                >Heidelberg</a
              >
            </li>
          </ul>
        </ion-row>
      </ion-col>
      <ion-col style="min-width: 320px">
        <ion-row
          style="
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
          "
        >
          <img
            src="../../../assets/logos/orderbird/RGB_obLogo_blue.svg"
            alt="Split Logo"
            style="width: 80%"
          />
        </ion-row>
        <ion-row>
          <ion-col class="logoCol">
            <a href="https://www.facebook.com/splitislit"
              ><img
                src="../../../assets/logo-facebook.webp"
                alt="Split Logo"
                style="cursor: pointer; width: 48px"
            /></a>
          </ion-col>
          <ion-col class="logoCol">
            <a href="https://www.instagram.com/splitislit/"
              ><img
                src="../../../assets/logo-instagram.webp"
                alt="Split Logo"
                style="cursor: pointer; width: 48px"
            /></a>
          </ion-col>
          <ion-col class="logoCol">
            <a href="https://de.linkedin.com/company/splittechsolutionsgmbh"
              ><img
                src="../../../assets/logo-linkedin.webp"
                alt="Split Logo"
                style="cursor: pointer; width: 48px"
            /></a>
          </ion-col>
          <ion-col class="logoCol">
            <a
              style="display: flex"
              href="https://www.youtube.com/channel/UCxyN8n9j8YgvqEjaOQHoSgw/featured"
              ><img
                src="../../../assets/logo-youtube-wide.webp"
                alt="Split Logo"
                style="align-self: center; cursor: pointer; width: 48px"
            /></a>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row style="margin: 16px auto; text-align: center">
      <ion-col>
        <a
          class="ftext"
          id="footer-impressum"
          href="https://gastro.split-app.de/imprint"
          target="_blank"
          >Impressum</a
        >
        <a
          class="ftext"
          id="footer-impressum"
          href="https://www.orderbird.com/de/datenschutzerklaerung-bestellmanagement"
          target="_blank"
          >Datenschutz</a
        >
        <a
          class="ftext"
          id="footer-impressum"
          href="https://www.orderbird.com/de/agb-bestellmanagement"
          target="_blank"
          >AGB's</a
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</footer>
