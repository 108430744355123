import { Injectable, NgZone } from '@angular/core'

export interface GastroResettable {
  /**
   * This is called when logging out of a gastro or the gastro has to be changed
   */
  onGastroLogout(): void

  /**
   * Register the current class to be resetted when logging out of a gastro
   */
  registerGastroReset(): void
}

@Injectable({ providedIn: 'root' })
export class ResetService {
  private gastroResettables: GastroResettable[] = []

  constructor(public zone: NgZone) {}

  /**
   * Registers [resettable] to be resetted on onGastroLogout
   * @param resettable the service/page which has values that should be resetted when logging out of a gastro
   */
  registerGastroReset(resettable: GastroResettable): void {
    if (!this.gastroResettables.includes(resettable)) {
      this.gastroResettables.push(resettable)
    }
  }

  /**
   * Calls onGastroLogout on all registered resettables of type GastroResettable
   */
  onGastroLogout() {
    this.zone.run(() => {
      console.log('RESETTING!', this.gastroResettables)
      for (const resettable of this.gastroResettables) {
        resettable.onGastroLogout()
      }
    })
  }
}
