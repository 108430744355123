import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  AlertController,
  LoadingController,
  ModalController,
  PickerController,
  PopoverController,
} from '@ionic/angular'
import {
  PayAtTableOrderService,
  SelfCheckoutPaymentMethod,
} from 'src/app/PayAtTable/order/pay-at-table-order.service'
import { CartService } from 'src/app/services/cart.service'
import { GastroService } from 'src/app/services/gastro.service'
import { GeoService } from 'src/app/services/geo.service'
import { GlobalService } from 'src/app/services/global.service'
import { LocalizationService } from 'src/app/services/localization.service'
import { PaymentService } from 'src/app/services/payment.service'
import { SentryService } from 'src/app/services/sentry.service'
import { SessionDataService } from 'src/app/services/session-data.service'
import { StorageService } from 'src/app/services/storage.service'
import { TableService } from 'src/app/services/table.service'
import { TableContentService } from 'src/app/services/table-content.service'
import { UserService } from 'src/app/services/user.service'
import { PaymentMethod, UtilService } from 'src/app/services/util.service'
import { PaymentMethodModalPage } from './modals/payment-method-modal/payment-method-modal.page'
import { TippingModalPage } from './modals/tipping-modal/tipping-modal.page'

@Component({
  selector: 'app-table-content',
  styleUrls: ['./table-content.page.scss'],
  templateUrl: './table-content.page.html',
})
export class TableContentPage implements OnInit {
  public showStripeButton = false
  public tableNr = 0
  partialPaymentMode = false
  isPaymentViewVisible = false

  constructor(
    public tableContentService: TableContentService,
    public utilService: UtilService,
    public modalController: ModalController,
    public cartService: CartService,
    public alertController: AlertController,
    public gastroService: GastroService,
    public pickerController: PickerController,
    public sessionDataService: SessionDataService,
    public popoverController: PopoverController,
    public globalService: GlobalService,
    public paymentService: PaymentService,
    public loadingController: LoadingController,
    public geoService: GeoService,
    public sentryService: SentryService,
    public storageService: StorageService,
    public localizationService: LocalizationService,
    public userService: UserService,
    public router: Router,
    public tableService: TableService,
    public payAtTableOrderService: PayAtTableOrderService
  ) {
    this.tableContentService.selectedTableContent.subscribe((value) => {
      this.calculateTotalOfSelectedContent()
      this.calculateTotal()
    })

    this.tableContentService.tableContent.subscribe((value) => {
      this.calculateTotalOfTableContent()
    })
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.gastroName = this.gastroService.$gastro.name
    this.paymentMethodSelected = false
    this.isPaymentViewVisible = false
    this.partialPaymentMode = false
    this.selectedPaymentMethod = {
      logo: {},
      label: '',
      name: '',
    }
    this.tableContentService.deselectAll()
    this.setTipValue(0, 'none')
    this.totalAmountToPay = this.tableContentService.getTotal()
    this.isPaymentModeSelected = false
    this.paymentService.$paymentId = undefined
    this.paymentService.$paymentName = undefined
  }

  async ionViewDidEnter() {
    this.setTableNumber()
    //check tableContent
    if (this.tableContentService.tableContent.value.length === 0) {
      await this.fetchObTab()
    }
    if (await this.gastroService.gastroLoadedSubject) {
      this.handleChangePaymentMethod(
        this.filterPaymentMethodsForSelection()[0].value
      )
    }
  }

  splitTableNr: any
  gastroName: string
  paymentName: string
  dibsCheckout: any
  dibsStartInit = false
  selectedContentTotal = 0
  allowCustomTip = false
  listOfPaymentNames: string[] = []
  customTipInput = '0.00'
  didCheck = true
  payment: string
  currentTip = ''
  showFooter = true
  paymentMethodSelected = false
  selectedPaymentMethod = {
    logo: {},
    label: '',
    name: '',
  }
  calculatedTips = {
    low: '0.00',
    medium: '0.00',
    high: '0.00',
  }
  //used to display the inital total amount before choosing the payment mode
  totalAmountToPay: number
  isPaymentModeSelected = false

  private totalAmountOfSelectedContent: any = {
    valueAsNumber: 0,
    valueAsString: '0.00',
  }
  public get $totalAmountOfSelectedContent(): any {
    return this.totalAmountOfSelectedContent
  }
  public set $totalAmountOfSelectedContent(value: any) {
    this.totalAmountOfSelectedContent.valueAsString = <string>(
      (Math.round(value * 100) / 100).toFixed(2)
    )
    this.totalAmountOfSelectedContent.value = value
  }

  private totalAmountOfTableContent: any = {
    valueAsNumber: <number>0,
    valueAsString: '0.00',
  }
  public get $totalAmountOfTableContent(): any {
    return this.totalAmountOfTableContent
  }
  public set $totalAmountOfTableContent(value: any) {
    this.totalAmountOfTableContent.valueAsString = (
      Math.round(value * 100) / 100
    ).toFixed(2)
    this.totalAmountOfTableContent.value = value
  }

  // fetch from OB tab
  async fetchObTab() {
    await this.tableContentService.fetchOBTableData()
    this.calculateTotalOfSelectedContent()
    this.calculateTotalOfTableContent()
  }

  /**
   * is triggered when a user selects an item from the table content in order to add it to the list of items to be paid
   * the idea here is that it should be possible to do partial payment of a tab
   *
   * @param position
   */
  addOrderToSelectedTableContent(position) {
    this.tableContentService.addOrderToSelectedTableContent(position)
    this.updateTipsWithTotal()
    this.calculateTotalOfSelectedContent()
    this.calculateTotalOfTableContent()
    this.setupPlatformPayment(this.calculateTotal())
  }

  async setTableNumber() {
    this.tableNr = (await this.tableService.getAsyncTable()).addData.name
    this.splitTableNr = (await this.tableService.getAsyncTable()).tischNR
  }
  /**
   * is triggered when a user de-selects an item from the table content in order to add it to the list of items to be paid
   * the idea here is that it should be possible to do partial payment of a tab
   *
   * @param position
   */
  removeOrderFromSelectedTableContent(position) {
    this.tableContentService.removeOrderFromSelectedTableContent(position)
    this.updateTipsWithTotal()
    this.calculateTotalOfSelectedContent()
    this.calculateTotalOfTableContent()
    this.setupPlatformPayment(this.calculateTotal())
  }

  /**
   * when pressed triggers the function to pay in the service
   * if clause checks if the answer of the pay() function was a success or not. On success we get a paymentDoc object
   * On failure we get a validation_error object. We only navigate if the object contains a paymentDocId attribute.
   */
  async payForSelectedTableContent() {
    await this.payAtTableOrderService.pay()
    this.isPaymentModeSelected = false
    this.isPaymentViewVisible = false
  }

  /**
   * deselects all items and then selects them again in order to avoid multiple selections.
   * when pressed triggers the function to pay in the service
   */
  async payForAllTableContent() {
    this.tableContentService.deselectAll()
    this.tableContentService.selectAll()
    this.updateTipsWithTotal()
    await this.payAtTableOrderService.pay()
  }

  /**
   * calculates each respective tip value against a total.
   * Sets the calcualtedTips object and its atributes to the calcualted values
   * @param total
   */
  calculatePossibleTipValue(total: number) {
    this.calculatedTips.low = (0.1 * total).toString()
    this.calculatedTips.medium = (0.15 * total).toString()
    this.calculatedTips.high = (0.2 * total).toString()
  }

  selectAll() {
    this.tableContentService.selectAll()
    this.updateTipsWithTotal()
    this.calculateTotalOfTableContent()
    this.calculateTotalOfSelectedContent()
    this.setupPlatformPayment(this.calculateTotal())
  }

  /**
   * Calculates the total amount of selected content in the table and updates associated properties.
   */
  calculateTotalOfSelectedContent() {
    const total = this.tableContentService.getTotalOfSelectedContent()
    this.$totalAmountOfSelectedContent.valueAsString = total?.toFixed(2)
    this.$totalAmountOfSelectedContent.valueAsNumber = total
    this.calculatePossibleTipValue(total)
  }
  /**
   * Calculates the total amount of all items in the table and updates associated properties.
   */
  calculateTotalOfTableContent() {
    const total = this.tableContentService.getTotal()
    this.$totalAmountOfTableContent.valueAsString = total?.toFixed(2)
    this.$totalAmountOfTableContent.valueAsNumber = total
  }
  /**
   * Calculates the total amount, including selected content and tip, for pay-at-table functionality.
   * @returns {string} - The formatted total amount.
   */
  calculateTotal() {
    const total =
      this.$totalAmountOfSelectedContent.valueAsNumber +
      this.payAtTableOrderService.$tipValue.valueAsNumber
    return total?.toFixed(2)
  }
  /**
   * Handles the change in the selected tip percentage, updating the tip value accordingly.
   * If the function is executed with the same value, it defaults to "none"
   * @param {string} value - The selected tip percentage ("none", "5", "10", "15", "custom").
   */
  async handleTipChange(value: string) {
    if (value === this.currentTip && value !== 'custom') {
      this.currentTip = 'none'
    } else {
      this.currentTip = value
    }
    this.updateTipsWithTotal()
  }

  /**
   * sets the tip value in the payAtTableOrderService and updates the currentTip
   * @param value
   * @param stringValue
   */
  setTipValue(value: number, stringValue: string) {
    let tip = 0
    this.allowCustomTip = false
    this.currentTip = stringValue
    tip = value * this.$totalAmountOfSelectedContent.valueAsNumber
    this.payAtTableOrderService.$tipValue = {
      valueAsNumber: tip,
      valueAsString: (Math.round(tip * 100) / 100).toFixed(2),
    }
  }

  /**
   * almost identical to handleTipChange() but in this case we are only updating the tips when we add or remove an item from partial payment
   * or when adding all items after we selected a tip
   */

  updateTipsWithTotal() {
    switch (this.currentTip) {
      case 'none':
        this.allowCustomTip = false
        this.payAtTableOrderService.$tipValue = {
          valueAsNumber: 0,
          valueAsString: '0.00',
        }
        this.currentTip = 'none'
        break
      case '10':
        this.setTipValue(0.1, '10')
        break
      case '15':
        this.setTipValue(0.15, '15')
        break
      case '20':
        this.setTipValue(0.2, '20')
        break
      case 'custom':
        this.currentTip = 'custom'
        break
      default:
        break
    }
    this.setupPlatformPayment(this.calculateTotal())
  }

  /**
   * opens the tipping modal and sets the custom tip value on confirm
   */
  async openCustomTipModal() {
    const previousTip = this.currentTip
    this.handleTipChange('custom')
    const tippingModal = await this.modalController.create({
      backdropDismiss: false,
      component: TippingModalPage,
      componentProps: {
        customTipInput: {
          valueAsNumber: parseFloat(this.customTipInput),
          valueAsString: this.customTipInput,
        },
      },
      cssClass: 'tipping-modal',
    })
    tippingModal.present()
    tippingModal.onWillDismiss().then((dismissValue) => {
      if (dismissValue.role === 'cancel') {
        this.handleTipChange(previousTip)
      } else if (dismissValue.role === 'confirm') {
        this.payAtTableOrderService.$tipValue = dismissValue.data
        this.customTipInput = dismissValue.data.valueAsString
      }
    })
  }

  /**
   * Displays an alert notifying the user to select a payment method.
   */
  async showAlert(): Promise<void> {
    const scrollElement = document.querySelector('#askHowToPayItem')

    const alert = await this.alertController.create({
      header: 'Achtung',
      message:
        'Es wurde keine Zahlungsart ausgewählt. Bitte wähle eine Zahlungsart aus',
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
          cssClass: 'primary',
        },
      ],
    })
    await alert.present()
    if (scrollElement != null) {
      alert
        .onDidDismiss()
        .then(() => scrollElement.scrollIntoView({ behavior: 'smooth' }))
    }
  }
  /**
   * filter the list of available payment methods to only have those that are not ec or credit
   * @returns a list on payment options for displaying in a modal
   */

  filterPaymentMethodsForSelection() {
    const inputs = []
    let paymentMethods: PaymentMethod[] =
      this.gastroService.$gastro.paymentOptions
    paymentMethods = paymentMethods.filter(
      (method) => method.option !== 'ec-device' && method.option !== 'bar'
    )
    let foundASelectedPaymentMethod = false
    paymentMethods.forEach((option: PaymentMethod) => {
      if (
        this.sessionDataService.$inhouseLink
          ? option.inhouse
          : option.outerhouse
      ) {
        let checked = false
        if (option.option === 'credit' || option.option === 'platform') {
          if (
            this.payment === option.option &&
            this.paymentName === option.name
          ) {
            checked = true
            foundASelectedPaymentMethod = true
          }
          const input = {
            checked: checked,
            label: option.name,
            name: option.option,
            type: 'radio',
            value: option,
          }
          inputs.push(input)
        }
      }
    })
    if (foundASelectedPaymentMethod === false && inputs.length > 0) {
      inputs[0].checked = true
    }
    return inputs
  }
  /**
   * Displays an alert with radio buttons for selecting a payment method.
   * The available payment methods are fetched from the gastroService.
   */

  async askHowToPay(event?) {
    const inputs = this.filterPaymentMethodsForSelection()
    const alert = await this.alertController.create({
      header: 'Wie bezahlst Du?',
      inputs: inputs,
      buttons: [
        {
          cssClass: 'secondary',
          handler: () => {},
          role: 'cancel',
          text: 'Abbrechen',
        },
        {
          handler: (val) => {
            this.handleChangePaymentMethod(val)
          },
          text: 'Ok',
        },
      ],
    })
    await alert.present()
  }

  /**
   * Handles the change in the selected payment method and performs necessary updates.
   * @param {any} option - The selected payment method option.
   */
  handleChangePaymentMethod(option: any) {
    if (!option) {
      return
    }
    this.payment = option.option
    this.paymentService.$paymentName = option.name
    this.paymentService.radioGroupChange(option)

    this.showStripeButton =
      option.option === 'credit' &&
      this.gastroService.$gastro.hasDibsPayment !== true
    if (this.showStripeButton) {
      this.setupStripe()
    }

    this.setupPlatformPayment(this.calculateTotal())
  }

  /**
   * Sets up the Stripe card element in the HTML for credit card payments.
   * Configures the appearance and behavior of the Stripe card element.
   *  * For proper linebreak using card expire cvc and postalcode stripe elements
   */
  // eslint-disable-next-line max-lines-per-function
  setupStripe() {
    this.paymentService.elements = this.paymentService.stripe.elements()
    this.setupPlatformPayment(this.calculateTotal())
  }

  /**
   * Sets up the payment request button for the platform payment.
   * @param {number} total - The total amount to be paid.
   */
  async setupPlatformPayment(total: number) {
    const amount = Math.round(Math.round(total * 100 * 100) / 100)
    const paymentRequest = this.getPlatformPaymentRequest(amount)

    this.registerPlatformPaymentListener(paymentRequest)
    await this.setupPlatformPayButton(paymentRequest)
  }

  /**
   * Returns a payment request object for the platform payment with the given amount.
   * @param amount
   * @returns a payment request object for the platform payment
   */
  getPlatformPaymentRequest(amount: number) {
    return this.paymentService.stripe.paymentRequest({
      country: 'DE',
      currency: 'eur',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: `Bestellung bei ${this.gastroService.$gastro.name}`,
        amount: amount,
      },
    })
  }

  /**
   * registers a listener for the platform payment.
   * The listener is triggered when the user pays with the platform payment method.
   * This will trigger the payAtTableOrderService to pay the order.
   * When successful, the listener completes the payment with "success" and close the plaform modal.
   * @param paymentRequest
   */
  registerPlatformPaymentListener(paymentRequest: any) {
    paymentRequest.on('paymentmethod', async (ev) => {
      try {
        const payAtTableResult = await this.payAtTableOrderService.pay(true, ev)
        if (
          payAtTableResult === undefined ||
          payAtTableResult.paymentIntent === undefined
        ) {
          ev.complete('fail')
        } else {
          ev.complete('success')
        }
      } catch (error) {
        ev.complete('fail')
        console.error(error)
        this.sentryService.captureError(
          error,
          'pay at table error - platform payment failed'
        )
      }
    })
  }

  /**
   * sets up and shows the payment request button for the platform payment on the page.
   * @param paymentRequest
   */
  async setupPlatformPayButton(paymentRequest: any) {
    const elements = this.paymentService.stripe.elements()
    const prButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
    })

    // Check the availability of the Payment Request API first.
    const result = await paymentRequest.canMakePayment()
    if (result && this.paymentService.$paymentId === 'platform') {
      prButton.mount('#payment-request-button')
    } else {
      const prb = document.getElementById('payment-request-button')
      if (prb) {
        prb.style.display = 'none'
      }
    }
  }
  /**
   * navigates the user back to the SOI-page (start-of-interaction)
   */

  goBack() {
    this.router.navigate(['restaurant-page'])
  }

  togglePartialPaymentMode() {
    this.isPaymentModeSelected = true
    this.partialPaymentMode = true
  }

  openPaymentFooter() {
    this.isPaymentViewVisible = true
  }

  async openPaymentMethodModal() {
    const paymentMethodModal = await this.modalController.create({
      backdropDismiss: false,
      component: PaymentMethodModalPage,
      cssClass: 'payment-method-modal',
      htmlAttributes: {
        style: 'width:100%',
      },
    })
    paymentMethodModal.present()
    paymentMethodModal.onWillDismiss().then((dismissValue) => {
      const paymentMethodObjec: SelfCheckoutPaymentMethod = dismissValue.data
      if (dismissValue.role === 'confirm') {
        this.paymentService.$paymentName = paymentMethodObjec.name
        this.paymentService.radioGroupChange(paymentMethodObjec)
        this.paymentMethodSelected = true
        this.selectedPaymentMethod = dismissValue.data

        //if the payment method is platform, we need to set up the platform payment
        if (dismissValue.data.name === 'platform') {
          this.setupPlatformPayment(this.calculateTotal())
        }
      }
    })
  }

  /**
   * start of the full payment process, selecting all items anf opening the footer
   */

  fullPayment() {
    this.isPaymentModeSelected = true
    this.selectAll()
    this.openPaymentFooter()
  }
}
