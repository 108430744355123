<ng-container *ngIf="modalReady">
  <div class="payment-modal-container">
    <ion-row class="payment-mmodal-header-container">
      <ion-col
        style="align-content: center; text-align: center"
        size="8"
        offset="2"
      >
        <div class="payment-modal-header-text">Zahlart wählen</div>
      </ion-col>
      <ion-col size="2" style="align-content: center; text-align: center">
        <button
          class="payment-modal-close ion-align-self-end"
          (click)="this.cancel()"
          aria-label="Schliessen"
        >
          <div>
            <img src="assets/icons/orderbird-cancel.svg" />
          </div>
        </button>
      </ion-col>
    </ion-row>
    <div *ngIf="this.selectedPaymentMethod.name !== 'credit'">
      <ion-row class="payment-method-list-container">
        <ion-row
          class="payment-method-element-container"
          *ngFor="let paymentMethod of this.listOfFilteredPaymentMethods"
          (click)="selectPaymentMethod(paymentMethod)"
        >
          <!-- Logo -->
          <ion-col
            *ngIf="paymentMethod.name == 'credit'"
            style="
              padding: 8.958px 10.196px 9.306px 11.046px;
              text-align: center;
            "
            class="ion-align-self-start"
            size="3"
          >
            <div class="payment-method-logo">
              <ion-img
                src="./../../assets/logo-selfcheckout-ec.svg"
                style="height: 40px; margin: 0 4px; width: 55px"
              ></ion-img>
            </div>
          </ion-col>
          <ion-col
            *ngIf="paymentMethod.name == 'paypal' "
            style="text-align: center"
            class="ion-align-self-start"
            size="3"
          >
            <div class="payment-method-logo">
              <ion-img
                src="./../../assets/logo-selfcheckout-paypal.svg"
                style="height: 40px; margin: 0 4px; width: 55px"
              ></ion-img>
            </div>
          </ion-col>
          <ion-col
            *ngIf="paymentMethod.label == 'ApplePay'"
            style="text-align: center"
            class="ion-align-self-start"
            size="3"
          >
            <div class="payment-method-logo">
              <ion-img
                src="./../../assets/logo-selfcheckout-applepay.svg"
                style="height: 40px; margin: 0 4px; width: 55px"
              ></ion-img>
            </div>
          </ion-col>
          <ion-col
            *ngIf="paymentMethod.label == 'Google Pay'"
            style="text-align: center"
            class="ion-align-self-start"
            size="3"
          >
            <div class="payment-method-logo">
              <ion-img
                src="./../../assets/logo-selfcheckout-googlepay.svg"
                style="height: 40px; margin: 0 4px; width: 55px"
              ></ion-img>
            </div>
          </ion-col>
          <!-- label -->
          <ion-col style="align-content: center; text-align: start">
            <div class="payment-method-label" role="button">
              {{paymentMethod.label}}
            </div>
          </ion-col>
          <!-- check -->
          <ion-col
            *ngIf="paymentMethod.selected == true"
            size="2"
            style="align-content: center; text-align: center"
          >
            <ion-icon
              style="color: #36cc53; height: 30px; width: 30px"
              name="checkmark-outline"
            ></ion-icon>
          </ion-col>
        </ion-row>
      </ion-row>
    </div>
    <div *ngIf="this.selectedPaymentMethod.name == 'credit'">
      <ion-row class="payment-method-list-container">
        <ion-row
          class="payment-method-element-container"
          (click)="selectPaymentMethod(this.selectedPaymentMethod)"
        >
          <!-- Logo -->
          <ion-col
            *ngIf="this.selectedPaymentMethod.name == 'credit'"
            style="
              padding: 8.958px 10.196px 9.306px 11.046px;
              text-align: center;
            "
            class="ion-align-self-start"
            size="3"
          >
            <div class="payment-method-logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="25"
                viewBox="0 0 34 25"
                fill="none"
              >
                <path
                  d="M2.82854 23.8021C1.60714 23.8021 0.614258 22.797 0.614258 21.5625V3.64583C0.614258 2.41138 1.60714 1.40625 2.82854 1.40625H31.1714C32.3928 1.40625 33.3857 2.41138 33.3857 3.64583V21.5625C33.3857 22.797 32.3928 23.8021 31.1714 23.8021H2.82854Z"
                  fill="#98CCFD"
                />
                <path
                  d="M31.1719 1.85384C32.1488 1.85384 32.9433 2.6574 32.9433 3.64551V21.5622C32.9433 22.5503 32.1488 23.3538 31.1719 23.3538H2.82902C1.85207 23.3538 1.05759 22.5503 1.05759 21.5622V3.64551C1.05759 2.6574 1.85207 1.85384 2.82902 1.85384H31.1719ZM31.1719 0.958008H2.82902C1.36139 0.958008 0.171875 2.16111 0.171875 3.64551V21.5622C0.171875 23.0466 1.36139 24.2497 2.82902 24.2497H31.1719C32.6395 24.2497 33.829 23.0466 33.829 21.5622V3.64551C33.829 2.16111 32.6395 0.958008 31.1719 0.958008Z"
                  fill="#4788C7"
                />
                <path
                  d="M0.171875 5.4375H33.829V9.02083H0.171875V5.4375Z"
                  fill="#4788C7"
                />
                <path
                  d="M3.71484 10.8125H14.3434V12.6042H3.71484V10.8125Z"
                  fill="white"
                />
              </svg>
            </div>
          </ion-col>
          <!-- label -->
          <ion-col style="align-content: center; text-align: start">
            <div class="payment-method-label">
              {{this.selectedPaymentMethod.label}}
            </div>
          </ion-col>
          <!-- check -->
          <ion-col size="2" style="align-content: center; text-align: center">
            <ion-icon
              style="color: #36cc53; height: 30px; width: 30px"
              name="checkmark-outline"
            ></ion-icon>
          </ion-col>
        </ion-row>
      </ion-row>
    </div>
    <div style="height: 80%">
      <ion-row style="height: 100%; padding: 10px">
        <form
          [hidden]="this.showStripeButton == false"
          style="width: 100%"
          action="/charge"
          method="post"
          id="payment-form"
        >
          <div class="form-row">
            <div class="credit-card-input-container-number">
              <div id="card-errors-number" class="credit-card-input-label">
                Kartennummer
              </div>
              <div
                class="credit-card-input-number"
                id="card-number-element-pay-at-table"
              ></div>
            </div>
            <div class="credit-card-input-container-other-group">
              <div class="credit-card-input-container-other">
                <div id="card-errors-expiry" class="credit-card-input-label">
                  Gültig bis
                </div>
                <div
                  class="credit-card-input-other"
                  id="card-expiry-element-pay-at-table"
                ></div>
              </div>
              <div class="credit-card-input-container-other">
                <div id="card-errors-cvc" class="credit-card-input-label">
                  CVC
                </div>
                <div
                  class="credit-card-input-other"
                  id="card-cvc-element-pay-at-table"
                ></div>
              </div>
            </div>
          </div>
        </form>
        <div
          [hidden]="this.showStripeButton == false"
          style="align-self: end; width: 100%"
        >
          <button
            class="credit-card-done-button"
            (click)="submitCreditCardInfo()"
          >
            Fertig
          </button>
        </div>
      </ion-row>
    </div>
  </div>
</ng-container>
