<ion-header>
  <ion-toolbar>
    <ion-title> Achtung </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div *ngIf="deletedProducts.length === 1">
    <p style="text-align: center">
      Es wurde ein Produkt aus dem Sortiment entfernt, welches du in deinem
      Warenkorb hattest:
    </p>
    <div class="deletedProduct">
      <img [src]="deletedProducts[0].img" />
      <span>{{ deletedProducts[0].name }}</span>
      <span>{{
        getPrice(deletedProducts[0])
          | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de'
      }}</span>
    </div>
  </div>
  <div *ngIf="deletedProducts.length > 1">
    <p>
      Es wurden einige Produkte aus dem Sortiment entfernt, welche du in deinem
      Warenkorb hattest:
    </p>
    <ng-container *ngFor="let deletedProduct of deletedProducts; let i = index">
      <div class="deletedProduct">
        <img [src]="deletedProduct.img" />
        <span>{{ deletedProduct.name }}</span>
        <span>{{
          getPrice(deletedProduct)
            | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de'
        }}</span>
      </div>
    </ng-container>
  </div>

  <div *ngIf="changedProducts.length === 1">
    <p style="text-align: center">
      Es wurde ein Produkt aus dem Sortiment verändert, welches du in deinem
      Warenkorb hattest:
    </p>
    <div class="productContainer">
      <img class="oldImage" [src]="changedProducts[0].oldProduct.img" />
      <span class="oldProduct">{{ changedProducts[0].oldProduct.name }}</span>
      <span class="oldPrice">{{
        getPrice(changedProducts[0].oldProduct)
          | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de'
      }}</span>

      <div style="font-size: 24px; font-weight: 500; grid-area: myArrow">
        ->
      </div>
      <div style="grid-area: product">Produkt</div>
      <div style="grid-area: price">Preis</div>

      <img class="newImage" [src]="changedProducts[0].newProduct.img" />
      <span class="newProduct">{{ changedProducts[0].newProduct.name }}</span>
      <span class="newPrice">{{
        getPrice(changedProducts[0].newProduct)
          | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de'
      }}</span>
    </div>
  </div>
  <div *ngIf="changedProducts.length > 1">
    <p>
      Es wurden einige Produkte aus dem Sortiment verändert, welche du in deinem
      Warenkorb hattest:
    </p>
    <ng-container
      class="rowsBackgroundContainer"
      *ngFor="let productChange of changedProducts; let i = index"
    >
      <div class="productContainer">
        <img class="oldImage" [src]="productChange.oldProduct.img" />
        <span class="oldProduct">{{ productChange.oldProduct.name }}</span>
        <span class="oldPrice">{{
          getPrice(productChange.oldProduct)
            | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de'
        }}</span>

        <div style="font-size: 24px; font-weight: 500; grid-area: myArrow">
          ->
        </div>
        <div style="grid-area: product">Produkt</div>
        <div style="grid-area: price">Preis</div>

        <img class="newImage" [src]="productChange.newProduct.img" />
        <span class="newProduct">{{ productChange.newProduct.name }}</span>
        <span class="newPrice">{{
          getPrice(productChange.newProduct)
            | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de'
        }}</span>
      </div>
    </ng-container>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="dismiss()">
        <b> Weiter </b>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
