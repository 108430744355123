import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { ComponentsModule } from 'src/app/components/components.module'
import { TableContentPage } from './table-content.page'
import { TableContentPageRoutingModule } from './table-content-routing.module'

@NgModule({
  declarations: [TableContentPage],

  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TableContentPageRoutingModule,
    ComponentsModule,
  ],
})
export class TableContentPageModule {}
