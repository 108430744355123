import { Injectable } from '@angular/core'
import { Gastro } from 'src/shared/split-submodules/types/types'
import { OrderService } from './order.service'
import { Discount } from './payment.service'

@Injectable({ providedIn: 'root' })
export class PlatformService {
  constructor(public orderService: OrderService) {}

  /**
   * determine if gastro has an active discount
   * @param gastro
   * @returns the active discount
   */
  public getGastroDiscounts(gastro: Gastro): any {
    const ret = []
    if (gastro?.discount === undefined) {
      return ret
    }
    for (const d of gastro.discount) {
      const active = this.isDiscountActiveForWhereType(d)
      if (active === true) {
        ret.push(d)
      }
    }
    return ret
  }

  isDiscountActiveForWhereType(discount) {
    if (!this.isDiscountWithinTime(discount)) {
      return false
    }

    if (discount.days === undefined) {
      return true
    }

    const now = new Date()
    if (discount.days[now.getDay()] === true) {
      return true
    }

    return false
  }

  public isDiscountWithinTime(discount: Discount): boolean {
    const nowTemp = new Date()
    const now = new Date(2000, 0, 1)
    now.setHours(nowTemp.getHours())
    now.setMinutes(nowTemp.getMinutes())
    if (
      (discount.from === undefined && discount.to === undefined) ||
      (discount.from === '' && discount.to === '')
    ) {
      return true
    }

    if (
      (this.orderService.timeToDate(discount.from) <= now &&
        now <= this.orderService.timeToDate(discount.to)) ||
      (this.orderService.timeToDate(discount.from) <= now &&
        now <= this.orderService.timeToDate(discount.to))
    ) {
      return true
    }

    if (
      this.orderService.timeToDate(discount.from) <= now &&
      now <= this.orderService.timeToDate(discount.to)
    ) {
      return true
    }

    return false
  }
}
