<ion-split-pane contentId="profile-content">
  <ion-menu contentId="profile-content" menuId="profilearea-menu">
    <ion-content>
      <ion-list
        style="
          background: transparent;
          color: black;

          --background: transparent;
        "
        id="inbox-list"
      >
        <ion-row>
          <ion-col class="ion-align-self-center ion-text-center">
            <img
              (click)="this.goBack()"
              style="cursor: pointer; height: 80px"
              [src]="'./../../assets/OBSplit-Logo.svg'"
            />
          </ion-col>
        </ion-row>

        <ion-menu-toggle
          auto-hide="false"
          *ngFor="let tab of this.userService.profileAreaTabs; let i = index"
        >
          <ion-item
            button
            style="

--highlight-color-focused: split-main"
            *ngIf="tab.enabled&& selectedIndex == i"
            (click)="selectedIndex = i"
            routerDirection="root"
            [routerLink]="[tab.url]"
            lines="full"
            [detail]="false"
          >
            <ion-icon
              color="split-main"
              [name]="tab.iconFilled"
              slot="start"
            ></ion-icon>

            <ion-label color="color:black;"> {{ tab.display }}</ion-label>
          </ion-item>
          <ion-item
            [ngClass]="tab.display"
            button
            style="

--background: transparent"
            *ngIf="tab.enabled&& selectedIndex != i"
            (click)="selectedIndex = i"
            routerDirection="root"
            [routerLink]="[tab.url]"
            lines="full"
            [detail]="false"
          >
            <ion-icon [name]="tab.iconOutline" slot="start"></ion-icon>
            <ion-label color="color:black;">{{ tab.display }}</ion-label>
            <ion-text *ngIf="tab.display=='Gutscheine'" class="addingCoupon"
              ><b>+1</b></ion-text
            >
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-button
        (click)="this.goBack()"
        size="default"
        style="border-radius: 2rem; color: black"
        color="split-secondary"
        expand="full"
      >
        <ion-icon name="arrow-undo-outline" style="margin-right: 10px">
        </ion-icon
        >Zurück zur Homepage</ion-button
      >
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="profile-content" main></ion-router-outlet>
</ion-split-pane>
