import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { ProfileEntryModalPage } from './profile-entry-modal.page'

const routes: Routes = [
  {
    path: '',
    component: ProfileEntryModalPage,
  },
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
  ],
  declarations: [ProfileEntryModalPage],
})
export class ProfileEntryModalPageModule {}
