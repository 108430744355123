import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { CartService } from 'src/app/services/cart.service'
import { SessionDataService } from 'src/app/services/session-data.service'
import { StorageService } from 'src/app/services/storage.service'
import { UserService } from 'src/app/services/user.service'

@Injectable({ providedIn: 'root' })
export class InGastroGuard implements CanActivate {
  constructor(
    public cartService: CartService,
    private userService: UserService,
    public router: Router,
    public storageService: StorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve, reject) => {
      const isGastroLoggedOut =
        await this.storageService.load('isGastroLoggedOut')
      console.log('isGastrologgedout', isGastroLoggedOut)
      if (
        isGastroLoggedOut === true ||
        isGastroLoggedOut === undefined ||
        isGastroLoggedOut === '' ||
        isGastroLoggedOut === null
      ) {
        this.router.navigate(['home'])
        resolve(false)
      } else {
        resolve(true)
      }
    })
  }
}
