import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { AlertController, ToastController } from '@ionic/angular'
import intlTelInput from 'intl-tel-input'
import { Gastro } from 'src/shared/split-submodules/types/types'
import { Config, uniqueNamesGenerator } from 'unique-names-generator'

export interface Category {
  /**
   * The unique id of the category
   */
  id: number

  /**
   * The name of the category
   */
  name: string

  /**
   * The description of the category
   */
  description: string

  /**
   * Whether or not the category contains food-items
   */
  isFood: boolean

  /**
   * Whether or not the category should be visible inhouse
   */
  inhouseVisible: boolean

  /**
   * Whether or not the category should be visible outerhouse
   */
  outerhouseVisible: boolean
}

export interface Product {
  /**
   * The id of the product
   */
  id: number

  /**
   * The id of the category this product belongs to-
   */
  categoryId: number

  /**
   * The name of the product
   */
  name: string

  /**
   * The description of the product
   */
  description: string

  /**
   * Whether or not the product has extras
   */
  opt: boolean

  /**
   * The id of the extra in the Database collection `dishExtras`
   */
  extraId: string

  /**
   * The url of an image which a product might have
   */
  img: string

  /**
   * The price of the product for inhouse purposes in euros as a float (e.g. 6.50)
   */
  inhousePrice: number

  /**
   * The tax which is applied on this product when ordering inhouse as a float (e.g. 0.07)
   */
  inhouseTax: number

  /**
   * Whether or not this product should be shown when ordering inhouse
   */
  inhouseVisible: boolean

  /**
   * The price of the product for outerhouse purposes in euros as a float (e.g. 6.50)
   */
  outerhousePrice: number

  /**
   * The tax which is applied on this product when ordering outerhouse as a float (e.g. 0.07)
   */
  outerhouseTax: number

  /**
   * Whether or not this product should be shown when ordering outerhouse
   */
  outerhouseVisible: boolean

  /**
   * Whether or not this product is food
   */
  isFood: boolean

  /**
   * The kind of this product????
   */
  kind: number

  /**
   * Whether or not this product is a special offer
   */
  offer: boolean

  /**
   * Extra information needed if this product is an offer
   */
  offerData?: any
}

export interface ExtraItem {
  /**
   * A description for this item
   */
  description: string

  /**
   * The price which will be applied when ordering inhouse
   */
  inhousePrice: number

  /**
   * The tax which will be applied when ordering inhouse
   */
  inhouseTax: number

  /**
   * Whether or not this extraItem will be shown when ordering inhouse
   */
  inhouseVisible: boolean

  /**
   * Whether this item is a food item
   */
  isFood: boolean

  /**
   * The name of this item
   */
  name: string

  /**
   * The price which will be applied when ordering outerhouse
   */
  outerhousePrice: number

  /**
   * The tax which will be applied when ordering outerhouse
   */
  outerhouseTax: number

  /**
   * Whether or not this extraItem will be shown when ordering outerhouse
   */
  outerhouseVisible: boolean
}

export interface ExtraCategory {
  /**
   * A description for this category
   */
  description: string

  /**
   * The kind of this category
   * 0: dropdown
   * 1: checkbox
   * 2: plus/minus
   * 3: text
   */
  kind: number

  /**
   * The header for this category
   */
  name: string

  /**
   * Items belonging to this category
   */
  extraItems: ExtraItem[]

  /**
   * Used for checkbox and plus/minus
   * Indicates how many items may be chosen for free
   */
  free?: number

  /**
   * Used for checkbox and plus/minus.
   * Indicates the max amount of items to be chosen within this category
   */
  maxCount?: number
}

export interface Extra {
  /**
   * A text that will be shown when showing the extra at the start
   */
  description: string

  /**
   * The id under which the extra can be found in firebase
   */
  id: string

  /**
   * The url to an image the extra has
   */
  img: string

  /**
   * Different categories for this extra
   */
  extraCategories: ExtraCategory[]
}

export interface PaymentMethod {
  /**
   * The Name which will be shown to customers
   */
  name: string

  /**
   * The Identifier to select the correct payment-Pipeling (e.g. 'credit', 'cash', ...)
   */
  option: string

  /**
   * Whether this payment method can be used inhouse
   */
  inhouse: boolean

  /**
   * Whether this payment method can be used outerhouse
   */
  outerhouse: boolean
}

export interface OrderDish {
  id: number
  name: string
  price: number
  kind: number
  prices: number[]
  image: string
  extras: any[]
  sorted: boolean
  note: string
  dropdown: any[]
  checkboxen: any[]
  categorie: number
  count: number
}

export interface Order {
  gastroId?: any
  items: OrderDish[]
  payment: {
    option: string
    name: string
    payed: number
  }
  isPayed: boolean
  nickname: string
  table: {
    nr: number
    id?: string
    name?: string
  }
  tip: number
  pickup?: boolean
}

export interface Globals {
  switches: {
    profileSystem: boolean
  }
}

@Injectable({ providedIn: 'root' })
export class UtilService {
  private nicknamesDB = {
    dictionaries: [],
    length: 0,
    separator: '',
    style: '',
  }
  public isNumberValid = false

  loadingWindow

  constructor(
    private afs: AngularFirestore,
    private alertController: AlertController,
    private toastController: ToastController
  ) {}

  public centsToEuros(centAmount: number): number {
    return centAmount / 100
  }

  public eurosToCents(euroAmount: number): number {
    return Math.floor(euroAmount * 100)
  }

  public minutesUntilMidnight() {
    const midnight = new Date()
    midnight.setHours(24)
    midnight.setMinutes(0)
    midnight.setSeconds(0)
    midnight.setMilliseconds(0)
    return (midnight.getTime() - new Date().getTime()) / 1000 / 60
  }

  fetchNicknames(nicknameDB: string) {
    if (nicknameDB) {
      this.nicknamesDB = {
        dictionaries: [],
        length: 0,
        separator: '',
        style: '',
      }
      this.afs
        .collection('nicknames')
        .doc(nicknameDB)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            this.nicknamesDB = <any>doc.data()
          }
        })
    }
  }

  loadingWindowDismiss() {
    try {
      this.loadingWindow?.dismiss()
    } catch (e) {
      console.log('err: loading window already closed')
    }
  }

  async nicknameGenerator(gastroId: string) {
    if (this.nicknamesDB.dictionaries.length != 0) {
      return await this.getNextNicknameByIndex(gastroId)
    } else {
      const germanAd = [
        'abenteuerlustiger',
        'achtsamer',
        'achtungswerter',
        'agiler',
        'bequemer',
        'beliebter',
        'beweglicher',
        'edeler',
        'ernster',
        'geduldiger',
        'genießbarer',
        'kräftiger',
        'lebenslustige',
        'leistungsorientierte',
        'lustige',
        'moderne',
        'nützliche',
        'praktische',
        'realistische',
        'sagenhafte',
        'sensibler',
        'sonnige',
        'sympathische',
        'tüchtiger',
      ]
      const soccer = [
        'Messi',
        'Ronaldo',
        'Beckenbauer',
        'Müller',
        'Özil',
        'Neuer',
        'Boateng',
        'Modric',
        'Kroos',
        'Ronaldinho',
        'Robben',
        'Ribery',
        'Suarez',
        'Löw',
        'Beckham',
        'Zidane',
      ]
      const customConfig: Config = {
        dictionaries: [germanAd, soccer],
        length: 2,
        separator: ' ',
        style: 'capital',
      }
      const shortName: string = uniqueNamesGenerator(customConfig) // big-donkey
      return shortName
    }
  }

  async getNextNicknameByIndex(gastroId: string) {
    let currentNicknameIndex = -1
    const gastroRef = this.afs.collection<Gastro>('gastro').doc(gastroId).ref
    const dictionaryLength = this.nicknamesDB.dictionaries[0].dictionary.length
    await this.afs.firestore.runTransaction((transaction) => {
      return transaction.get(gastroRef).then((gastro) => {
        if (!gastro.exists) {
          console.log(`Document ${gastroRef} does not exist.`)
        } else {
          if (gastro.data().nicknameIndex != undefined) {
            currentNicknameIndex = gastro.data().nicknameIndex
          } else {
            currentNicknameIndex = 0
          }
          const newIndex = (currentNicknameIndex + 1) % dictionaryLength
          transaction.update(gastroRef, { nicknameIndex: newIndex })
        }
      })
    })
    //If unsuccessful then select randomly
    if (currentNicknameIndex < 0) {
      console.log('Transaction was not successful')
      currentNicknameIndex = Math.floor(
        Math.random() * this.nicknamesDB.dictionaries[0].dictionary.length
      )
    }
    return this.nicknamesDB.dictionaries[0].dictionary[currentNicknameIndex]
  }

  /**
   * Checks if two objects are deep copies of each other.
   * !!!Does not work with Dates!!!
   * @param x object 1
   * @param y object 2
   * @returns {boolean}
   */
  public objectEquals(x: any, y: any): boolean {
    /* eslint-disable no-prototype-builtins */

    // if both x and y are null or undefined and exactly the same
    if (x === y) {
      return true
    }

    // if they are not strictly equal, they both need to be Objects
    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false
    }

    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.
    if (x.constructor !== y.constructor) {
      return false
    }

    for (const p in x) {
      // other properties were tested using x.constructor === y.constructor
      if (!x.hasOwnProperty(p)) {
        continue
      }

      // allows to compare x[p] and y[p] when set to undefined
      if (!y.hasOwnProperty(p)) {
        return false
      }

      // if they have the same strict value or identity then they are equal
      if (x[p] === y[p]) {
        continue
      }

      // Numbers, Strings, Functions, Booleans must be strictly equal
      if (typeof x[p] !== 'object') {
        return false
      }

      // Objects and Arrays must be tested recursively
      if (!this.objectEquals(x[p], y[p])) {
        return false
      }
    }

    for (const p in y) {
      // allows x[p] to be set to undefined
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
        return false
      }
    }

    return true

    /* eslint-enable no-prototype-builtins */
  }
  /**
   * is given a length and returns an alphanumeric string of characters of this length
   * @param length the length of the desired ID
   * @returns a string of characters of "length" length
   */
  makeid(length: number) {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  /**
   *
   * @returns the current date
   */
  getServerDate() {
    // let url = environment.functionsUrl + "getServerTime";
    // await $.get(url, {}, response => {
    //     this.configUrl = new Date(response)
    // });
    // return this.configUrl;
    // this.configUrl = new Date()
    // return this.configUrl
    return new Date()
  }

  replaceUmlaute(s: string) {
    s = s.toLowerCase()
    s = s.replace('ä', 'ae')
    s = s.replace('ö', 'oe')
    s = s.replace('ü', 'ue')
    s = s.replace('ß', 'ss')
    return s
  }

  async alertInfo(
    subHeader: string,
    message: string,
    scrollIntoID: string = undefined
  ) {
    const scrollElement = document.querySelector(scrollIntoID)
    const alert = await this.alertController.create({
      buttons: [{ text: 'Ok' }],
      header: 'Info',
      message: message,
      subHeader: subHeader,
    })

    await alert.present()
    if (scrollElement != null) {
      alert
        .onDidDismiss()
        .then(() => scrollElement.scrollIntoView({ behavior: 'smooth' }))
    }
  }

  checkMobileNr() {
    return this.isNumberValid
  }

  async presentToast(
    message,
    position: any = 'top',
    scrollIntoID: string = undefined
  ) {
    const scrollElement = document.querySelector(scrollIntoID)
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 3000,
      message: message,
      position: position,
    })
    toast.present()
    if (scrollElement != null) {
      scrollElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  /**
   * Returns a new sorted array of objects based on a property
   * @param arr The array to be sorted
   * @param propertyName By which property the array should be sorted
   * @returns
   */
  sortByProperty<T extends object>(arr: T[], propertyName: string) {
    return arr.slice().sort((a, b) => {
      if (a[propertyName] < b[propertyName]) {
        return -1
      }
      if (a[propertyName] > b[propertyName]) {
        return 1
      }
      return 0
    })
  }

  /**
   * Returns a new array sorted by a specified property with the favorite object at the beginning
   * @param arr The array to be sorted
   * @param propertyName By which property the array should be sorted (The property is expected to be of type string or number)
   * @param favoriteValue A value of [propertyName] which should be at the beginning of the array
   * @returns
   */
  public sortByPropertyWithFavoriteFirst<T extends object>(
    arr: T[],
    propertyName: string,
    favoriteValue: string | number
  ) {
    const sorted = this.sortByProperty(
      arr.filter((obj) => obj[propertyName] !== favoriteValue),
      propertyName
    )
    const favoriteObject = arr.find(
      (obj) => obj[propertyName] === favoriteValue
    )
    if (favoriteObject) {
      sorted.unshift(favoriteObject)
    }
    return sorted
  }
}
