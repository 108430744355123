import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AppRoutingPreloaderService } from './app-routing-preloader.service'
import { CartGuard } from './guards/cart.guard'
import { GastroActiveGuard } from './guards/gastro-active.guard'
import { InGastroGuard } from './guards/in-gastro.guard'
import { LoginGuard } from './guards/login.guard'
import { MenuAvailableGuard } from './guards/menu-available.guard'
import { NegateOpenGuard } from './guards/negate-open.guard'
import { OpenGuard } from './guards/open.guard'
import { OrderingAvailableGuard } from './guards/ordering-available.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  // { path: ':id', redirectTo: 'home/:id', pathMatch: 'full' },
  {
    data: { animation: 'Home' },
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    path: 'home',
  },
  {
    loadChildren: () =>
      import('./qrcode/qrcode.module').then((m) => m.QrcodePageModule),
    path: 'qrcode',
  },
  {
    canActivate: [
      InGastroGuard,
      OpenGuard,
      GastroActiveGuard,
      MenuAvailableGuard,
      OrderingAvailableGuard,
    ],
    data: { preload: true },
    loadChildren: () =>
      import('./restaurant-page/restaurant-page.module').then(
        (m) => m.RestaurantPagePageModule
      ),
    path: 'restaurant-page',
  },
  {
    canActivate: [
      InGastroGuard,
      OpenGuard,
      GastroActiveGuard,
      CartGuard,
      MenuAvailableGuard,
    ],
    data: {
      preload: true,
    },
    loadChildren: () =>
      import('./cart/cart.module').then((m) => m.CartPageModule),
    path: 'cart',
  },
  {
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
    path: 'register',
  },
  {
    data: {
      animation: 'Locations',
      preload: true,
    },
    loadChildren: () =>
      import('./locations/locations.module').then((m) => m.LocationsPageModule),
    path: 'locations',
  },
  {
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutPageModule),
    path: 'checkout',
  },
  {
    data: {
      animation: 'Home2',
      preload: true,
    },
    loadChildren: () =>
      import('./home2/home2.module').then((m) => m.Home2PageModule),
    path: 'home2',
  },
  {
    canActivate: [InGastroGuard, OpenGuard, GastroActiveGuard],
    loadChildren: () =>
      import('./dish-detail/dish-detail.module').then(
        (m) => m.DishDetailPageModule
      ),
    path: 'dish-detail',
  },
  {
    loadChildren: () =>
      import('./intro/intro.module').then((m) => m.IntroPageModule),
    path: 'intro',
  },
  {
    canActivate: [
      InGastroGuard,
      OpenGuard,
      GastroActiveGuard,
      MenuAvailableGuard,
    ],
    loadChildren: () =>
      import('./status/status.module').then((m) => m.StatusPageModule),
    path: 'status',
  },
  {
    loadChildren: () =>
      import('./code-login/code-login.module').then(
        (m) => m.CodeLoginPageModule
      ),
    path: 'code-login',
  },
  {
    canActivate: [NegateOpenGuard],
    loadChildren: () =>
      import('./closed/closed.module').then((m) => m.ClosedPageModule),
    path: 'closed',
  },
  {
    loadChildren: () =>
      import('./inactive/inactive.module').then((m) => m.InactivePageModule),
    path: 'inactive',
  },
  {
    loadChildren: () =>
      import('./qrcode-scanner/qrcode-scanner.module').then(
        (m) => m.QrcodeScannerPageModule
      ),
    path: 'qrcode-scanner',
  },
  {
    loadChildren: () =>
      import('./select-location/select-location.module').then(
        (m) => m.SelectLocationPageModule
      ),
    path: 'select-location',
  },
  {
    loadChildren: () => import('./go/go.module').then((m) => m.GoPageModule),
    path: 'go/:id',
  },
  {
    loadChildren: () => import('./go/go.module').then((m) => m.GoPageModule),
    path: 'go/:id/:inhouse',
  },
  {
    loadChildren: () => import('./go/go.module').then((m) => m.GoPageModule),
    path: 'go/:id/:inhouse/:table',
  },
  {
    loadChildren: () =>
      import('./feedback/feedback.module').then((m) => m.FeedbackPageModule),
    path: 'feedback',
  },
  {
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    path: 'home/:id',
  },
  {
    loadChildren: () =>
      import('./referral/referral.module').then((m) => m.ReferralPageModule),
    path: 'referral/:id',
  },
  {
    loadChildren: () =>
      import('./open-invoice.modal/open-invoice.modal.module').then(
        (m) => m.OpenInvoiceModalPageModule
      ),
    path: 'open-invoice.modal',
  },
  {
    loadChildren: () =>
      import('./user-register/user-register.module').then(
        (m) => m.UserRegisterPageModule
      ),
    path: 'user-register',
  },
  {
    loadChildren: () =>
      import('./user-login/user-login.module').then(
        (m) => m.UserLoginPageModule
      ),
    path: 'user-login',
  },
  {
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./profile-entry-modal/profile-entry-modal.module').then(
        (m) => m.ProfileEntryModalPageModule
      ),
    path: 'profile-modal',
  },
  {
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./userprofilearea-modal/userprofilearea-modal.module').then(
        (m) => m.UserprofileareaModalPageModule
      ),
    path: 'profile-area',
  },
  {
    loadChildren: () =>
      import('./usernotloggedinpopover/usernotloggedinpopover.module').then(
        (m) => m.UsernotloggedinpopoverPageModule
      ),
    path: 'usernotloggedinpopover',
  },
  {
    loadChildren: () =>
      import('./coupon-select-popover/coupon-select-popover.module').then(
        (m) => m.CouponSelectPopoverPageModule
      ),
    path: 'coupon-select-popover',
  },
  {
    canActivate: [LoginGuard],
    loadChildren: () =>
      import(
        './userprofilearea-modal/userprofileareaPages/statistics/statistics.module'
      ).then((m) => m.StatisticsPageModule),
    path: 'statistics',
  },
  {
    loadChildren: () =>
      import('./select-location/select-location.module').then(
        (m) => m.SelectLocationPageModule
      ),
    path: 'select-location/:location/:tableNr',
  },
  {
    loadChildren: () =>
      import('./corona-form/corona-form.module').then(
        (m) => m.CoronaFormPageModule
      ),
    path: 'corona-form',
  },
  {
    loadChildren: () =>
      import('./tipping-modal/tipping-modal.module').then(
        (m) => m.TippingModalPageModule
      ),
    path: 'tipping-modal',
  },
  {
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundPageModule
      ),
    path: 'page-not-found',
  },
  {
    loadChildren: () =>
      import('./vytal-modal/vytal-modal.module').then(
        (m) => m.VytalModalPageModule
      ),
    path: 'vytal-modal',
  },
  {
    loadChildren: () =>
      import('./impressum/impressum.module').then((m) => m.ImpressumPageModule),
    path: 'impressum',
  },
  {
    loadChildren: () =>
      import('./age-verification/age-verification.module').then(
        (m) => m.AgeVerificationPageModule
      ),
    path: 'age-verification',
  },
  {
    loadChildren: () =>
      import('./PayAtTable/table-content/table-content.module').then(
        (m) => m.TableContentPageModule
      ),
    path: 'table-content',
  },
  {
    loadChildren: () =>
      import('./PayAtTable/end-of-interaction/end-of-interaction.module').then(
        (m) => m.EndOfInteractionPageModule
      ),
    path: 'end-of-interaction',
  },
  {
    loadChildren: () =>
      import('./PayAtTable/receipt/receipt.module').then(
        (m) => m.ReceiptPageModule
      ),
    path: 'receipt',
  },
  {
    loadChildren: () =>
      import(
        './PayAtTable/table-content/modals/payment-method-modal/payment-method-modal.module'
      ).then((m) => m.PaymentMethodModalPageModule),
    path: 'payment-method-modal',
  },
]

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [AppRoutingPreloaderService],
})
export class AppRoutingModule {}
