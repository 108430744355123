<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="this.closeModal()">
        <ion-icon name="arrow-back-outline"> </ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{gastro?.name}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row>
    <ion-card style="width: 100%">
      <ion-card-title class="cardTitle"
        ><ion-icon style="margin-right: 10px" name="home-outline"></ion-icon
        >Adresse</ion-card-title
      >
      <ion-card-content>
        <p>{{gastro?.address.streetname}} {{gastro?.address.housenumber}}</p>
        <p>{{gastro?.address.PLZ}} {{gastro?.address.city}}</p>
      </ion-card-content>
    </ion-card>
  </ion-row>
  <!--Öffnungszeiten-->
  <ion-row>
    <ion-card style="width: 100%">
      <ion-card-title class="cardTitle"
        ><ion-icon style="margin-right: 10px" name="timer-outline"></ion-icon>
        Öffnungszeiten</ion-card-title
      >
      <ion-card-content>
        <ion-row *ngFor="let day of days" class="times">
          <ion-col style="font-weight: 700" size="10">{{day}}:</ion-col>
          <ion-col *ngIf="checkOpening('openingHours', day)"
            >Geschlossen</ion-col
          >
          <div *ngFor="let entry of gastro?.openingHours let i = index">
            <ion-col *ngIf="parseDay(entry.day)==day"
              >{{entry.from}}-{{entry.to}}</ion-col
            >
          </div>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-row>

  <ion-row style="display: flex; justify-content: space-evenly">
    <ion-col>
      <ion-card>
        <ion-card-title class="cardTitle"
          ><ion-icon
            style="margin-right: 10px"
            name="bicycle-outline"
          ></ion-icon
          >Lieferzeiten</ion-card-title
        >
        <ion-card-content>
          <ion-row *ngFor="let day of days" class="times">
            <ion-col style="font-weight: 700" size="8">{{day}}:</ion-col>
            <ion-col *ngIf="checkOpening('deliveryTimes', day)"
              >Geschlossen</ion-col
            >
            <div *ngFor="let entry of gastro?.deliveryTimes let i = index">
              <ion-col *ngIf="parseDay(entry.day)==day"
                >{{entry.from}}-{{entry.to}}</ion-col
              >
            </div>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col>
      <ion-card>
        <ion-card-title class="cardTitle"
          ><ion-icon style="margin-right: 10px" name="walk-outline"></ion-icon>
          Abholzeiten</ion-card-title
        >
        <ion-card-content>
          <ion-row *ngFor="let day of days" class="times">
            <ion-col style="font-weight: 700" size="8">{{day}}:</ion-col>
            <ion-col *ngIf="checkOpening('pickupTimes', day)"
              >Geschlossen</ion-col
            >
            <div *ngFor="let entry of gastro?.pickupTimes let i = index">
              <ion-col *ngIf="parseDay(entry.day)==day"
                >{{entry.from}}-{{entry.to}}</ion-col
              >
            </div>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-content>
