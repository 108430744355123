import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Category, Product } from 'src/shared/split-submodules/types/types'

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private availableLanguages: {
    languageCode: string
    documentId: string
  }[] = []

  public get availableLanguageCodes() {
    return this.availableLanguages.map((elem) => elem.languageCode)
  }

  public currentMenuTranslation = {
    products: {},
    categories: {},
    extras: {},
  }
  public currentLanguageCode
  public defaultLanguageCode

  constructor(private afs: AngularFirestore) {}

  public async init(gastro) {
    const gastroId = gastro.id
    this.defaultLanguageCode = gastro.languageCode
    this.currentLanguageCode = this.defaultLanguageCode
    this.availableLanguages = []
    const languagesCollection = await this.afs
      .collection('gastro')
      .doc(gastroId)
      .collection('languages')
      .get()
      .toPromise()
    for (const languageDoc of languagesCollection.docs) {
      const data = languageDoc.data()
      this.availableLanguages.push({
        languageCode: data.languageCode,
        documentId: languageDoc.id,
      })
    }
  }

  public async loadLanguage(languageCode: string, gastroId) {
    if (this.currentLanguageCode === languageCode) {
      return
    }

    if (languageCode === this.defaultLanguageCode) {
      this.currentMenuTranslation = {
        products: {},
        categories: {},
        extras: {},
      }
      this.currentLanguageCode = languageCode
      return
    }

    const languageElem = this.availableLanguages.find(
      (elem) => elem.languageCode === languageCode
    )

    if (languageElem === undefined) {
      return
    }

    this.currentLanguageCode = languageCode

    const gastroRef = this.afs.collection('gastro').doc(gastroId)
    const languageRef = gastroRef
      .collection('languages')
      .doc(languageElem.documentId)

    const productTranslationCollection = await languageRef
      .collection('products')
      .get()
      .toPromise()

    for (const productTranslationDoc of productTranslationCollection.docs) {
      const data = productTranslationDoc.data()
      this.currentMenuTranslation.products = data.products
    }

    const categoryTranslationCollection = await languageRef
      .collection('categories')
      .get()
      .toPromise()

    for (const categoryTranslationDoc of categoryTranslationCollection.docs) {
      const data = categoryTranslationDoc.data()
      this.currentMenuTranslation.categories = data.categories
    }

    const extraTranslationCollection = await languageRef
      .collection('extras')
      .get()
      .toPromise()

    for (const extraTranslationDoc of extraTranslationCollection.docs) {
      const data = extraTranslationDoc.data()
      this.currentMenuTranslation.extras = data.extras
    }
  }

  public getProductTranslation(product: Product): {
    name: string
    description: string
  } {
    const ret = {
      name: product.name,
      description: product.description,
    }

    const menuTranslation = this.currentMenuTranslation
    if (menuTranslation === undefined) {
      return ret
    }

    const productTranslation = menuTranslation.products[product.id]

    if (productTranslation === undefined) {
      return ret
    }

    if (productTranslation.name !== '') {
      ret.name = productTranslation.name
    }

    if (productTranslation.description !== '') {
      ret.description = productTranslation.description
    }

    return ret
  }

  public getCategoryTranslation(category: Category): {
    name: string
    description: string
  } {
    const ret = {
      name: category.name,
      description: category.description,
    }

    const menuTranslation = this.currentMenuTranslation
    if (menuTranslation === undefined) {
      return ret
    }

    const categoryTranslation = menuTranslation.categories[category.id]

    if (categoryTranslation === undefined) {
      return ret
    }

    if (categoryTranslation.name !== '') {
      ret.name = categoryTranslation.name
    }

    if (categoryTranslation.description !== '') {
      ret.description = categoryTranslation.description
    }

    return ret
  }

  public getExtraTranslation(extraId: string) {
    const menuTranslation = this.currentMenuTranslation
    if (menuTranslation === undefined) {
      return {
        description: '',
        extraCategories: [],
      }
    }

    const extraTranslation = menuTranslation.extras[extraId]
    return extraTranslation
  }
}
