import { Component, OnInit } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import {
  ActivationStart,
  Router,
  RouterModule,
  RouterOutlet,
  Routes,
} from '@angular/router'
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
} from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { UserService } from 'src/app/services/user.service'
import { UserprofileareaModalPage } from 'src/app/userprofilearea-modal/userprofilearea-modal.page'

@Component({
  selector: 'app-profile-entry-modal',
  templateUrl: './profile-entry-modal.page.html',
  styleUrls: ['./profile-entry-modal.page.scss'],
})
export class ProfileEntryModalPage implements OnInit {
  state = 'login'
  logInEmail = ''
  logInPW = ''
  logInPersist = false
  errorMessage = ''
  resetPswdEmail = ''
  regName
  regLastname
  regEmail
  regPW
  regrePW
  regDateOfBirth
  acceptUserTosAndDs = false

  constructor(
    public alertController: AlertController,
    public router: Router,
    public viewCtrl: ModalController,
    public userService: UserService,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public afs: AngularFirestore
  ) {}

  ngOnInit() {}
  async presentProfileAreaAlert() {} /**
   * dismisses the login modal
   */
  dismiss() {
    this.viewCtrl.dismiss()
  }

  /**
   * makes the userService log the user out and sets the state back to the initial one
   */
  logOut() {
    this.userService.signOut().then((e) => {
      this.changeProfileViewState('login')
    })
  }

  /**
   *  is given a destination string and after confirming the alert, calls the router in roder to navigate to the desired page
   * @param destination
   */
  async profileGoTo(destination: string) {
    const alert = await this.alertController.create({
      buttons: [
        {
          cssClass: 'secondary',
          handler: (blah) => {},
          role: 'cancel',
          text: 'Cancel',
        },
        {
          handler: () => {
            this.viewCtrl.dismiss()
            this.router.navigateByUrl(`/profile-area/${destination}`)
          },
          text: 'Okay',
        },
      ],
      cssClass: 'my-custom-class',
      header: 'Achtung',
      message: 'Hiermit werden sie den Bestellprozess verlassen!',
      subHeader: '',
    })

    await alert.present()

    // })
  }
  /**
   * used to track on which tab the user is in when opening the login screen
   * @param state
   */
  changeProfileViewState(state) {
    this.errorMessage = ''
    this.state = state
  }

  /**
   * hands over login credentials to the userservice, which in part returns a login attempt
   * @param email
   * @param pw
   * @param persist
   */
  logIn(email, pw, persist) {
    this.userService
      .signIn(email, pw, persist)
      .then((loginAttempt) => {
        if (loginAttempt == true) {
          this.state = 'entry'
        } else {
          this.errorMessage = 'Unbekannter Fehler'
        }
      })
      .catch((e) => {
        this.errorMessage = this.userService.authErrorsParser(e)
      })
  }

  /**
   * Checks for correct input fields for registration and if all are positive, gives the info over to the userService
   * any errors are displayed with a message
   * @param name
   * @param lastname
   * @param email
   * @param pw
   * @param rePw s
   */
  async signUp(name, lastname, email, pw, rePw /*, dateOfBirth*/) {
    if (
      name == undefined ||
      name == '' ||
      lastname == undefined ||
      lastname == '' ||
      email == undefined ||
      email == '' ||
      this.acceptUserTosAndDs === false
    ) {
      this.errorMessage = 'Alle Felder müssen ausgefüllt sein!'
    } else if (pw.length <= 5) {
      this.errorMessage = 'Dein Passwort muss mind. 6 Zeichen enthalten!'
    } else if (pw == rePw) {
      this.userService
        .register(name, lastname, email, pw /*, dateOfBirth*/)
        .then(async (registerAttempt) => {
          if (registerAttempt == true) {
            this.changeProfileViewState('entry')
          } else {
            this.errorMessage =
              this.userService.authErrorsParser(registerAttempt)
            if (registerAttempt == 'auth/email-already-in-use') {
              this.checkIfVinoUserExistsAlready(email, pw, rePw)
            }
          }
        })
    } else {
      this.errorMessage = 'Dein Passwort stimmt nicht überein!'
    }
  }

  /**
   * awaits the input of an emailAddress in order to send the password Reset link. If the field is filled,
   * it triggers the corresponding function of userService
   */
  resetPswd() {
    if (!this.resetPswdEmail) {
      this.errorMessage = 'Bitte geben Sie Ihre Email ein'
    } else {
      this.userService
        .resetUserPswd(this.resetPswdEmail)
        .then(
          async () => {
            this.dismiss()
            const alert = await this.alertController.create({
              buttons: [
                {
                  handler: () => {
                    this.changeProfileViewState('entry')
                    this.resetPswdEmail = ''
                    console.log('Confirm Okay')
                  },
                  text: 'Okay',
                },
              ],
              cssClass: 'my-custom-class',
              header: 'Achtung',
              message:
                'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet!',
              subHeader: '',
            })
            await alert.present()
          },
          async (rejectionReason) => {
            this.errorMessage = this.userService.authErrorsParser(
              rejectionReason.code
            )
          }
        )
        .catch(
          (e) =>
            (this.errorMessage =
              'Beim Versuch Ihr Passwort zurückzusetzen, ist ein Fehler aufgetreten')
        )
    }
  }

  async checkIfVinoUserExistsAlready(email, pw, rePw) {
    const userDos = await this.afs
      .collection('customer-profiles', (ref) => ref.where('name', '==', email))
      .get()
      .toPromise()
    if (userDos.empty) {
      const userDocs2 = await this.afs
        .collection('customer-profiles', (ref) =>
          ref.where('email', '==', email).where('vinoUser', '==', true)
        )
        .get()
        .toPromise()
      if (!userDocs2.empty) {
        const user: any = userDocs2.docs[0].data()
        await this.userService.signIn(user.email, user.email, true)
        await this.userService.changePW(user.email, pw, rePw, 2)
      }
    } else {
      const user: any = userDos.docs[0].data()
      const test = await this.userService.signIn(email, user.password, true)
      await this.userService.changePW(user.password, pw, rePw, 1)
    }
  }
}
