import { Component, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-article-skeleton',
  styleUrls: ['./article-skeleton.component.scss'],
  templateUrl: './article-skeleton.component.html',
})
export class ArticleSkeletonComponent {
  constructor() {
    return
  }
}
