<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="this.closeModal()">
        <ion-icon name="arrow-back-outline"> </ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{this.getProductTranslation(dish).name}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <img
    *ngIf="this.dish.img != undefined && this.dish.img != ''"
    [src]="this.dish.img"
    style="
      display: block;
      height: 150px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
    "
  />
  <ion-row *ngIf="this.extra.info" style="margin-top: 8px">
    <ion-col>
      <h4>Beschreibung</h4>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="this.extra.info" style="margin-top: 4px">
    <ion-col>
      <span>{{this.extra.info}}</span>
    </ion-col>
  </ion-row>
  <ng-container
    *ngFor="let cat of this.extra.extraCategories; let categoryIndex = index;"
  >
    <ng-container *ngIf="cat.kind == 3">
      <div style="font-size: 1.1em; font-weight: 300; margin: 16px">
        <!-- in case that there is just a short descritpion -->
        <ng-container>
          <div
            id="desc-container"
            [class.trimmed-description]="this.showMore===false"
            [class.full-description]="this.showMore===true"
          >
            <ion-row
              *ngFor="let descriptionItem of splitDescritption(cat.description)"
            >
              {{descriptionItem}}
            </ion-row>
          </div>
          <ion-row
            *ngIf="this.showMore === false && this.calculateOverflow() === true"
          >
            <ion-label color="primary" (click)="this.showMore=true"
              >Mehr Anzeigen</ion-label
            >
          </ion-row>
          <ion-row *ngIf="this.showMore === true">
            <ion-label color="primary" (click)="this.showMore=false"
              >Weniger Anzeigen</ion-label
            >
          </ion-row>
        </ng-container>
      </div>
    </ng-container>
    <ion-list *ngIf="this.categoryHasItems(cat) && cat.kind != 3">
      <ion-list-header [id]="'category' + categoryIndex.toString()">
        <ion-label
          [ngClass]="{'error': categoryIndex === this.errorInCategoryIndex}"
          class="ion-text-wrap"
          >{{this.getExtraCategoryTranslation(categoryIndex).name}}</ion-label
        >
      </ion-list-header>
      <ion-item
        *ngIf="cat.description != undefined && cat.description != ''"
        lines="full"
      >
        <ion-label class="ion-text-wrap"
          >{{this.getExtraCategoryTranslation(categoryIndex).description}}</ion-label
        >
      </ion-item>
      <!--Dropdown-->
      <ng-container *ngIf="cat.kind == 0">
        <ion-radio-group [value]="getSelected(cat)">
          <ng-container
            *ngFor="let item of cat.extraItems, let itemIndex = index"
          >
            <ion-item
              style="margin-left: 16px"
              lines="none"
              *ngIf="this.sessionDataService.$inhouseLink ? item.inhouseVisible : item.outerhouseVisible"
              (click)="selectItemInDropdown(cat.extraItems, item)"
            >
              <ion-label>
                <ion-row>
                  <ion-col class="ion-text-wrap">
                    {{this.getExtraItemTranslation(categoryIndex,
                    itemIndex).name}}
                  </ion-col>
                  <ion-col
                    style="text-align: end"
                    *ngIf="this.sessionDataService.$inhouseLink && item.inhousePrice > 0 ||
					!this.sessionDataService.$inhouseLink && item.outerhousePrice > 0"
                  >
                    {{(this.sessionDataService.$inhouseLink ? item.inhousePrice
                    : item.outerhousePrice) | price:getPriceFormat():true}}
                  </ion-col>
                </ion-row>
              </ion-label>
              <ion-radio
                *ngIf="!this.menuService.isUnclickable()"
                slot="start"
                [value]="item"
              ></ion-radio>
            </ion-item>
          </ng-container>
        </ion-radio-group>
      </ng-container>
      <!--Checkbox-->
      <ng-container *ngIf="cat.kind == 1">
        <ion-item
          *ngIf="cat.maxCount != undefined || (cat.free!=undefined && cat.free!=0)"
          lines="full"
        >
          <ion-label
            class="minmax"
            *ngIf="cat.maxCount != undefined && cat.maxCount !== 0 && menuService.isUnclickable() !== true"
            >{{this.getCountInCheckbox(cat)}} von maximal {{cat.maxCount}}
            gewählt</ion-label
          >
          <ion-label class="minmax" *ngIf="cat.free > 0"
            >{{this.min(this.getCountInCheckbox(cat), cat.free)}} von
            {{cat.free}} frei</ion-label
          >
        </ion-item>
        <ng-container
          *ngFor="let item of cat.extraItems, let itemIndex = index"
        >
          <ion-item
            style="margin-left: 16px"
            *ngIf="this.sessionDataService.$inhouseLink ? item.inhouseVisible : item.outerhouseVisible"
            lines="none"
            (click)="checkItemInCheckbox(cat, item)"
          >
            <ion-checkbox
              [disabled]="cat.maxCount!=undefined && cat.maxCount!=0 && item.selected === false && getCountInCheckbox(cat)>=cat.maxCount"
              *ngIf="!this.menuService.isUnclickable()"
            >
            </ion-checkbox>
            <ion-label>
              <ion-row>
                <ion-col class="ion-text-wrap">
                  {{this.getExtraItemTranslation(categoryIndex,
                  itemIndex).name}}
                </ion-col>
                <ion-col
                  style="text-align: end"
                  *ngIf="this.getCountInCheckbox(cat)>=cat.free"
                >
                  {{(this.sessionDataService.$inhouseLink ? item.inhousePrice :
                  item.outerhousePrice) | price:getPriceFormat():true}}
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>
        </ng-container>
      </ng-container>
      <!--PlusMinus-->
      <ng-container *ngIf="cat.kind == 2">
        <ion-item style="margin-left: 16px">
          <ion-label
            *ngIf="cat.maxCount !== undefined && cat.maxCount !== 0 && menuService.isUnclickable() !== true"
            class="minmax"
            >{{this.getCountInPlusMinus(cat)}} von maximal {{cat.maxCount}}
            gewählt</ion-label
          >
          <ion-label class="minmax" *ngIf="cat.free > 0"
            >{{this.min(this.getCountInPlusMinus(cat), cat.free)}} von
            {{cat.free}} frei</ion-label
          >
        </ion-item>
        <ng-container
          *ngFor="let item of cat.extraItems, let itemIndex = index"
        >
          <ion-item
            style="margin-left: 16px"
            *ngIf="this.sessionDataService.$inhouseLink ? item.inhouseVisible : item.outerhouseVisible"
            lines="none"
          >
            <ion-label>
              <ion-row>
                <ion-col class="ion-text-wrap">
                  {{this.getExtraItemTranslation(categoryIndex,
                  itemIndex).name}}
                </ion-col>
                <ion-col style="text-align: end">
                  <ion-button
                    *ngIf="item.count > 0 && !this.menuService.isUnclickable()"
                    style="
                      border-width: 20px;
                      font-size: 10px;
                      height: 30px;
                      width: 30px;
                    "
                    fill="outline"
                    (click)="this.incrCountInPlusMinus(cat, item, -1)"
                    >-</ion-button
                  >
                  <ng-container *ngIf="item.count > 0"
                    >{{item.count}} * </ng-container
                  ><ng-container
                    *ngIf="this.getCountInPlusMinus(cat)>=cat.free"
                  >
                    {{(this.sessionDataService.$inhouseLink ? item.inhousePrice
                    : item.outerhousePrice) |
                    price:getPriceFormat():true}}</ng-container
                  >
                  <ion-button
                    *ngIf="shouldDisplayPlus(cat)"
                    style="height: 30px; width: 30px"
                    fill="outline"
                    (click)="this.incrCountInPlusMinus(cat, item, +1)"
                    >+</ion-button
                  >
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>
        </ng-container>
      </ng-container>
    </ion-list>
  </ng-container>
  <ng-container
    *ngIf="!this.menuService.isUnclickable() && this.gastroService.$gastro.hasNoExtraWishes !== true"
  >
    <ion-list-header>
      <ion-label>Bemerkung hinzufügen</ion-label>
    </ion-list-header>
    <ion-item lines="none" style="margin-bottom: 16px">
      <ion-textarea
        style="border: 1px solid rgb(209 209 209 / 85.6%); padding-left: 16px"
        [rows]="2"
        placeholder="Platz für Deine Sonderwünsche =)"
        [maxlength]="100"
        [(ngModel)]="note"
      ></ion-textarea>
    </ion-item>
  </ng-container>
</ion-content>
<ion-footer>
  <ion-toolbar *ngIf="!this.menuService.isUnclickable()">
    <ion-button (click)="toCart()" fill="clear" expand="full">
      <ion-icon
        style="
          filter: invert(100%) sepia(0%) saturate(1352%) hue-rotate(87deg)
            brightness(119%) contrast(119%);
        "
        src="../../assets/icons/cart-outline.svg"
      ></ion-icon>
      Zum Warenkorb hinzufügen | {{getPrice() |
      currency:'EUR':'symbol-narrow':'1.2-2':'de'}}</ion-button
    >
  </ion-toolbar>
</ion-footer>
