import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Timestamp } from 'firebase/firestore'
import {
  JoinSmartBatchingSessionRequest,
  JoinSmartBatchingSessionResponse,
  Session,
  SessionUser,
} from 'functions/src/orderbird/cloudPrinting/types'
import { BehaviorSubject, lastValueFrom, timeout } from 'rxjs'
import { Subscription } from 'rxjs/internal/Subscription'
import { environment } from 'src/environments/environment'
import { DeviceService } from './device.service'

@Injectable({
  providedIn: 'root',
})
export class SessionService implements OnDestroy {
  private _session: BehaviorSubject<Session> = new BehaviorSubject<Session>(
    undefined
  )
  public session$ = this._session.asObservable()
  public get session() {
    return this._session.value
  }
  private sessionSubscription: Subscription

  private _sessionUser: BehaviorSubject<SessionUser> =
    new BehaviorSubject<SessionUser>(undefined)
  public sessionUser$ = this._sessionUser.asObservable()
  public get sessionUser() {
    return this._sessionUser.value
  }
  private sessionUserSubscription: Subscription

  constructor(
    private afs: AngularFirestore,
    private deviceService: DeviceService,
    private httpClient: HttpClient
  ) {}

  /**
   * Joins or creates a new session
   *
   * @param gastroId
   * In which gastro a session should be joined
   * @param tableId
   * The table on which to start or join a session
   */
  public async joinSession(gastroId: string, tableId: string) {
    if (!gastroId || !tableId) {
      return
    }
    const url = `${environment.functionsUrlEU}/joinSmartBatchingSession`
    const data: JoinSmartBatchingSessionRequest = {
      gastroId: gastroId,
      tableId: tableId,
      userData: {
        deviceId: this.deviceService.getDeviceID(),
      },
    }

    try {
      const responseData = await lastValueFrom(
        this.httpClient
          .post<JoinSmartBatchingSessionResponse>(url, data)
          .pipe(timeout(10000))
      )
      this.subscribeToSession(responseData.session)
      this.subscribeToUser(responseData.user)
      return responseData
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Sets the status property of the user to 'active'
   * and updates the lastActivity property
   *
   * Only updates the user if the lastActivity property is older than 15 seconds
   *
   * @param gastroId
   * The id of the restaurant.
   * Fallback parameter needed in case the session is not set on the client yet.
   *
   * @param tableId
   * The id of the table.
   * Fallback parameter needed in case the session is not set on the client yet.
   *
   * @returns
   */
  public async activateUser(gastroId: string, tableId: string) {
    if (
      !this.sessionUser ||
      !this.session ||
      this.session.status === 'closed'
    ) {
      await this.joinSession(gastroId, tableId)
    }

    const bufferTime = new Date()
    bufferTime.setSeconds(bufferTime.getSeconds() - 15)
    if (
      this.sessionUser.status === 'active' &&
      this.sessionUser.lastActivity.toDate() > bufferTime
    ) {
      return
    }

    return this.afs
      .collection('gastro')
      .doc(this.sessionUser.gastroId)
      .collection('table')
      .doc(this.sessionUser.tableId)
      .collection('session')
      .doc(this.sessionUser.sessionId)
      .collection<SessionUser>('users')
      .doc(this.sessionUser.id)
      .update({
        lastActivity: Timestamp.fromDate(new Date()),
        status: 'active',
      })
  }

  /**
   * Subscribes to the session
   *
   * @param session
   * The session to subscribe to
   * @returns
   */
  private subscribeToSession(session: Session) {
    if (session.id === this._session.value?.id) {
      return
    }

    this._session.next(session)
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe()
    }
    this.sessionSubscription = this.afs
      .collection('gastro')
      .doc(session.gastroId)
      .collection('table')
      .doc(session.tableId)
      .collection<Session>('session')
      .doc(session.id)
      .valueChanges()
      .subscribe((session) => {
        this._session.next(session)
        if (session.status === 'closed') {
          this.unsubscribe()
        }
      })
  }

  /**
   * Subscribes to the SessionUser
   *
   * @param user
   * The user to subscribe to
   * @returns
   */
  private subscribeToUser(user: SessionUser) {
    if (user.id === this._sessionUser.value?.id) {
      return
    }

    this._sessionUser.next(user)
    if (this.sessionUserSubscription) {
      this.sessionUserSubscription.unsubscribe()
    }

    this.sessionSubscription = this.afs
      .collection('gastro')
      .doc(user.gastroId)
      .collection('table')
      .doc(user.tableId)
      .collection('session')
      .doc(user.sessionId)
      .collection<SessionUser>('users')
      .doc(user.id)
      .valueChanges()
      .subscribe((user) => {
        this._sessionUser.next(user)
      })
  }

  /**
   * Unsubscribes from all subscriptions
   */
  private unsubscribe() {
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe()
    }
    if (this.sessionUserSubscription) {
      this.sessionUserSubscription.unsubscribe()
    }
  }

  public ngOnDestroy() {
    this.unsubscribe()
  }
}
