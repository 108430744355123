import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { StorageService } from 'src/app/services/storage.service'

@Injectable({ providedIn: 'root' })
export class OpenGuard implements CanActivate {
  constructor(
    public router: Router,
    public storageService: StorageService,
    public db: AngularFirestore
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve) => {
      const id = await this.storageService.load('gastroId')
      if (id != undefined && id != null) {
        resolve(true)
      } else {
        this.router.navigate(['home'])
        resolve(false)
      }
    })
  }
}
