import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Gastro } from 'src/shared/split-submodules/types/types'
import { GastroService } from './../services/gastro.service'

@Injectable({ providedIn: 'root' })
export class MenuAvailableGuard implements CanActivate {
  constructor(
    public gastroService: GastroService,
    public router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve) => {
      this.gastroService.gastroLoadedSubject.subscribe((gastroLoaded) => {
        if (gastroLoaded === true) {
          const gastro: Gastro = this.gastroService.gastro
          if (
            gastro.isMenuAvailable == true ||
            gastro.isMenuAvailable == undefined
          ) {
            resolve(true)
            return
          } else {
            this.router.navigate(['table-content'])
            resolve(false)
            return
          }
        } else if (gastroLoaded === false) {
          //check for false is important to ignore the initial value of undefined which is just present while reloading the page
          this.router.navigate(['home'])
          resolve(false)
        }
      })
    })
  }
}
