import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { PaymentMethodModalPage } from './payment-method-modal.page'
import { PaymentMethodModalPageRoutingModule } from './payment-method-modal-routing.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PaymentMethodModalPageRoutingModule,
  ],
  declarations: [PaymentMethodModalPage],
})
export class PaymentMethodModalPageModule {}
