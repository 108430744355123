<ion-header>
  <ion-toolbar>
    <ion-title>Corona-Infos</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <form name="coronaForm" #form="ngForm" (ngSubmit)="submitForm()">
          <ion-card>
            <ion-item>
              <ion-label position="stacked">Name</ion-label>
              <ion-input
                type="text"
                [(ngModel)]="name"
                name="personName"
                required
              ></ion-input>
            </ion-item>

            <ion-item>
              <ion-label position="stacked">Telefon-Nummer</ion-label>
              <ion-input
                type="text"
                [(ngModel)]="phone"
                name="personTel"
                required
              ></ion-input>
            </ion-item>

            <ion-item>
              <ion-label position="stacked">Adresse</ion-label>
              <ion-input
                type="text"
                [(ngModel)]="address"
                name="personAddress"
                required
              ></ion-input>
            </ion-item>

            <ion-item>
              <ion-label class="ion-text-wrap"
                >Daten für nächsten Besuch speichern?</ion-label
              >
              <ion-checkbox
                [(ngModel)]="saveCoronaInfosOnDevice"
                name="saveCoronaInfo"
              ></ion-checkbox>
            </ion-item>
            <!-- <ion-item>
              <ion-label class="ion-text-wrap"
                >Ich möchte gerne Gutscheine oder Infos zu Promotions
                erhalten</ion-label
              >
              <ion-checkbox
                [(ngModel)]="hasMarketing"
                name="saveCoronaInfoMarketing"
              ></ion-checkbox>
            </ion-item> -->

            <ion-item *ngIf="hasMarketing">
              <ion-label position="stacked">E-Mail</ion-label>
              <ion-input
                type="email"
                [(ngModel)]="emailAddress"
                name="emailAddress"
                required
                pattern="[A-ẞa-ß0-9._%+-]{3,}@[a-ßA-ẞ]{3,}([.]{1}[a-ßA-ẞ]{2,}|[.]{1}[a-ßA-ẞ]{2,}[.]{1}[a-ßA-ẞ]{2,})"
              >
              </ion-input>
            </ion-item>
          </ion-card>
          <ion-card *ngFor="let att of additionalPersons; let idx = index">
            <ion-label color="black"
              >Zusätzliche Person {{att.label}}{{idx+1}}</ion-label
            >

            <ion-item>
              <ion-label position="stacked">Name</ion-label>
              <ion-input
                type="text"
                [(ngModel)]="additionalPersons[idx].name"
                name="extraPersonName{{idx+1}}"
                required
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Telefon-Nummer</ion-label>
              <ion-input
                type="text"
                [(ngModel)]="additionalPersons[idx].phone"
                name="extraPersonTel{{idx+1}}"
                required
              ></ion-input>
            </ion-item>

            <ion-button
              *ngIf="idx === additionalPersons.length-1"
              (click)="removeAdditionalPerson(idx)"
              expand="full"
              color="danger"
            >
              Zusätzliche Person entfernen</ion-button
            >
          </ion-card>

          <ion-button
            (click)="addAdditionalPerson()"
            expand="full"
            color="gastro"
          >
            Zusätzliche Person hinfügen</ion-button
          >
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar color="success">
    <!-- <ion-button (click)="dismiss()" fill="clear" expand="full" color="light " -->
    <!-- <form name="coronaForm" #form="ngForm" (ngSubmit)="submitForm()"> -->
    <ion-button
      (click)="dismiss()"
      fill="clear"
      expand="full"
      color="light"
      type="submit"
      [disabled]="form.invalid"
    >
      Abschicken</ion-button
    >
  </ion-toolbar>
</ion-footer>
