import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'

/**
 * Modal component for displaying image and text content with a button(function should be passed).
 */
@Component({
  selector: 'app-modal-ob-img-text',
  templateUrl: './modal-ob-img-text.component.html',
  styleUrls: ['./modal-ob-img-text.component.scss'],
})
export class ModalObImgTextComponent implements OnInit {
  /** URL of the image to be displayed in the modal. */
  @Input() imgUrl: string

  /** Header text to be displayed in the modal. */
  @Input() header: string

  /** Label text for the dismissible button. */
  @Input() button: string

  /** Array of paragraphs to be displayed in the modal. */
  @Input() paragraphs: string[]

  /** Function to be called when the button is clicked. */
  @Input() buttonClickHandler: () => void = this.dismiss

  /**
   * Constructs a new instance of `ModalObImgTextComponent`.
   * @param modalControler The modal controller for managing the modal instance.
   */
  constructor(public modalControler: ModalController) {}

  /** Lifecycle hook that is called after data-bound properties have been initialized. */
  ngOnInit() {}

  /** Dismisses the modal. */
  dismiss() {
    this.modalControler.dismiss()
  }
}
