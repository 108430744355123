import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-gastro-info-modal',
  templateUrl: './gastro-info-modal.page.html',
  styleUrls: ['./gastro-info-modal.page.scss'],
})
export class GastroInfoModalPage implements OnInit {
  @Input() gastro

  public days = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
  ]

  constructor(public modalCtrl: ModalController) {}

  ngOnInit() {}

  ionViewWillEnter() {
    window.addEventListener('popstate', (e) => {
      this.closeModal()
    })

    if (!window.history.state.modal) {
      const modalState = { modal: true }
      history.pushState(modalState, null)
    }
  }

  public async closeModal() {
    const isModalOpened = await this.modalCtrl.getTop()
    if (isModalOpened) {
      this.modalCtrl.dismiss(null)
    }
  }

  checkOpening(type, day) {
    let a = true
    if (type === 'openingHours') {
      this.gastro.openingHours.forEach((entry) => {
        if (this.parseDay(entry.day) == day) {
          a = false
        }
      })
    }
    if (type === 'deliveryTimes') {
      this.gastro.deliveryTimes.forEach((entry) => {
        if (this.parseDay(entry.day) == day) {
          a = false
        }
      })
    }
    if (type === 'pickupTimes') {
      this.gastro.pickupTimes.forEach((entry) => {
        if (this.parseDay(entry.day) == day) {
          a = false
        }
      })
    }
    return a
  }

  parseDay(num) {
    //Wandelt Integer Werte in die Entsprechenden Wochentage
    switch (num) {
      case 0: {
        return 'Sonntag'
      }
      case 1: {
        return 'Montag'
      }
      case 2: {
        return 'Dienstag'
      }
      case 3: {
        return 'Mittwoch'
      }
      case 4: {
        return 'Donnerstag'
      }
      case 5: {
        return 'Freitag'
      }
      case 6: {
        return 'Samstag'
      }
    }
  }
}
