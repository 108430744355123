import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { StartOfInteractionPage } from './start-of-interaction.page'
import { StartOfInteractionPageRoutingModule } from './start-of-interaction-routing.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    StartOfInteractionPageRoutingModule,
  ],
  declarations: [StartOfInteractionPage],
})
export class StartOfInteractionPageModule {}
