import { Injectable } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Product } from 'src/shared/split-submodules/types/types'
import { CouponService } from './coupon.service'
import { DeviceService } from './device.service'
import { GastroService } from './gastro.service'
import { HistoryService } from './history.service'
import { ReportService } from './report.service'
import { GastroResettable, ResetService } from './reset.service'
import { SentryService } from './sentry.service'
import { SessionDataService } from './session-data.service'
import { StorageService } from './storage.service'
import { UtilService } from './util.service'

type ExtraSelection = ExtraSingleSelection | ExtraMultipleSelection
interface ExtraSingleSelection {
  description: string
  extra: ExtraSingleItemSelection[]
  free?: number
  kind: 0 | 1
  maxCount?: number
  name: string
  select?: string
  minCount?: number
}
interface ExtraMultipleSelection {
  description: string
  extra: ExtraMultipleItemSelection[]
  free?: number
  kind: 2
  maxCount?: number
  name: string
  select?: string
  minCount?: number
}

interface ExtraSingleItemSelection {
  selected: boolean
  inhousePrice: number
  outerhousePrice: number
}

interface ExtraMultipleItemSelection {
  count: number
  inhousePrice: number
  outerhousePrice: number
}

@Injectable({ providedIn: 'root' })
export class CartService implements GastroResettable {
  private cart = []
  public retItems = []
  public cartLoaded: Promise<any>
  public productsCount: number

  constructor(
    private utilService: UtilService,
    private gastroService: GastroService,
    private storageService: StorageService,
    private sessionDataService: SessionDataService,
    private deviceService: DeviceService,
    private couponService: CouponService,
    private historyService: HistoryService,
    private sentryService: SentryService,
    private reportService: ReportService,
    private resetService: ResetService,
    public modalController: ModalController
  ) {
    this.registerGastroReset()
    this.cartLoaded = new Promise((resolve) => {
      this.storageService.load('cart').then((storageCart) => {
        if (storageCart != null) {
          this.cart = storageCart
        }
        resolve(true)
      })
    })
  }

  public onGastroLogout(): void {
    this.$cart = []
    this.retItems = []
  }

  public registerGastroReset(): void {
    this.resetService.registerGastroReset(this)
  }

  public get $cart(): any[] {
    return this.cart
  }

  public set $cart(newCart: any[]) {
    this.cart = newCart
    this.storageService.store('cart', this.cart)
  }

  getAmountOfFoodInCart() {
    //TODO: use item.isFood or item.kind
    return this.$cart.reduce((total, item) => {
      total += item.isFood ? item.count : 0
      return total
    }, 0)
  }

  isProductInCart(product) {
    let found = false
    this.cart.forEach((element) => {
      if (product.name === element.name) {
        found = true
      }
    })
    return found
  }

  getProductInCartCount(product) {
    let count = 0
    this.cart.forEach((element) => {
      if (product.name === element.name && element.extras === undefined) {
        count = element.count
      }
    })
    return count
  }

  addProduct(product, extras: any = {}) {
    if (
      product.isFood === true &&
      this.gastroService.$gastro.hasPreTimePerFood
    ) {
      this.sessionDataService.initTimes(
        this.gastroService.$gastro,
        this.$cart,
        this.getAmountOfFoodInCart()
      )
    }

    //light copy the object because it gets overwrite in upper function
    product = JSON.parse(JSON.stringify(product))
    extras = JSON.parse(JSON.stringify(extras))

    if (extras.name !== undefined) {
      product.name = extras.name
      product.extras = extras.extras
      product.dropdown = extras.dropdown
      product.note = extras.note
      product.sorted = extras.sorted
      product.checkboxen = extras.checkboxen
    }

    let found = false

    //TODO: check if we can remove this
    this.cart.forEach((element, index) => {
      if (product.name === element.name && extras.name === undefined) {
        this.cart[index].count += 1
        found = true
      }
    })
    this.cart.forEach((element, index) => {
      if (
        product.name === element.name &&
        extras.name !== undefined &&
        this.utilService.objectEquals(product.extras, element.extras) &&
        this.utilService.objectEquals(product.checkboxen, element.checkboxen) &&
        this.utilService.objectEquals(product.dropdown, element.dropdown) &&
        product.note === element.note
      ) {
        if (!this.isProductInCart(product)) {
          this.cart[index] = product
        }
        this.cart[index].count += 1
        found = true
      }
    })

    if (!found) {
      product.count = 1
      this.cart.push(product)
    }
    this.storageService.store('cart', this.cart)
  }

  //Delete Button des aktuellen Produkts. Nimm Name des Produktes, suche in Array und lösche dieses Element.
  deleteProduct(product, extras = undefined, deleteThisItemCompletely = false) {
    for (let i = 0; i <= this.$cart.length; i++) {
      if (extras !== undefined) {
        if (
          product !== null &&
          product.name === this.cart[i].name &&
          extras.name !== undefined &&
          JSON.stringify(product.extras) ===
            JSON.stringify(this.cart[i].extras) &&
          JSON.stringify(product.checkboxen) ===
            JSON.stringify(this.cart[i].checkboxen) &&
          JSON.stringify(product.dropdown) ===
            JSON.stringify(this.cart[i].dropdown) &&
          product.note === this.cart[i].note
        ) {
          if (!this.isProductInCart(product)) {
            this.cart[i] = product
          }
          if (
            product.kind === 0 &&
            this.gastroService.$gastro.hasPreTimePerFood
          ) {
            this.sessionDataService.initTimes(
              this.gastroService.$gastro,
              this.$cart,
              this.getAmountOfFoodInCart()
            )
          }
          if (deleteThisItemCompletely === true) {
            this.cart.splice(i, 1)
          } else if (this.cart[i].count !== 1) {
            this.cart[i].count -= 1
          } else {
            this.cart.splice(i, 1)
          }
          break
        }
      } else if (product != null && this.cart[i].name === product.name) {
        if (!this.isProductInCart(product)) {
          this.cart[i] = product
        }
        if (
          product.kind === 0 &&
          this.gastroService.$gastro.hasPreTimePerFood
        ) {
          this.sessionDataService.initTimes(
            this.gastroService.$gastro,
            this.$cart,
            this.getAmountOfFoodInCart()
          )
        }
        if (deleteThisItemCompletely === true) {
          this.cart.splice(i, 1)
        } else if (this.cart[i].count !== 1) {
          this.cart[i].count -= 1
        } else {
          this.cart.splice(i, 1)
        }
        break
      }
    }
    this.$cart = this.cart
  }

  /**
   * Removes all items from the cart that share the same id with product (if an orderbird_id is defined it will try using this id instead)
   * @param product The referenced product which should be removed from the cart
   */
  public deleteAllProductReferences(product: Product) {
    if (product.addData?.ob_id === undefined) {
      this.$cart = this.$cart.filter((elem) => elem.id !== product.id)
    } else {
      this.$cart = this.$cart.filter(
        (elem) => elem.addData?.ob_id !== product.addData?.ob_id
      )
    }

    this.storageService.store('cart', this.cart)
  }

  async gastroEnter(gid) {
    console.log('gastroEnter in cart')
    this.deviceService.$logInTime = new Date()
    if (this.sessionDataService.$isGastroLoggedOut) {
      this.historyService.$allOrderedItemsIds = []
    }
    this.sessionDataService.$isGastroLoggedOut = false
    this.$cart = []
    // this.storage.get('didBar').then(val => {
    //     if (val == null) {
    //       //  this.sessionDataService.setDidBar(false); //TODO
    //     }
    // })
    this.couponService.resetCouponList()
    this.sentryService.setSentryContext({
      gastroID: gid,
      tableID: this.sessionDataService.$tableNr,
    })
    this.sentryService.setSentryTag('gastroID', gid)
    this.sentryService.setSentryTag('tableID', this.sessionDataService.$tableNr)
    this.sentryService.setSentryTag('version', '7.1')
    this.reportService.incrementUsersFromPlatformOrDirectLinkinReports(
      gid,
      this.sessionDataService.$comesFromPlatform
    ) //TODO
  }

  /**
   * Updates any [oldProducts] in the current cart with the name and price data of [newProduct]
   *
   * @param oldProduct The old version of the product which may be in the current cart
   * @param newProduct The new version of the product.
   */
  updateCartOnDataSyncChanges(oldProduct: Product, newProduct: Product) {
    const cartItems = this.$cart.filter((item) => {
      if (oldProduct.addData?.ob_id !== undefined) {
        return item.addData?.ob_id === oldProduct.addData?.ob_id
      }
      return item.id === oldProduct.id
    })

    for (const cartItem of cartItems) {
      cartItem.name = newProduct.name
      cartItem.inhousePrice = newProduct.inhousePrice
      cartItem.outerhousePrice = newProduct.outerhousePrice
      cartItem.price = this.recalculatePrice(newProduct, cartItem.extras)
    }

    this.storageService.store('cart', this.cart)
  }

  /**
   * calculates the price of a product with the given selected extras
   * @param product
   * @param extraSelection
   * @returns the total of the product base price with the price of any selected extras
   */
  private recalculatePrice(
    product: Product,
    extraSelection?: ExtraSelection[]
  ): number {
    const useInhousePrice = this.sessionDataService.$inhouseLink
    let total: number = useInhousePrice
      ? product.inhousePrice
      : product.outerhousePrice

    if (extraSelection === undefined) {
      return total
    }

    for (const extraCategory of extraSelection) {
      if (extraCategory.kind === 0 || extraCategory.kind === 1) {
        const sortedExtraItems = extraCategory.extra.slice().sort((a, b) => {
          if (useInhousePrice) {
            return a.inhousePrice - b.inhousePrice
          } else {
            return a.outerhousePrice - b.outerhousePrice
          }
        })
        for (let i = 0; i < extraCategory.free ?? 0; i++) {
          sortedExtraItems.shift()
        }

        for (const extra of sortedExtraItems) {
          if (extra.selected) {
            total += useInhousePrice
              ? extra.inhousePrice
              : extra.outerhousePrice
          }
        }
      }

      if (extraCategory.kind === 2) {
        const sortedExtraItems = extraCategory.extra.slice().sort((a, b) => {
          if (useInhousePrice) {
            return a.inhousePrice - b.inhousePrice
          } else {
            return a.outerhousePrice - b.outerhousePrice
          }
        })
        let remainingFreeItems = extraCategory.free ?? 0

        for (const extra of sortedExtraItems) {
          const freeItems = Math.min(remainingFreeItems, extra.count)
          total +=
            (extra.count - freeItems) *
            (useInhousePrice ? extra.inhousePrice : extra.outerhousePrice)
          remainingFreeItems -= freeItems
        }
      }
    }

    return total
  }

  getEarlyTime() {
    const ret = this.sessionDataService.getColumnsForTimesDelivery(
      this.gastroService.$gastro,
      this.$cart,
      this.getAmountOfFoodInCart()
    )
    if (ret[0] && ret[0].options[1]) {
      return ret[0].options[1].text
    } else {
      return ''
    }
  }

  getSumOfPrices() {
    let sum = 0
    for (const c of this.cart) {
      sum += c.price * c.count
    }
    return sum
  }

  updateCountOfProducts() {
    let count = 0
    for (const c of this.cart) {
      count += c.count
    }
    this.productsCount = count
  }
}
