import { Injectable } from '@angular/core'
import {
  AngularFirestore,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore'
import * as $ from 'jquery'
import { SessionDataService } from 'src/app/services/session-data.service'
import {
  Category,
  Extra,
  Order,
  PaymentMethod,
  Product,
  UtilService,
} from 'src/app/services/util.service'
import { environment } from 'src/environments/environment'
import { CheckoutSystem } from './../checkout-system-api.service'

/* For more information visit https://www.hellotess.com/api */

//#region Hello Tess Interfaces
interface ht_article {
  /**
   * globally unique article id
   */
  id: string

  /**
   * PLU for this article
   */
  plu?: string

  /**
   * article name
   */
  name: string

  /**
   * article description
   */
  description?: string

  /**
   * 0: Main Article, 1: Sub Article, 2: Deposit
   */
  type: number

  /**
   * The id of the article group the article is associated with
   */
  articleGroupId: string

  /**
   * Gross price in smallest monetary unit (Cents/Rappen)
   */
  price: number

  /**
   * External PLU (id) field
   */
  externalPLU?: string

  /**
   * Gross secondary price in smallest monetary unit (Cents/Rappen), like promotions etc.
   */
  priceSecondary?: number

  /**
   * Gross takeaway price in smallest monetary unit (Cents/Rappen)
   */
  priceTakeaway?: number

  /**
   * Default inhouse tax rate times 100 (1900 equals 19%)
   */
  taxInhouse?: number

  /**
   * Default Takeaway tax rate times 100 (700 equals 7%)
   */
  taxTakeaway?: number

  /**
   * Inherit Tax rates from articleGroup
   */
  inheritTaxrate?: boolean

  /**
   * Inherit colors from Article Group
   */
  inheritColors?: boolean

  /**
   * HEX color code (6 digits)
   */
  colorForeground?: string

  /**
   * HEX color code (6 digits)
   */
  colorBackground?: string

  /**
   * HEX color code (6 digits)
   */
  colorBorder?: string

  /**
   * Allergy information about this product
   */
  allergyInfo?: string

  /**
   * Fat content of the Product
   */
  fatContentPoints?: any

  /**
   * Barcode of this product
   */
  barcode?: string

  /**
   * Calculatory production cost to get calculate marginal return. Smallest monetary unit (Cent/Rappen)
   */
  netProductionCosts?: number

  /**
   * Calculatory production cost to get calculate marginal return on weighted articles. Smallest monetary unit (Cent/Rappen)
   */
  netProductionCostsPerWeight?: number

  /**
   * Show article group on default layer
   */
  active?: boolean

  /**
   * Is this article deleted?
   */
  deleted?: boolean
}

interface ht_articleGroup {
  /**
   * Unique Identifier , must be unique in the system
   */
  id: string

  /**
   * Main article group. Valid Values: "0: Food", "1: Drink", "2: Diverse"
   */
  mainArticleGroup: number

  /**
   * Article group name
   */
  name: string

  /**
   * Human readable article group id
   */
  externalId?: string

  /**
   * Default inhouse tax rate times 100 (1900 equals 19%) for articles in this group
   */
  taxInhouse?: number

  /**
   * Default inhouse tax rate times 100 (700 equals 7%) for artilces in this group
   */
  taxTakeaway?: number

  /**
   * HEX color code (6 digits)
   */
  colorForeground?: string

  /**
   * HEX color code (6 digits)
   */
  colorBackground?: string

  /**
   * HEX color code (6 digits)
   */
  colorBorder?: string

  /**
   * Start PLU numbers (for autogenerated PLU's)
   */
  pluStart?: number

  /**
   * End PLU numbers (for autogenerated PLU's)
   */
  pluEnd?: number

  /**
   * A custom top article group like AFG
   */
  topArticleGroupId?: string

  /**
   * External ID to be used in third party systems
   */
  externalPLU?: string

  /**
   * Show article group on default layer
   */
  active: boolean

  /**
   * Is this article group deleted?
   */
  deleted?: boolean
}

interface ht_orderFlow {
  /**
   * Unique identifier of this orderflow
   */
  id: string

  /**
   * Name
   */
  name: string

  /**
   * articles triggered
   */
  trigger?: {
    id: string

    /**
     * Show only articles of this article group id
     */
    articleGroups: {
      articles?: string[]
      id: string
    }[]
  }

  /**
   * Available step objects for this orderflow, ordered by array key
   */
  steps: ht_step[]
}

interface ht_step {
  /**
   * Unique identifier of this step
   */
  id: string

  /**
   * Name of this step
   */
  name: string

  pricing: {
    overwrite: boolean
    price: number

    /**
     * Available articles for this step
     */
    // articlesAvailable: ht_article[],

    /**
     * ???
     */
    articles: any[]
  }

  selection: {
    /**
     * Min articles to be chosen (0 is optional)
     */
    minimum: number

    /**
     * Max articles to be chosen
     */
    maximum: number

    datasources: {
      articles: {
        id: string
      }[]
      ident: string
      type: number
    }[]
  }
}

interface ht_externalOrder {
  /**
   * Unique identifier for this store
   */
  id?: string

  /**
   * DateTime of this order in ISO 8601 format
   */
  timestamp?: string

  /**
   * Order number to be printed on production bon
   */
  orderDisplayNumber?: number

  /**
   * Suppress confirmation E-Mail
   */
  suppressConfirmationEmail?: number

  /**
   * If this is an internal order, no order bon is being printed
   */
  internal?: boolean

  /**
   * The store to release this order to
   */
  destination?:
    | string
    | {
        storeId: string
      }

  /**
   * If no table id is given, the order will be placed on the default table
   */
  tableId?: string

  /**
   * Notes to be printed on production bons
   */
  notes?: string[]

  user?: {
    /**
     * Unique identifier for this user
     */
    id: string

    /**
     * First name of this user
     */
    firstname?: string

    /**
     * Last name of this user
     */
    surname?: string

    /**
     * Specific number given to the employee
     */
    employeeNumber?: string

    waiterNumber?: string

    active?: boolean

    /**
     * E-Mail of this user
     */
    email?: string

    validFrom?: string

    roleId?: string

    validTo?: string

    /**
     * External Personell number
     */
    personnelNumber?: number

    mustChangePin?: number

    activeStores?: any
  }

  delivery?: {
    /**
     * Takeaway/Delivery type
     * Valid values: "1: Pickup", "2: Delivery", "3: Conference room delivery"
     */
    type: number

    /**
     * Expected delivery DateTime of this order in ISO 8601 format
     */
    timestamp: string
  }

  payment?: {
    method: ht_paymentMethod

    /**
     * Payment status
     * Valid values: "0: paid", "1: unpaid"
     */
    status: number
  }

  items?: ht_deliveryItem[]
}

interface ht_deliveryItem {
  /**
   * Article uid (iternal article ID)
   */
  id?: string

  /**
   * Quantity to be ordered
   */
  quantity: number

  /**
   * Price in smallest monetary unit (Cents/Rappen)
   */
  price: number

  /**
   * Note for production bon
   */
  note: string
}

interface ht_paymentMethod {
  /**
   * Unique identifier for this payment method
   */
  id: string

  /**
   * Name of this payment Method
   */
  name: string

  /**
   * Is this payment method active?
   */
  active?: boolean
}

interface ht_table {
  /**
   * Unique table identifier (string)
   */
  id: string

  /**
   * Name of this table
   */
  name: string

  /**
   * Room id the table is located in
   */
  roomId?: string

  /**
   * Store id the table is located in
   */
  storeId?: string

  /**
   * External PLU for this table
   */
  externalPLU?: number

  /**
   * Is this table active?
   */
  active?: boolean
}

interface ht_store {
  /**
   * Unique identifier for this store
   */
  id: string

  /**
   * Name of this store
   */
  name: string

  /**
   * Store number
   */
  number?: number

  /**
   * Is this store active?
   */
  active?: boolean
}

//#endregion

@Injectable({
  providedIn: 'root',
})
export class HelloTessServiceService implements CheckoutSystem {
  /**
   * * This promise resolves to false if the helloTess Api is not enabled for this gastro and resolves to to true if the Api is enabled and the service has been initialized
   * */
  private active: Promise<boolean>
  hasMobilePayment: boolean
  gastroId = ''

  constructor(
    private util: UtilService,
    private afs: AngularFirestore,
    private sessionDataService: SessionDataService
  ) {
    this.active = new Promise((resolve, reject) => {
      const gastroIdSubscription = this.sessionDataService.$gastroId.subscribe(
        async (id) => {
          if (id !== undefined && id !== null && id !== this.gastroId) {
            this.gastroId = id
            const doc = await this.afs
              .collection('gastro')
              .doc(id)
              .collection('checkoutSystemApi')
              .doc('helloTess')
              .get()
              .toPromise()
            if (doc.exists === false) {
              resolve(false)
            } else {
              resolve(doc.data().active)
              gastroIdSubscription.unsubscribe()
            }
          }
        }
      )
    })
  }
  getProducts(): Product[] {
    throw new Error('Method not implemented.')
  }

  getMobilePaymentSplitObject(): PaymentMethod {
    throw new Error('Method not implemented.')
  }

  sendPayment(amount: number, partyId?: number, tip?: number) {
    throw new Error('Method not implemented.')
  }

  getCategories(): Category[] {
    return []
  }

  async sendOrder(split_cart: Order): Promise<[boolean, any]> {
    const data = {
      order: split_cart,
      gastroId: this.gastroId,
    }

    const url = `${environment.functionsUrl}sendHelloTessOrder`

    const resp = await $.post(url, data)

    if (resp.status === undefined || resp.status === 200) {
      return [true, undefined]
    } else {
      return [false, undefined]
    }
  }

  getExtra(split_extraId: string): Extra {
    return undefined
  }

  getPaymentMethods(): PaymentMethod[] {
    return []
  }

  async isActiveAndEnabled(): Promise<boolean> {
    return await this.active
  }
  apiName(): string {
    return 'hellotess'
  }
}
