import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { ProfileEntryModalPage } from 'src/app/profile-entry-modal/profile-entry-modal.page'
import { GastroService } from 'src/app/services/gastro.service'
import { GlobalService } from 'src/app/services/global.service'
import { LocalizationService } from 'src/app/services/localization.service'
import { SessionDataService } from 'src/app/services/session-data.service'
import { getLabelOfLanguageCode } from 'src/shared/split-submodules/functions/functions'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output()
  public navigateBackEvent: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private alertController: AlertController,
    public gastroService: GastroService,
    public globalService: GlobalService,
    public localizationService: LocalizationService,
    private modalController: ModalController,
    private router: Router,
    private sessionDataService: SessionDataService
  ) {}

  ngOnInit() {}

  public navigateBackCallback() {
    this.navigateBackEvent.emit()
  }

  public async changeLanguageButtonCallback() {
    const inputs = this.localizationService.availableLanguageCodes.map(
      (code) => {
        return {
          checked: this.localizationService.currentLanguageCode === code,
          label: getLabelOfLanguageCode(code),
          type: 'radio',
          value: code,
        }
      }
    )

    const defaultLanguageCode = this.localizationService.defaultLanguageCode
    inputs.unshift({
      checked:
        this.localizationService.currentLanguageCode === defaultLanguageCode,
      label: getLabelOfLanguageCode(defaultLanguageCode),
      type: 'radio',
      value: defaultLanguageCode,
    })

    inputs.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

    const alert = await this.alertController.create({
      header: 'Sprache ändern',
      inputs: <any>inputs,
      buttons: [
        {
          role: 'cancel',
          text: 'Abbrechen',
        },
        {
          text: 'Bestätigen',
          handler: (value) => {
            this.localizationService.loadLanguage(
              value,
              this.gastroService.$gastro.id
            )
          },
        },
      ],
    })
    alert.present()
  }

  async openProfileSelect(eve: any) {
    const modal = await this.modalController.create({
      component: ProfileEntryModalPage,
      cssClass: 'auto-height',
    })
    modal.present()
    modal.onDidDismiss().then((data) => {})
  }

  /**
   * returns the user to the Start-Of-Interaction, only if the user is inhouse.
   * There is no other case since the button is only shown if the gastro has self checkout
   */
  public goToSelfCheckout() {
    if (
      this.gastroService.$gastro.hasPayAtTable == true &&
      this.sessionDataService.$inhouseLink
    ) {
      this.router.navigate(['start-of-interaction'])
    }
  }
}
