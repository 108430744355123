import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class FontService {
  constructor() {}

  public static InjectFont(path: string, name: string) {
    const styles = `@font-face {
			font-family: ${name};
			src: url('/assets/fonts/${path}') format('woff');
			}`

    const node = document.createElement('style')
    node.innerHTML = styles
    document.body.appendChild(node)
  }

  public static trackFont(id: string) {
    (window as any).MTFontIds.push(id)
    const mtTracking = document.createElement('script')
    mtTracking.type = 'text/javascript'
    mtTracking.async = true
    mtTracking.src = '/assets/js/mtiFontTrackingCode.js'
    ;(
      document.getElementsByTagName('head')[0] ||
      document.getElementsByTagName('body')[0]
    ).appendChild(mtTracking)
  }
}
