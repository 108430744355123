import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { GastroService } from 'src/app/services/gastro.service'

@Injectable({ providedIn: 'root' })
export class CartGuard implements CanActivate {
  constructor(
    private gastroService: GastroService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.gastroService.gastroLoaded !== true) {
      this.router.navigate(['restaurant-page'])
    }
    return true
  }
}
