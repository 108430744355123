import { Component, OnInit } from '@angular/core'
import { GastroService } from 'src/app/services/gastro.service'
import { getHoursAndMinutes } from 'src/shared/split-submodules/functions/functions'

@Component({
  selector: 'app-closed-banner',
  templateUrl: './closed-banner.component.html',
  styleUrls: ['./closed-banner.component.scss'],
})
export class ClosedBannerComponent implements OnInit {
  constructor(public gastroService: GastroService) {}

  public nextOpening: string
  public nextPickup: string

  ngOnInit() {
    // Normal times
    const openingTime = this.gastroService.getNextOpeningTime()
    if (openingTime.valid) {
      this.nextOpening = getHoursAndMinutes(openingTime.time)
    }
    const pickupTime = this.gastroService.getNextPickupTime()
    if (pickupTime.valid) {
      this.nextPickup = getHoursAndMinutes(pickupTime.time)
    }

    // Special Times?
    const specialTimes = this.gastroService.getGastroSpecialTimes()
    if (specialTimes === undefined) {
      return
    }
    const now = new Date()
    for (const specialTime of specialTimes) {
      const fromDate = new Date(specialTime.dateFrom)
      const toDate = new Date(specialTime.dateTo)
      toDate.setHours(24)
      if (specialTime.repeat) {
        fromDate.setFullYear(now.getFullYear())
        toDate.setFullYear(now.getFullYear())
      }
      if (fromDate <= now && now <= toDate) {
        if (specialTime.closed) {
          this.nextOpening = undefined
          this.nextPickup = undefined
        }
        this.nextOpening = specialTime.opening.from
        this.nextPickup = specialTime.pickup.from
        break
      }
    }
  }
}
