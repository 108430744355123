import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/angular'

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor() {}

  public setSentryContext(context: any) {
    Sentry.setContext('gastro', context)
  }

  public setSentryTag(key: string, value: any) {
    Sentry.setTag(key, value)
  }

  public captureError(e, addInfo) {
    console.log(`ErrorMsg: ${addInfo}`)
    Sentry.captureException(e)
  }
}
