<ion-row class="article-container" *ngIf="article">
  <div *ngIf="isInteractive == false" class="article-amount-container">
    <ion-text
      class="article-amount-text"
      attr.aria-label="{{ article.counter }} mal"
      >{{ article.counter }}</ion-text
    >
  </div>
  <div
    class="article-amount-container-greater-partial"
    *ngIf="isInteractive == true && showStepCounter == false && showCollapsed"
    (click)="toggleStepCounter()"
    attr.aria-label="{{ partialCounter }} mal"
    role="button"
  >
    <div
      class="article-amount-container-minor-partial"
      [ngClass]="{
        'top-radius': article.maxNumber !== undefined,
        'full-radius': article.maxNumber === undefined
      }"
    >
      <ion-text
        class="article-amount-minor-text"
        attr.aria-label="Anzahl ausgewählter Produkte: {{
          partialCounter
        }}. Klicken um anzupassen"
        role="button"
        >{{ partialCounter }}</ion-text
      >
    </div>
    <div
      *ngIf="article.maxNumber !== undefined"
      class="article-amount-container-partial-counter"
    >
      <ion-text class="article-amount-partial-counter-text" aria-hidden="true"
        >von
      </ion-text>
      <ion-text class="article-amount-partial-total-text" aria-hidden="true">{{
        article.maxNumber
      }}</ion-text>
    </div>
  </div>
  <div
    class="article-amount-container-greater-with-step-counter"
    *ngIf="isInteractive == true && showStepCounter == true"
    [@horizontalGrowEffect]
    (@horizontalGrowEffect.done)="onAnimationDone()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      attr.aria-label="{{ article.name }} von Bestellung entfernen"
      role="button"
      (click)="removeItemFromOrder()"
      aria-label="Um 1 verringern"
      role="button"
    >
      <rect width="36" height="36" rx="18" fill="#DFEDF3" />
      <path
        d="M9 17.5405C9 16.6897 9.68972 16 10.5405 16H25.946C26.7968 16 27.4865 16.6897 27.4865 17.5405C27.4865 18.3914 26.7968 19.0811 25.946 19.0811H10.5405C9.68972 19.0811 9 18.3914 9 17.5405Z"
        fill="#00A3E0"
      />
    </svg>
    <div
      class="article-amount-container-greater-partial"
      (click)="toggleStepCounter()"
      attr.aria-label="{{ partialCounter }} mal"
      role="button"
    >
      <div
        class="article-amount-container-minor-partial"
        [ngClass]="{
          'top-radius': article.maxNumber !== undefined,
          'full-radius': article.maxNumber === undefined
        }"
      >
        <ion-text
          class="article-amount-minor-text"
          attr.aria-label="Anzahl ausgewählter Produkte: {{
            partialCounter
          }} von {{ article.counter }}. Klicken um bearbeitung zu beenden"
          role="button"
          >{{ partialCounter }}</ion-text
        >
      </div>
      <div
        *ngIf="article.maxNumber !== undefined"
        class="article-amount-container-partial-counter"
      >
        <ion-text class="article-amount-partial-counter-text" aria-hidden="true"
          >von
        </ion-text>
        <ion-text
          class="article-amount-partial-total-text"
          aria-hidden="true"
          >{{ article.maxNumber }}</ion-text
        >
      </div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      attr.aria-label="{{ article.name }} zu Bestellung hinzufügen"
      role="button"
      (click)="addItemToOrder()"
      aria-label="Um 1 erhöhen"
      role="button"
    >
      <rect
        width="36"
        height="36"
        rx="18"
        transform="matrix(-1 0 0 1 36 0)"
        fill="#DFEDF3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.579 10.8937C16.579 10.1089 17.2152 9.47266 18 9.47266C18.7849 9.47266 19.4211 10.1089 19.4211 10.8937V16.578H25.1052C25.89 16.578 26.5263 17.2142 26.5263 17.9991C26.5263 18.7839 25.89 19.4201 25.1052 19.4201H19.4211V25.1042C19.4211 25.8891 18.7849 26.5253 18 26.5253C17.2152 26.5253 16.579 25.8891 16.579 25.1042V19.4201H10.8947C10.1099 19.4201 9.47363 18.7839 9.47363 17.9991C9.47363 17.2142 10.1099 16.578 10.8947 16.578H16.579V10.8937Z"
        fill="#00A3E0"
      />
    </svg>
  </div>
  <div
    *ngIf="isStatus == false"
    class="text-container"
    (click)="textClick ? addItemToOrder() : null"
  >
    <ion-text class="product-title">
      {{ article.name }}
    </ion-text>
    <div *ngIf="article.extras && article.extras.length > 0">
      <div>
        <app-show-more [items]="article.extras" [limit]="2">
          <ng-template let-child>
            <ion-text class="extras-text">+ {{ child.name }}</ion-text>
          </ng-template>
        </app-show-more>
      </div>
    </div>
  </div>
  <div *ngIf="isStatus == true" class="text-container">
    <ion-text class="product-title">
      {{ article.name }}
    </ion-text>
    <ion-text class="extras-text">
      {{ article.extrasString }}
    </ion-text>
  </div>
  <div class="price-container">
    <!-- commented out until we actually use it -->
    <!-- <ion-icon class="info-icon" name="information-circle-outline"></ion-icon> -->
    <ion-text
      *ngIf="isStatus == false"
      [ngClass]="
        (isInteractive == true && partialCounter > 0) || !isInteractive
          ? 'price-text'
          : 'price-text-no-counter'
      "
    >
      {{ this.total | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de' }}
    </ion-text>
    <ion-text class="price-text-status" *ngIf="isStatus == true">
      {{ this.total | currency: 'EUR' : 'symbol-narrow' : '1.2-2' : 'de' }}
    </ion-text>
  </div>
</ion-row>
<div class="item-divider"></div>
