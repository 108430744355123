import { CurrencyPipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { PricePipe } from './price.pipe'
import { SafePipe } from './safe.pipe'

@NgModule({
  declarations: [SafePipe, PricePipe],
  exports: [SafePipe, PricePipe],
  providers: [PricePipe, CurrencyPipe],
})
export class PipesModule {}
