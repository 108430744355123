import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PaymentMethodModalPage } from './payment-method-modal.page'

const routes: Routes = [
  {
    path: '',
    component: PaymentMethodModalPage,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentMethodModalPageRoutingModule {}
