<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Allergene</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngFor="let allergen of allergens">
    <ion-item>
      <ion-text>{{ allergen }}</ion-text>
    </ion-item>
  </div>
  <div class="infoText">
    <ion-text *ngIf="!confirmed"
      >Diese Allergene wurden durch eine KI erstellt. Dementsprechend ist die
      Liste eventuell nicht vollständig. Bei Fragen wende dich bitte an das
      Service Personal.</ion-text
    >
  </div>
</ion-content>
