<ion-app *ngIf="compatible">
  <ion-item
    *ngIf="!isConnected"
    color="danger"
    style="position: absolute; text-align: center; width: 100%; z-index: 5"
  >
    <ion-label> Du bist offline </ion-label></ion-item
  >
  <script
    id="CookieDeclaration"
    src="https://consent.cookiebot.com/0a6b62e3-5ccc-4734-adf4-a6b43696603d/cd.js"
    type="text/javascript"
    async
  ></script>
  <ion-router-outlet [animated]="true">
    <ion-tabs>
      <ion-tab-bar
        id="tabstabs"
        slot="bottom"
        [hidden]="
          !(
            (this.gastroService.gastro.hasPayAtTable == false ||
              this.gastroService.gastro.hasPayAtTable == undefined) &&
            this.gastroService.gastroLoaded === true
          )
        "
      >
        <ion-tab-button
          *ngIf="!sessionDataService.inhouseLink && false"
          tab="home"
        >
          <ion-icon name="location-outline"></ion-icon>
          <ion-label>Locations</ion-label>
        </ion-tab-button>
        <ion-tab-button
          *ngIf="sessionDataService.$isGastroLoggedOut"
          tab="home"
        >
          <ion-icon name="home-outline"></ion-icon>
          <ion-label>Home</ion-label>
        </ion-tab-button>
        <ion-tab-button
          *ngIf="!sessionDataService.$isGastroLoggedOut"
          tab="restaurant-page"
        >
          <ion-icon name="restaurant-outline"></ion-icon>
          <ion-label>Speisekarte</ion-label>
        </ion-tab-button>
        <ion-tab-button
          *ngIf="
            gastroService.$gastro !== undefined &&
            gastroService.$gastro.self &&
            !sessionDataService.$isGastroLoggedOut &&
            menuService.isUnclickable() === false &&
            this.historyService.$allOrderedItemsIds?.length > 0
          "
          tab="status"
        >
          <ion-icon name="fast-food-outline"></ion-icon>
          <ion-label>Meine Bestellungen</ion-label>
        </ion-tab-button>

        <ion-tab-button
          *ngIf="menuService.isUnclickable() === false"
          tab="checkout"
        >
          <ion-icon name="wallet-outline"></ion-icon>
          <ion-label
            >Rechnung
            <ion-icon
              *ngIf="!orderService.$hasSeenNewOrders"
              color="danger"
              name="radio-button-on"
            ></ion-icon>
          </ion-label>
        </ion-tab-button>

        <!--<ion-tab-button tab="{{profilePath}}">
          <ion-icon name="person"></ion-icon>
          <ion-label>Profil</ion-label>
        </ion-tab-button>-->
      </ion-tab-bar>
    </ion-tabs></ion-router-outlet
  >
</ion-app>
<h1 style="color: red" *ngIf="!compatible">
  Bitte update deinen Browser auf version 11 um orderbird zu starten
</h1>
