import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { ModalController } from '@ionic/angular'
import { Subscription } from 'rxjs'
import { ModalObImgTextComponent } from 'src/app/components/modal-ob-img-text/modal-ob-img-text.component'
import {
  ProductsInCartDeletedAlertComponentProps,
  ProductsInCartDeletedAlertedComponent,
} from 'src/app/modals/products-in-cart-deleted-alerted/products-in-cart-deleted-alerted.component'
import { getHoursAndMinutes } from 'src/shared/split-submodules/functions/functions'
import {
  Category,
  Product,
  ProductTag,
  ProductTagGroup,
} from 'src/shared/split-submodules/types/types'
import { CartService } from './cart.service'
import { GastroService } from './gastro.service'
import { OrderService } from './order.service'
import { ReportService } from './report.service'
import { GastroResettable, ResetService } from './reset.service'
import { SessionDataService } from './session-data.service'
import { StorageService } from './storage.service'

@Injectable({ providedIn: 'root' })
export class MenuService implements GastroResettable {
  public disableMenuSkeleton = false

  public menuLoaded = false

  public productTags: ProductTag[] = []
  public productTagGroups: ProductTagGroup[] = []

  private categorySubscription?: Subscription
  private productSubscription?: Subscription

  public categories: Category[] = []
  public products: Product[] = []
  public topProducts: Product[] = []
  public menuUnclickable = false

  constructor(
    private afs: AngularFirestore,
    private cartService: CartService,
    private gastroService: GastroService,
    private modalController: ModalController,
    private reportService: ReportService,
    private resetService: ResetService,
    private sessionDataService: SessionDataService,
    private orderService: OrderService,
    private storageService: StorageService
  ) {
    this.loadProductTags()
    this.registerGastroReset()
  }

  onGastroLogout(): void {
    this.disableMenuSkeleton = false
    this.menuLoaded = false
    this.unsubscribeAll()
    this.categories = []
    this.products = []
    this.topProducts = []
  }

  registerGastroReset(): void {
    this.resetService.registerGastroReset(this)
  }

  //CLEANUP: GastroService?
  isUnclickable(): boolean {
    const unclickable =
      (this.sessionDataService.$inhouseLink &&
        (this.gastroService.$gastro.inhouseUnclickable ||
          !this.sessionDataService.$inhouseCanOrder ||
          this.gastroService.$gastro.inhouseInactive)) ||
      (!this.sessionDataService.$inhouseLink &&
        this.gastroService.$gastro.outerhouseUnclickable) ||
      (!this.gastroService.isTogoOptionAvailable &&
        !this.sessionDataService.$inhouseLink) ||
      !this.gastroService.hasOpen()
    this.menuUnclickable = unclickable
    return unclickable
  }

  /**
   * Loads the entire menu (categories, products and bestsellers) from the database and subscribes to changes
   */
  fetchMenu() {
    this.menuLoaded = true

    this.fetchCategories()
    this.fetchProducts()

    if (
      (this.sessionDataService.$inhouseLink &&
        this.gastroService.$gastro.popDishesInhouseShown) ||
      (!this.sessionDataService.$inhouseLink &&
        this.gastroService.$gastro.popDishesOuterhouseShown)
    ) {
      this.getReportsOfLastMonth()
    }
  }

  /**
   * Fetches the categories from the database and subscribes to changes
   */
  private fetchCategories() {
    this.unsubscribeCategories()
    this.categorySubscription = this.afs
      .collection('gastro')
      .doc(this.gastroService.$gastroId)
      .collection('categories')
      .snapshotChanges()
      .subscribe((categoryCol) => {
        if (categoryCol.length === 0) {
          return
        }
        this.categories = []
        const categoryDoc = categoryCol[0]
        const data = categoryDoc.payload.doc.data()
        this.categories = data.categories
      })
  }

  /**
   * Fetches the products from the database and subscribes to changes
   */
  private fetchProducts() {
    this.unsubscribeProducts()
    this.productSubscription = this.afs
      .collection('gastro')
      .doc(this.gastroService.$gastroId)
      .collection('products')
      .snapshotChanges()
      .subscribe((docs) => {
        const oldProducts = this.products.slice()
        this.products = []
        docs.forEach((doc) => {
          const data = doc.payload.doc.data()
          data.dishes.forEach((dish) => {
            if (dish.img) {
              dish.img = dish.img.replace('(', '%28')
              dish.img = dish.img.replace(')', '%29')
            }
            this.products.push(dish)
          })

          /* Soon to be deprecated */
          if (data.categories !== undefined) {
            this.categories = data.categories
          }
          /* Soon to be deprecated */
        })
        //Handling of Product Change or Delete, also works with Masterdata sync and multiple items.

        this.lookForProductChanges(oldProducts, this.products)

        this.products.sort(this.compareByOffer)

        this.disableMenuSkeleton = true
      })
  }

  /**
   * Compares two products by their offer status
   * @param product1
   * @param product2
   * @returns
   */
  // eslint-disable-next-line class-methods-use-this
  private compareByOffer(product1: Product, product2: Product): number {
    if (
      product1.offer === true &&
      product1.offerData !== undefined &&
      product2.offer === false
    ) {
      //a is an offer and b is not
      return -1
    } else if (
      product2.offer === true &&
      product2.offerData !== undefined &&
      product1.offer === false
    ) {
      //b is an offer and a is not
      return 1
    } else if (
      product1.offer === true &&
      product1.offerData !== undefined &&
      product2.offer === true &&
      product2.offerData !== undefined
    ) {
      //Both a and b are offers
      if (product1.offerData.category < product2.offerData.category) {
        return -1
      } else if (product1.offerData.category > product2.offerData.category) {
        return 1
      } else {
        return 0
      }
    } else {
      //Neither a or b are offers
      return 0
    }
  }

  /**
   * Looks if there were any relevant changes between [oldProducts] and [newProducts] and handles them.
   * @param oldProducts
   * @param newProducts
   */
  private lookForProductChanges(
    oldProducts: Product[],
    newProducts: Product[]
  ) {
    const changedProducts: {
      oldProduct: Product
      newProduct: Product
    }[] = []
    const deletedProducts: Product[] = []

    const oldProductsInCart = oldProducts
      .filter((oldProduct) => oldProduct.addData?.ob_id !== undefined)
      .filter((oldProduct) =>
        this.cartService.$cart.some(
          (cartItem) => cartItem.addData?.ob_id === oldProduct.addData?.ob_id
        )
      )

    for (const oldProduct of oldProductsInCart) {
      const newVersionProduct = newProducts.find(
        (elem) => elem.addData?.ob_id === oldProduct.addData?.ob_id
      )
      if (newVersionProduct === undefined) {
        deletedProducts.push(oldProduct)
        continue
      }
      if (this.sessionDataService.$inhouseLink == true) {
        if (
          newVersionProduct.inhouseVisible == false &&
          newVersionProduct.outerhouseVisible == false
        ) {
          deletedProducts.push(oldProduct)
          continue
        }
      } else if (newVersionProduct.outerhouseVisible == false) {
        deletedProducts.push(oldProduct)
        continue
      }

      if (this.productHasChanged(oldProduct, newVersionProduct)) {
        const productChange = {
          oldProduct: oldProduct,
          newProduct: newVersionProduct,
        }

        changedProducts.push(productChange)
      }
    }

    this.handleProductChangesInCart(deletedProducts, changedProducts)
  }

  /**
   * Determines whether or not a product has changed in a way that is relevant to the user by comparing the old and new products name and price
   * @param oldProduct the old version of the product
   * @param newProduct the new version of the product
   * @returns if there was a change in the products name or price (depending on if we are ordering inhouse or outerhouse)
   */
  private productHasChanged(oldProduct: Product, newProduct: Product): boolean {
    if (oldProduct.name !== newProduct.name) {
      return true
    }

    if (this.sessionDataService.$inhouseLink) {
      if (oldProduct.inhousePrice !== newProduct.inhousePrice) {
        return true
      }
    } else if (oldProduct.outerhousePrice !== newProduct.outerhousePrice) {
      return true
    }

    return false
  }

  /**
   * It will prompt the user with a confirm-alert which notifies the user about product changes in the user's cart
   * Any deleted products in the cart will be removed and any changed products will be updated
   * @param deletedProducts the products that were deleted from the menu and are in the cart
   * @param changedProducts the products that were changed from the menu and are in the cart
   * @returns
   */
  private async handleProductChangesInCart(
    deletedProducts: Product[],
    changedProducts: {
      oldProduct: Product
      newProduct: Product
    }[]
  ) {
    // No changes
    if (deletedProducts.length === 0 && changedProducts.length === 0) {
      return
    }

    // Present changes to user
    const componentProps: ProductsInCartDeletedAlertComponentProps = {
      deletedProducts: deletedProducts,
      changedProducts: changedProducts,
    }
    const modal = await this.modalController.create({
      component: ProductsInCartDeletedAlertedComponent,
      componentProps: componentProps,
      backdropDismiss: false,
    })
    modal.present()

    // Apply Changes
    for (const product of deletedProducts) {
      this.cartService.deleteAllProductReferences(product)
    }

    for (const productChange of changedProducts) {
      this.cartService.updateCartOnDataSyncChanges(
        productChange.oldProduct,
        productChange.newProduct
      )
    }
    this.orderService.updateCartTotal()
  }

  /**
   * Determines whether or not there are any products visible in the category with the given [categoryId].
   * It also considers offers that may or may not be enabled at the moment.
   *
   * @param categoryId the id of the category
   * @returns true if there are no products visible in the category with the given [categoryId] and false if there is one or more product visible
   */
  isCategoryEmpty(categoryId: string | number): boolean {
    const filtered = this.products.filter(
      (elem) => elem.categoryId === categoryId
    )
    let j = 0
    for (let i = 0; i < filtered.length; i++) {
      if (
        (this.sessionDataService.$inhouseLink &&
          filtered[i].inhouseVisible === false) ||
        (!this.sessionDataService.$inhouseLink &&
          filtered[i].outerhouseVisible === false)
      ) {
        j++
      }
    }

    if (j === filtered.length) {
      return true
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].offer === false) {
        return false
      }
    }
    const now = new Date()
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].offer === true) {
        if (filtered[i].offerData.dates.includes(now.getDay())) {
          if (
            filtered[i].offerData.from <= getHoursAndMinutes(now) &&
            getHoursAndMinutes(now) < filtered[i].offerData.to
          ) {
            return false
          }
        }
      }
    }
    return true
  }

  /**
   * Unsubscribes from all subscriptions
   */
  private unsubscribeAll() {
    this.unsubscribeCategories()
    this.unsubscribeProducts()
  }

  /**
   * Unsubscribes from the category subscription
   */
  private unsubscribeCategories() {
    if (this.categorySubscription !== undefined) {
      this.categorySubscription.unsubscribe()
      this.categorySubscription = undefined
    }
  }

  /**
   * Unsubscribes from the product subscription
   */
  private unsubscribeProducts() {
    if (this.productSubscription !== undefined) {
      this.productSubscription.unsubscribe()
      this.productSubscription = undefined
    }
  }

  /*
   * returns the week number and year of the last four weeks
   * example: Today is 3. Week 2022
   * -> Returns Array [{week: 2, year: 2022}, {week: 1, year:2022}, {week: 52, year: 2021}, {week:51, year:2021}]
   */
  getLastMonth() {
    const currentDate = new Date()
    const currentYear: number = currentDate.getFullYear()
    const currentMonth: number = currentDate.getMonth()
    if (currentMonth !== 0) {
      return { month: currentMonth - 1, year: currentYear }
    } else {
      return { month: 11, year: currentYear - 1 }
    }
  }

  /*
   * gets the top 3 dishes of last month
   * sets the topDishes variable to these 3 dishes
   */
  async getReportsOfLastMonth() {
    const report = []
    const accumulatedReport = []
    const lastMonth = this.getLastMonth()
    const reports = await this.reportService.loadPopDishes(
      this.gastroService.$gastroId,
      lastMonth.year,
      lastMonth.month
    )
    report.push(reports.topsellerBar)
    report.push(reports.topseller)
    report.push(reports.topsellerPaypal)
    for (let i = 0; i < report.length; i++) {
      report[i].forEach((dish) => {
        const x = this.products.findIndex((e) => e.name === dish.name)
        if (
          x !== -1 &&
          this.products[x].isFood &&
          this.products[x].inhouseVisible &&
          this.products[x].outerhouseVisible
        ) {
          if (
            accumulatedReport.filter((e) => e.name === dish.name).length === 0
          ) {
            accumulatedReport.push(dish)
          } else {
            accumulatedReport[
              accumulatedReport.findIndex((e) => e.name === dish.name)
            ].count += dish.count
          }
        }
      })
    }
    accumulatedReport.sort((a, b) => {
      return b.count - a.count
    })
    for (let i = 0; i < accumulatedReport.length; i++) {
      const topDish = this.products.filter((dish) => {
        return dish.name === accumulatedReport[i].name
      })
      this.topProducts.push(topDish[0])
    }
  }

  private async loadProductTags() {
    interface ProductTagDocument {
      productTags: ProductTag[]
      productTagGroups: ProductTagGroup[]
    }

    const productTagCollection = await this.afs
      .collection('productTags')
      .get()
      .toPromise()

    const data: ProductTagDocument =
      productTagCollection.docs[0].data() as ProductTagDocument

    this.productTagGroups = data.productTagGroups
    this.productTags = data.productTags
  }

  ngOnDestroy() {
    this.unsubscribeAll()
  }

  async checkAndShowGroupOrderingPopup() {
    const showedGroupOrderingPopUp = await this.storageService.load(
      'showedGroupOrderingPopUp'
    )
    if (
      (showedGroupOrderingPopUp === undefined ||
        showedGroupOrderingPopUp === null ||
        showedGroupOrderingPopUp === false) &&
      this.sessionDataService.inhouseLink === true &&
      this.sessionDataService.$inhouseCanOrder === true &&
      this.isUnclickable() === false
    ) {
      this.openGroupOrdering()
    }
  }
  /**
   * Opens the Modal for Groupordering and loads it with information.
   */
  async openGroupOrdering() {
    this.storageService.store('showedGroupOrderingPopUp', true)
    const modal = await this.modalController.create({
      component: ModalObImgTextComponent,
      componentProps: {
        button: 'Alles klar!',
        header: 'Gemeinsam bestellen.\nGemeinsam genießen.',
        imgUrl: './../../assets/ob-group-ordering.svg',
        paragraphs: [
          'Seid ihr mehrere Personen & möchtet unser Angebot gemeinsam genießen?',
          'Bestellt einfach gemeinsam von einem Handy! So können wir eure Bestellungen zeitgleich servieren.',
        ],
      },
      cssClass: 'auto-height',
    })
    await modal.present()
  }
}
