import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { LoginGuard } from 'src/app/guards/login.guard'
import { UserprofileareaModalPage } from './userprofilearea-modal.page'

const routes: Routes = [
  {
    path: '',
    component: UserprofileareaModalPage,
    children: [
      /* eslint-disable max-len */
      {
        path: 'main',
        loadChildren: () =>
          import('./userprofileareaPages/main/main.module').then(
            (m) => m.MainPageModule
          ),
      },
      {
        path: 'history',
        loadChildren: () =>
          import('./userprofileareaPages/history/history.module').then(
            (m) => m.HistoryPageModule
          ),
      },
      {
        path: 'favGastros',
        loadChildren: () =>
          import('./userprofileareaPages/fav-gastros/fav-gastros.module').then(
            (m) => m.FavGastrosPageModule
          ),
      },
      {
        path: 'coupons',
        loadChildren: () =>
          import('./userprofileareaPages/coupons/coupons.module').then(
            (m) => m.CouponsPageModule
          ),
      },
      {
        path: 'paymentMethods',
        loadChildren: () =>
          import(
            './userprofileareaPages/payment-methods/payment-methods.module'
          ).then((m) => m.PaymentMethodsPageModule),
      },
      {
        path: 'rewards',
        loadChildren: () =>
          import('./userprofileareaPages/rewards/rewards.module').then(
            (m) => m.RewardsPageModule
          ),
      },
      {
        path: 'addresses',
        loadChildren: () =>
          import('./userprofileareaPages/addresses/addresses.module').then(
            (m) => m.AddressesPageModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./userprofileareaPages/settings/settings.module').then(
            (m) => m.SettingsPageModule
          ),
      },
      {
        path: 'newPaymentMethods',
        loadChildren: () =>
          import(
            './userprofileareaPages/new-payment-methods/new-payment-methods.module'
          ).then((m) => m.NewPaymentMethodsPageModule),
      },
      {
        path: 'statistics',
        loadChildren: () =>
          import('./userprofileareaPages/statistics/statistics.module').then(
            (m) => m.StatisticsPageModule
          ),
      },

      {
        path: 'loyalty',
        loadChildren: () =>
          import('./userprofileareaPages/loyalty/loyalty.module').then(
            (m) => m.LoyaltyPageModule
          ),
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import(
            './userprofileareaPages/change-password/change-password.module'
          ).then((m) => m.ChangePasswordPageModule),
        canActivate: [LoginGuard],
      },
      /* eslint-enable max-len */
    ],
  },
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
  ],
  declarations: [UserprofileareaModalPage],
})
export class UserprofileareaModalPageModule {}
