import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import {
  PayAtTableOrderService,
  SelfCheckoutPaymentMethod,
} from 'src/app/PayAtTable/order/pay-at-table-order.service'
import { GastroService } from 'src/app/services/gastro.service'
import { OrderService } from 'src/app/services/order.service'
import { PaymentService } from 'src/app/services/payment.service'
import { SessionDataService } from 'src/app/services/session-data.service'
import { PaymentMethod } from 'src/app/services/util.service'

const root = document.documentElement
const CARD_CVC_OPTIONS = {
  style: {
    base: {
      '::placeholder': {
        color: getComputedStyle(root).getPropertyValue(
          '--orderbird-color-midgrey'
        ),
      },
      color: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-darkblue'
      ),
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '17px',
      fontSmoothing: 'antialiased',
      fontWeight: 400,
    },
    invalid: {
      color: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-danger'
      ),
      iconColor: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-danger'
      ),
    },
  },
  placeholder: '***',
}

const CARD_EXPIRY_OPTIONS = {
  style: {
    base: {
      '::placeholder': {
        color: getComputedStyle(root).getPropertyValue(
          '--orderbird-color-midgrey'
        ),
      },
      color: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-darkblue'
      ),
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '17px',
      fontSmoothing: 'antialiased',
      fontWeight: 400,
    },
    invalid: {
      color: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-danger'
      ),
      iconColor: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-danger'
      ),
    },
  },
  placeholder: 'MM/JJ',
}

const CARD_NUMBER_OPTIONS = {
  disableLink: true,
  hideIcon: true,
  placeholder: 'Kartennummer eingeben',
  style: {
    base: {
      '::placeholder': {
        color: getComputedStyle(root).getPropertyValue(
          '--orderbird-color-midgrey'
        ),
      },
      color: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-darkblue'
      ),
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '17px',
      fontSmoothing: 'antialiased',
      fontWeight: 400,
    },
    invalid: {
      color: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-danger'
      ),
      iconColor: getComputedStyle(root).getPropertyValue(
        '--orderbird-color-danger'
      ),
    },
  },
}

@Component({
  selector: 'app-payment-method-modal',
  templateUrl: './payment-method-modal.page.html',
  styleUrls: ['./payment-method-modal.page.scss'],
})
export class PaymentMethodModalPage implements OnInit {
  constructor(
    public modalController: ModalController,
    private paymentService: PaymentService,
    private gastroService: GastroService,
    private sessionDataService: SessionDataService,
    public orderService: OrderService,
    public payAtTheTableOrderService: PayAtTableOrderService
  ) {}

  public modalReady = false
  public showStripeButton = false
  public listOfFilteredPaymentMethods: SelfCheckoutPaymentMethod[] = []
  public selectedPaymentMethod: SelfCheckoutPaymentMethod = {
    label: '',
    name: '',
    option: '',
    selected: false,
  }

  ngOnInit() {
    this.filterPaymentMethodsForSelection()
    this.changePlatformToRightName()
    this.preloadImages()
  }

  /**
   * Preloads images to be displayed in the modal and sets the modal to ready once all images are loaded.
   */
  private preloadImages() {
    const imageURLs = [
      './../../assets/logo-selfcheckout-ec.svg',
      './../../assets/logo-selfcheckout-paypal.svg',
      './../../assets/logo-selfcheckout-applepay.svg',
      './../../assets/logo-selfcheckout-googlepay.svg',
    ]

    const imageMap = imageURLs.map((image) => {
      return {
        url: image,
        loaded: false,
      }
    })

    for (const image of imageMap) {
      const img = new Image()
      img.src = image.url
      img.onload = () => {
        image.loaded = true
        if (imageMap.every((image) => image.loaded)) {
          this.modalReady = true
        }
      }
    }
  }

  /**
   * dismisses the modal
   */
  cancel() {
    this.modalController.dismiss()
  }

  /**
   * Handles the change in the selected payment method and performs necessary updates.
   * @param {any} option - The selected payment method option.
   */

  handleChangePaymentMethod(option: SelfCheckoutPaymentMethod) {
    for (const paymentMethod of this.listOfFilteredPaymentMethods) {
      if (option.name == paymentMethod.name) {
        if (paymentMethod.selected == true) {
          paymentMethod.selected = false
          this.showStripeButton = false
          return
        } else {
          paymentMethod.selected = true
        }
      } else {
        paymentMethod.selected = false
      }
    }

    this.showStripeButton =
      option.name === 'credit' &&
      this.gastroService.$gastro.hasDibsPayment !== true
    if (this.showStripeButton) {
      this.setupStripe()
    }
    if (option.name !== 'credit') {
      this.modalController.dismiss(this.selectedPaymentMethod, 'confirm')
    }
  }

  /**
   * Submits credit card information to create a Stripe payment method and confirms the payment.
   *
   * This asynchronous function calls the `createStripePaymentMethod` method from the `orderService`
   * to generate a new payment method for credit card payments. Once the payment method is created,
   * it sets it to the `payAtTheTableOrderService` and then dismisses the modal with the selected
   * payment method and a "confirm" status.
   *
   * @async
   * @returns {Promise<void>} - A promise that resolves when the credit card information is submitted
   *                            and the modal is dismissed.
   *
   * @throws {Error} - Throws an error if there is a problem with creating the Stripe payment method.
   *
   * @example
   * await this.submitCreditCardInfo();
   * // Submits the credit card info, creates a payment method, and confirms the payment.
   */
  async submitCreditCardInfo() {
    this.payAtTheTableOrderService.paymentMethod =
      await this.orderService.createStripePaymentMethod()
    this.modalController.dismiss(this.selectedPaymentMethod, 'confirm')
  }

  /**
   * Sets up the Stripe card element in the HTML for credit card payments.
   * Configures the appearance and behavior of the Stripe card element.
   *  * For proper linebreak using card expire cvc and postalcode stripe elements
   */
  setupStripe() {
    this.paymentService.elements = this.paymentService.stripe.elements()
    const elements = this.paymentService.elements
    const cardNumber = elements.create('cardNumber', CARD_NUMBER_OPTIONS)
    cardNumber.mount('#card-number-element-pay-at-table')
    const cardExpiry = elements.create('cardExpiry', CARD_EXPIRY_OPTIONS)
    cardExpiry.mount('#card-expiry-element-pay-at-table')
    const cardCvc = elements.create('cardCvc', CARD_CVC_OPTIONS)
    cardCvc.mount('#card-cvc-element-pay-at-table')
    PaymentMethodModalPage.AddEventListenersForStripeInputs(
      cardNumber,
      cardExpiry,
      cardCvc
    )
  }

  /**
   * Adds event listeners to the Stripe card elements for handling changes in the input fields.
   * @param {any} cardNumber - The Stripe card number element.
   * @param {any} cardExpiry - The Stripe card expiry element.
   * @param {any} cardCvc - The Stripe card CVC element.
   */
  static AddEventListenersForStripeInputs(
    cardNumber: any,
    cardExpiry: any,
    cardCvc: any
  ) {
    // Event listeners for changes in the Stripe card element
    cardNumber.addEventListener('change', (event) => {
      const displayError = document.getElementById('card-errors-number')
      if (event.error) {
        displayError.textContent = event.error.message
        displayError.className = 'credit-card-input-label-error'
      } else {
        displayError.textContent = 'Kartennummer'
        displayError.className = 'credit-card-input-label'
        if (event.complete) {
          cardExpiry.focus()
        }
      }
    })
    cardExpiry.addEventListener('change', (event) => {
      const displayError = document.getElementById('card-errors-expiry')
      if (event.error) {
        displayError.textContent = event.error.message
        displayError.className = 'credit-card-input-label-error'
      } else {
        displayError.textContent = 'Gültig bis'
        displayError.className = 'credit-card-input-label'
        if (event.complete) {
          cardCvc.focus()
        }
      }
    })
    cardCvc.addEventListener('change', (event) => {
      const displayError = document.getElementById('card-errors-cvc')
      if (event.error) {
        displayError.textContent = event.error.message
        displayError.className = 'credit-card-input-label-error'
      } else {
        displayError.textContent = 'CVC'
        displayError.className = 'credit-card-input-label'
        if (event.complete) {
        }
      }
    })
  }

  /**
   * filter the list of available payment methods to only have those that are not ec or credit
   * @returns a list on payment options for displaying in a modal
   */

  filterPaymentMethodsForSelection() {
    const inputs = []
    let paymentMethods: PaymentMethod[] =
      this.gastroService.$gastro.paymentOptions
    paymentMethods = paymentMethods.filter(
      (method) => method.option !== 'ec-device' && method.option !== 'bar'
    )
    paymentMethods.forEach((option: PaymentMethod) => {
      if (
        this.sessionDataService.$inhouseLink
          ? option.inhouse
          : option.outerhouse
      ) {
        const input = {
          label: option.name,
          name: option.option, //has to stay due to complicated legacy reasons
          option: option.option, //has to stay due to complicated legacy reasons
          selected: false,
        }
        inputs.push(input)
      }
    })
    this.listOfFilteredPaymentMethods = inputs
  }

  /**
   * Updates the label of the platform payment method in the list of filtered payment methods.
   *
   * This function iterates through `listOfFilteredPaymentMethods` and updates the label of the
   * payment method with the name "platform" to the specified `name`.
   *
   * @param {string} name - The new label to be set for the platform payment method.
   *
   * @returns {void} - This function does not return a value.
   *
   * @example
   * findPlatformItem("ApplePay");
   * // Updates the label of the platform payment method to "ApplePay".
   */

  changePlatformName(name) {
    this.listOfFilteredPaymentMethods.forEach((e) => {
      if (e.name === 'platform') {
        e.label = name
      }
    })
  }

  /**
   * Detects the platform and updates the payment method label to the appropriate platform name.
   *
   * This function determines the user's platform by analyzing the `userAgent` string of the browser.
   * If the platform is iOS or Safari without Chrome, it sets the platform payment method label to
   * "ApplePay". Otherwise, it sets the label to "Google Pay".
   *
   * @returns {void} - This function does not return a value.
   *
   * @example
   * changePlatformToRightName();
   * // Updates the platform payment method label based on the detected user platform.
   */
  changePlatformToRightName() {
    const userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent),
      chrome = /chrome/.test(userAgent)
    if (ios || (safari && !chrome)) {
      this.changePlatformName('ApplePay')
    } else {
      this.changePlatformName('Google Pay')
    }
  }

  /**
   * Selects a payment method, toggling the selection state if the same method is selected twice.
   *
   * This function compares the selected payment
   * method with the current payment method. If they are the same, it resets the selected payment method.
   * Otherwise, it updates the selected payment method with the new payment method and invokes the
   * `handleChangePaymentMethod` method to handle any additional changes related to the payment method
   * selection.
   *
   * @param {Object} paymentMethod - The payment method object to be selected.
   * @param {string} paymentMethod.name - The name of the payment method.
   * @param {string} paymentMethod.label - The label of the payment method.
   *
   * @returns {void} - This function does not return a value.
   *
   * @example
   * const paymentMethod = { name: "CreditCard", label: "Credit Card" };
   * this.selectPaymentMethod(paymentMethod);
   * // Selects the payment method "Credit Card". If it's already selected, it will deselect it.
   */
  selectPaymentMethod(paymentMethod) {
    if (paymentMethod.name == this.selectedPaymentMethod.name) {
      this.selectedPaymentMethod = {
        label: '',
        name: '',
        option: '',
        selected: false,
      }
    } else {
      this.selectedPaymentMethod = paymentMethod
    }
    this.handleChangePaymentMethod(paymentMethod)
  }
}
